import { push } from "connected-react-router"
import React, {
    FC,
    useEffect,
    useState,
    useCallback,
    useMemo,
    RefObject
} from "react"
import {
    changeSearchingStatus,
    resetTaskIds,
    getFeedbackOptions
} from "../../actions/api/TaskActions"
import { useAppDispatch } from "../../hooks/useReduxState"
import { ingestTrainingInfo } from "../../interfaces/TaskInterfaces"
import { Worker } from "../../models/Worker"
import { routePaths } from "../../routes"
import {
    TaskResponse,
    FeedbackOptionsResponse,
    TaskApp,
    TaskResolveParams
} from "resha"
import { markTrainingComplete } from "../../actions/api/TrainingActions"
import { useTaskResolve } from "../../hooks/useTaskResolve"
import { DeadManCountdown } from "../../components/DeadManCountdown/DeadManCountdown"
import { useUrlSigner } from "../../hooks/useUrlSigner"
import { ToastStatus, useToast } from "../../hooks/useToast"

export interface TrainingTaskProps {
    task: TaskResponse
    worker: Worker
    trainingId: string
    toolbarRef: RefObject<HTMLDivElement>
}

export const TrainingTask: FC<TrainingTaskProps> = ({
    task,
    worker,
    trainingId,
    toolbarRef
}) => {
    const dispatch = useAppDispatch()
    const showErrorMessage = useToast(ToastStatus.ERROR)

    const [handleResolving] = useTaskResolve(worker, task)

    const [adminFeedback, setAdminFeedback] =
        useState<FeedbackOptionsResponse>()

    const handleResolve = useCallback(
        async (params: TaskResolveParams) => {
            if (params.resolveAction !== "RESOLVE") {
                return
            }

            switch (task.type) {
                case "TASK":
                    await handleResolving(params)
                    dispatch(
                        push(routePaths.trainingTasks(trainingId), {
                            sequence: task.sequence
                        })
                    )
                    dispatch(changeSearchingStatus(true))
                    break
                case "END":
                    // after the end of a training go to missions page
                    await dispatch(
                        markTrainingComplete({
                            trainingId,
                            sequence: task.sequence ?? 0
                        })
                    )
                    dispatch(push(routePaths.missions()))

                    break
                default:
                    // if its a training tutorial
                    if (task.sequence !== undefined) {
                        dispatch(
                            push(routePaths.trainingTasks(trainingId), {
                                sequence: task.sequence
                            })
                        )
                        dispatch(changeSearchingStatus(true))
                    }
                    break
            }
        },
        [dispatch, handleResolving, task, trainingId]
    )

    const handleSignUrl = useUrlSigner(worker, task)

    const data = useMemo(() => {
        if (task.id === undefined && task.trainingId === undefined) {
            return undefined
        }

        // training hint and metric value is ingested into the input for displaying (if present)
        const { input, output, response } = ingestTrainingInfo(task)

        return {
            type: "task" as const,
            task: { ...task, input, output, response }
        }
    }, [task])

    useEffect(() => {
        return () => {
            dispatch(resetTaskIds())
            dispatch(changeSearchingStatus(false))
        }
    }, [dispatch])

    useEffect(() => {
        if (task.id !== undefined) {
            dispatch(
                getFeedbackOptions({ workerUuid: worker.uuid, taskId: task.id })
            ).then(data => {
                setAdminFeedback(data)
            })
        }
    }, [worker, task, setAdminFeedback, dispatch])

    if (data === undefined) {
        return null
    }

    return (
        <DeadManCountdown worker={worker}>
            <TaskApp
                toolbarRef={toolbarRef}
                allowSkip={false}
                shouldPollPrelabels={false}
                data={data}
                appFeedback={adminFeedback}
                urlSigner={handleSignUrl}
                onTaskResolve={handleResolve}
                onError={showErrorMessage}
            />
        </DeadManCountdown>
    )
}
