import { ModalAction } from "../reducers/client/ModalReducer"
import { ModalType } from "./Types"

export const hideExplanationModal = (): ModalAction => ({
    type: ModalType.hideExplanationModal
})

export const showConnectionLostModal = (): ModalAction => ({
    type: ModalType.showConnectionLostModal
})

export const showTimeoutModal = (): ModalAction => ({
    type: ModalType.showTimeoutModal
})
