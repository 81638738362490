import React, { FC, ChangeEvent } from "react"

export interface InputProps {
    label: string
    name: string
    placeholder: string
    value: string
    onChange: (event: ChangeEvent<HTMLInputElement>) => void
    required?: boolean
    type?: "text" | "password"
}

export const Input: FC<InputProps> = ({
    label,
    name,
    required,
    onChange,
    type = "text",
    placeholder,
    value
}) => {
    return (
        <div className="flex flex-col w-full mb-4">
            <label htmlFor={name}>{label}</label>
            <input
                className="px-3 py-2 border border-solid rounded placeholder-gray-500 transition-border-color-250 hover:border-black focus:border-black hover:border-opacity-87 focus:border-opacity-87"
                id={name}
                name={name}
                type={type}
                required={required}
                placeholder={placeholder}
                onChange={onChange}
                value={value}
            />
        </div>
    )
}
