/**
 * The types defined here are used in tracking
 * Please be mindful if editing the value of the action type string
 * or adapting it's usage for some slightly different event
 * that this could affect how we analyse users.
 *
 * To ensure consistency, please contact whoever is managing
 * the anaylitics before making changes, so that updates
 * are transparent and we can adapt the respective systems.
 *
 * If a type is introduce purely for the sake of tracking,
 * it is ideal to prefix the type with "TRACKING/" to ensure
 * that it isn't removed in favour of redundancy.
 */

export enum AuthType {
    confirmationMode = "AUTH/USER/CONFIRM_NEW_PASSWORD",
    loggedIn = "AUTH/USER/LOGGED_IN",
    isSigningIn = "AUTH/USER/SIGNING_IN",
    isNotSigningIn = "AUTH/USER/NOT/SIGNING_IN",
    isSignedIn = "AUTH/USER/SIGNED_IN",
    isNotSignedIn = "AUTH/USER/NOT/SIGNED_IN",
    resetWorker = "AUTH/USER/RESET",
    toggleLoading = "AUTH/USER/TOGGLE_LOADING"
}

export enum MessagesType {
    set = "MESSAGES/SET",
    dismiss = "MESSAGES/DISMISS"
}

export enum TrainingsType {
    getTrainings = "API/TRAININGS/GET",
    startTraining = "API/TRAINING/START",
    cancelTraining = "API/TRAINING/CANCEL",
    toggleLoading = "API/TRAINING/TOGGLE_LOADING"
}

export enum TaskType {
    setTaskIds = "API/TASK/SET/IDS",
    setSearching = "API/SEARCHING/SET",
    setTask = "API/TASK/SET"
}

export enum BannerType {
    toggleInactiveWarning = "BANNER/INACTIVE_WARNING"
}

export enum ModalType {
    showConnectionLostModal = "MODAL/CONNECTION_LOST",
    showTimeoutModal = "MODAL/TIMEOUT",
    hideExplanationModal = "MODAL/HIDE_EXPLANATION"
}

export enum APIType {
    setLastSuccessfulKeepAlive = "API/LAST_SUCCESSFUL_KEEP_ALIVE"
}
