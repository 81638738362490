import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState
} from "react"
import { useInterval } from "./useInterval"

export const useIdle = (
    timeToIdle: number,
    gracePeriod: number = Infinity
): [boolean, number, Dispatch<SetStateAction<number>>] => {
    const [countdown, setCountdown] = useState(timeToIdle)

    const resetCountdown = useCallback(() => {
        setCountdown(timeToIdle)
    }, [timeToIdle])

    useInterval(async () => {
        setCountdown(count => count - 1)
    }, 1000)

    useEffect(() => {
        window.addEventListener("mousemove", resetCountdown)
        window.addEventListener("touchmove", resetCountdown)
        window.addEventListener("keypress", resetCountdown)
        return () => {
            window.removeEventListener("mousemove", resetCountdown)
            window.removeEventListener("touchmove", resetCountdown)
            window.removeEventListener("keypress", resetCountdown)
        }
    }, [resetCountdown])

    return [countdown <= 0, countdown, setCountdown]
}
