import { useCallback, useEffect, useRef } from "react"
import { TaskResponse } from "resha"
import { getSignedUrl } from "../actions/api/DataActions"
import { extractDataUrlPath, getMillisecondsUntilExpire } from "../models/Data"
import { Worker } from "../models/Worker"
import { useAppDispatch } from "./useReduxState"

export const useUrlSigner = (
    worker: Worker,
    task?: TaskResponse,
    example?: { sequence: number; id: number; trainingId: number }
) => {
    const urlStateRef = useRef<Map<string, string>>(new Map<string, string>())

    const dispatch = useAppDispatch()

    const updateUrlState = useCallback(
        (path: string) => (signedUrl: string) => {
            urlStateRef.current.set(path, signedUrl)
            return signedUrl
        },
        []
    )

    useEffect(
        () => () => {
            urlStateRef.current = new Map<string, string>()
        },
        []
    )

    return useCallback(
        (url: string) => {
            const path = extractDataUrlPath(url)

            if (path === undefined) {
                return Promise.resolve(url)
            }
            const signedUrl = urlStateRef.current.get(path)

            if (
                signedUrl !== undefined &&
                getMillisecondsUntilExpire(signedUrl) > 20 * 1000
            ) {
                return Promise.resolve(signedUrl)
            }

            if (task !== undefined && task.id !== undefined) {
                return dispatch(
                    getSignedUrl({
                        uuid: worker.uuid,
                        path,
                        taskId: task.id,
                        seconds: 600
                    })
                ).then(updateUrlState(path))
            } else if (example !== undefined) {
                return dispatch(
                    getSignedUrl({
                        uuid: worker.uuid,
                        exampleId: example.id,
                        trainingId: example.trainingId,
                        sequence: example.sequence,
                        path,
                        seconds: 600
                    })
                ).then(updateUrlState(path))
            }

            return Promise.reject("Task ID or Example is not defined")
        },
        [dispatch, example, task, updateUrlState, urlStateRef, worker.uuid]
    )
}
