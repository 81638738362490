import { push } from "connected-react-router"
import React, { FC, ReactNode } from "react"
import { useAppDispatch } from "../../hooks/useReduxState"
import { routePaths } from "../../routes"
import { CardFrame } from "../atoms/CardFrame"

interface BonusProgramProps {
    title: string
    description: ReactNode
    newBanner: string
}

export const BonusProgram: FC<BonusProgramProps> = ({
    description,
    title,
    newBanner
}) => {
    const dispatch = useAppDispatch()

    const handleClick = () => {
        dispatch(push(routePaths.bonus()))
    }

    return (
        <CardFrame justify="items-start" onClick={handleClick}>
            <div className="flex font-medium items-center mb-2">
                <span
                    className="mr-2 text-lg md:text-xl"
                    role="img"
                    aria-label="money bag emoji"
                >
                    💰
                </span>
                <h2 className="md:text-lg mb-0 ">{title}</h2>
                <span className="ml-3 px-2 text-white-opaque bg-grape-500 text-sm border border-solid border-grape-900">
                    {newBanner}
                </span>
            </div>
            <p className="text-left text-sm">{description}</p>
        </CardFrame>
    )
}
