"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.modernizeSchema = exports.taskOutputModernizeOptions = exports.taskInputModernizeOptions = exports.findUniversalSchemaTypeByField = exports.isUploadableType = exports.isSimpleType = exports.createTextSchemaItem = exports.outputTypeFields = exports.databaseMatchingUiFields = exports.classificationModelFieldUiFields = exports.annotationModelFieldUiFields = exports.annotationTypeFields = exports.annotationOutputTypes = void 0;
const _0_0_4_1 = require("../0.0.4");
const schema_json_1 = require("../0.0.4/schema_json");
const VirtuallyStructured_1 = require("./VirtuallyStructured");
const JSONSchema7_1 = require("./JSONSchema7");
const SchemaForm_1 = require("./SchemaForm");
const MultipleType_1 = require("./MultipleType");
require("../1.0.0");
exports.annotationOutputTypes = [
    _0_0_4_1.UniversalSchemaType.IMAGE_BOUNDING_BOX,
    _0_0_4_1.UniversalSchemaType.IMAGE_POLYGON,
    _0_0_4_1.UniversalSchemaType.SATELLITE_POLYGON
];
exports.annotationTypeFields = [
    "https://widgets.super.ai/image-bounding-box@1",
    "https://widgets.super.ai/document-bounding-box@1",
    "https://widgets.super.ai/image-polygon@1",
    "https://widgets.super.ai/document-extraction@1"
];
exports.annotationModelFieldUiFields = [
    "https://widgets.super.ai/document-extraction@1"
];
exports.classificationModelFieldUiFields = [
    "https://widgets.super.ai/classification-table@1"
];
exports.databaseMatchingUiFields = [
    "https://widgets.super.ai/matching-table@1"
];
exports.outputTypeFields = ["https://widgets.super.ai/image-hype@1"];
const createTextSchemaItem = (text) => ({
    type: _0_0_4_1.UniversalSchemaType.TEXT,
    schema_instance: text
});
exports.createTextSchemaItem = createTextSchemaItem;
function isSimpleType(schema) {
    return (typeof schema !== "boolean" &&
        !Array.isArray(schema.type) &&
        ((schema.type !== undefined &&
            ["null", "number", "string", "integer"].includes(schema.type)) ||
            (schema.$ref !== undefined &&
                [
                    _0_0_4_1.UniversalSchemaType.NUMBER,
                    _0_0_4_1.UniversalSchemaType.INTEGER,
                    _0_0_4_1.UniversalSchemaType.TEXT,
                    _0_0_4_1.UniversalSchemaType.URL
                ].includes(schema.$ref))));
}
exports.isSimpleType = isSimpleType;
function isUploadableType(uiSchema, propName) {
    var _a;
    return [
        "https://widgets.super.ai/image@1",
        "https://widgets.super.ai/image-presentation@0.0.4",
        "https://widgets.super.ai/video@0.0.4",
        "https://widgets.super.ai/satellite@0.0.4",
        "https://widgets.super.ai/document@0.0.4",
        "https://widgets.super.ai/img-document@0.0.4"
    ].includes((_a = uiSchema[propName]) === null || _a === void 0 ? void 0 : _a["ui:field"]);
}
exports.isUploadableType = isUploadableType;
const fieldsMap = {
    [_0_0_4_1.UniversalSchemaType.CHECKBOX]: "https://widgets.super.ai/checkbox@0.0.4",
    [_0_0_4_1.UniversalSchemaType.INSTRUCTIONS]: "https://widgets.super.ai/instructions@0.0.4",
    [_0_0_4_1.UniversalSchemaType.URL]: "https://widgets.super.ai/url@0.0.4",
    [_0_0_4_1.UniversalSchemaType.TEXT]: { type: "string" },
    [_0_0_4_1.UniversalSchemaType.NUMBER]: { type: "number" },
    [_0_0_4_1.UniversalSchemaType.INTEGER]: { type: "integer" },
    [_0_0_4_1.UniversalSchemaType.BINARY_CHOICE]: { type: "boolean" },
    [_0_0_4_1.UniversalSchemaType.EXCLUSIVE_CHOICE]: "https://widgets.super.ai/exclusive-choice@0.0.4",
    [_0_0_4_1.UniversalSchemaType.MULTIPLE_CHOICE]: "https://widgets.super.ai/multiple-choice@0.0.4",
    [_0_0_4_1.UniversalSchemaType.IMAGE]: "https://widgets.super.ai/image@1",
    [_0_0_4_1.UniversalSchemaType.IMAGE_PRESENTATION]: "https://widgets.super.ai/image-presentation@0.0.4",
    [_0_0_4_1.UniversalSchemaType.SATELLITE]: "https://widgets.super.ai/satellite@0.0.4",
    [_0_0_4_1.UniversalSchemaType.VIDEO]: "https://widgets.super.ai/video@0.0.4",
    [_0_0_4_1.UniversalSchemaType.DOCUMENT]: "https://widgets.super.ai/document@0.0.4",
    [_0_0_4_1.UniversalSchemaType.IMAGE_BOUNDING_BOX]: "https://widgets.super.ai/image-bounding-box@0.0.4",
    [_0_0_4_1.UniversalSchemaType.IMAGE_POLYGON]: "https://widgets.super.ai/image-polygon@0.0.4",
    [_0_0_4_1.UniversalSchemaType.SATELLITE_POLYGON]: "https://widgets.super.ai/satellite-polygon@0.0.4"
};
function findUniversalSchemaTypeByField(field) {
    for (const c in fieldsMap) {
        if (fieldsMap[c] === field) {
            return c;
        }
    }
}
exports.findUniversalSchemaTypeByField = findUniversalSchemaTypeByField;
function createModernizeEnhancer(rest, metadata, descriptionInUiHelp) {
    return function enhancer(schema, uiSchema = {}) {
        var _a, _b, _c, _d;
        const description = (_a = metadata === null || metadata === void 0 ? void 0 : metadata.description) !== null && _a !== void 0 ? _a : rest.description;
        return {
            schema: Object.assign(Object.assign({}, schema), { description: descriptionInUiHelp ? "" : description, title: (_c = (_b = metadata === null || metadata === void 0 ? void 0 : metadata.title) !== null && _b !== void 0 ? _b : rest.title) !== null && _c !== void 0 ? _c : "", examples: metadata === undefined || !("example" in metadata)
                    ? (_d = schema.examples) !== null && _d !== void 0 ? _d : rest.examples
                    : metadata.example }),
            uiSchema: descriptionInUiHelp
                ? Object.assign(Object.assign({}, uiSchema), { "ui:help": description }) : uiSchema
        };
    };
}
exports.taskInputModernizeOptions = {
    descriptionInUiHelp: true,
    enableCopyTextButton: true,
    enableMarkdown: true
};
exports.taskOutputModernizeOptions = {
    descriptionInUiHelp: true
};
function getDereferencedSchema(type) {
    switch (type) {
        case _0_0_4_1.UniversalSchemaType.VIDEO:
        case _0_0_4_1.UniversalSchemaType.IMAGE:
        case _0_0_4_1.UniversalSchemaType.SATELLITE:
        case _0_0_4_1.UniversalSchemaType.URL:
            return (0, SchemaForm_1.resolveSchemaRef)({ $ref: type }, schema_json_1.jsonSchemaV004);
        case _0_0_4_1.UniversalSchemaType.EXCLUSIVE_CHOICE:
            return (0, SchemaForm_1.resolveSchemaRef)({
                description: "Choose one of the choices",
                type: "object",
                properties: {
                    choices: {
                        type: "array",
                        description: "List of choices that will be shown to the task annotator",
                        items: {
                            $ref: "choice"
                        },
                        minItems: 1
                    },
                    selection: {
                        $ref: "choice"
                    }
                },
                required: ["choices", "selection"]
            }, schema_json_1.jsonSchemaV004);
        case _0_0_4_1.UniversalSchemaType.MULTIPLE_CHOICE:
            return (0, SchemaForm_1.resolveSchemaRef)({
                description: "Choose one or more of the choices",
                type: "object",
                properties: {
                    choices: {
                        type: "array",
                        description: "List of choices that will be shown to the task annotator",
                        items: {
                            $ref: "choice"
                        },
                        minItems: 1
                    },
                    selections: {
                        type: "array",
                        items: {
                            $ref: "choice"
                        },
                        minItems: 1
                    }
                },
                required: ["choices", "selections"]
            }, schema_json_1.jsonSchemaV004);
        default:
            // leave schema empty, because all the information needed is encoded as `ui:field` and
            // complex schema makes react-jsonschema-form slow to render
            return {};
    }
}
/**
 * Convert universal-schema based schema into the pair of schema and "UI schema" that
 * react-jsonschema-form based component can understand
 */
function modernizeSchema(_a, metadata, options = {}) {
    var { $schema, $ref, required } = _a, rest = __rest(_a, ["$schema", "$ref", "required"]);
    const { descriptionInUiHelp = false, enableCopyTextButton = false, enableMarkdown = false, allowEmptyString = true, requiredField = false, exampleAsDefault = false } = options;
    const enhance = createModernizeEnhancer(rest, metadata, descriptionInUiHelp);
    if (rest.oneOf !== undefined) {
        const oneOf = rest.oneOf.flatMap(childSchema => (0, JSONSchema7_1.isJSONSchema7)(childSchema)
            ? [modernizeSchema(childSchema, undefined, options)]
            : []);
        return enhance(Object.assign(Object.assign({}, rest), { oneOf: oneOf.map(item => item.schema) }));
    }
    if (rest.anyOf !== undefined) {
        const anyOf = rest.anyOf.flatMap(childSchema => (0, JSONSchema7_1.isJSONSchema7)(childSchema)
            ? [modernizeSchema(childSchema, undefined, options)]
            : []);
        return enhance(Object.assign(Object.assign({}, rest), { anyOf: anyOf.map(item => item.schema) }));
    }
    if ((0, VirtuallyStructured_1.isVirtuallyStructuredSchema)(rest)) {
        const uiSchema = {
            "ui:field": "https://widgets.super.ai/virtually-structured@0.0.4"
        };
        if (metadata === undefined ||
            !("items" in metadata) ||
            metadata.items === undefined ||
            !("properties" in metadata.items)) {
            return enhance({}, uiSchema);
        }
        const example = [
            Object.entries(metadata.items.properties).reduce((accum, [fieldName, field]) => {
                var _a;
                if ("example" in field) {
                    accum[fieldName] = (_a = field.example) === null || _a === void 0 ? void 0 : _a[0];
                }
                return accum;
            }, {})
        ];
        return enhance({ examples: [example] }, uiSchema);
    }
    if ((0, MultipleType_1.isMultipleTypeSchema)(rest)) {
        const uiSchema = {
            "ui:field": "https://widgets.super.ai/multiple-type@0.0.4"
        };
        const modernizedProps = Object.entries(rest.properties).map(([name, childSchema]) => {
            if (!(0, JSONSchema7_1.isJSONSchema7)(childSchema)) {
                return [name, false];
            }
            const { schema } = modernizeSchema(childSchema, metadata !== undefined && "properties" in metadata
                ? metadata.properties[name]
                : undefined, options);
            return [name, schema];
        });
        return enhance(Object.assign(Object.assign({}, rest), { properties: Object.fromEntries(modernizedProps), required: required !== null && required !== void 0 ? required : modernizedProps
                .filter(([_, schema]) => schema !== false)
                .map(([name]) => name) }), uiSchema);
    }
    if (rest.properties !== undefined) {
        const modernizedProps = Object.entries(rest.properties).map(([name, childSchema]) => {
            var _a;
            if (!(0, JSONSchema7_1.isJSONSchema7)(childSchema)) {
                return [name, undefined, undefined];
            }
            const modernizedProp = modernizeSchema(childSchema.const !== undefined
                ? childSchema
                : Object.assign(Object.assign({}, childSchema), { title: (_a = childSchema.title) !== null && _a !== void 0 ? _a : name }), metadata !== undefined && "properties" in metadata
                ? metadata.properties[name]
                : undefined, Object.assign(Object.assign({}, options), { requiredField: (required !== null && required !== void 0 ? required : []).indexOf(name) !== -1 }));
            return [
                name,
                $ref === _0_0_4_1.UniversalSchemaType.BLOCK
                    ? Object.assign(Object.assign({}, modernizedProp.schema), { title: name }) : modernizedProp.schema,
                modernizedProp.uiSchema
            ];
        });
        const aggregatedUiSchema = Object.fromEntries(modernizedProps.map(([name, _, uiSchema]) => [name, uiSchema]));
        return enhance(Object.assign(Object.assign({}, rest), { properties: Object.fromEntries(modernizedProps), required: $ref === _0_0_4_1.UniversalSchemaType.BLOCK
                ? required
                : required !== null && required !== void 0 ? required : modernizedProps
                    .filter(([_, schema]) => schema !== undefined)
                    .map(([name]) => name) }), Object.assign({}, ($ref === _0_0_4_1.UniversalSchemaType.BLOCK
            ? Object.assign(Object.assign({}, aggregatedUiSchema), { "ui:options": Object.assign(Object.assign({}, aggregatedUiSchema["ui:options"]), { isBlockType: true }) }) : aggregatedUiSchema)));
    }
    if ((0, JSONSchema7_1.isJSONSchema7)(rest.items)) {
        const modernizedItems = modernizeSchema(rest.items, metadata === undefined || !("items" in metadata)
            ? undefined
            : metadata.items, options);
        return enhance(Object.assign(Object.assign({}, rest), { items: modernizedItems.schema }), { items: modernizedItems.uiSchema });
    }
    if ($ref !== undefined && !$ref.startsWith("#")) {
        const field = fieldsMap[$ref];
        let enhancedSchema;
        if (typeof field === "string" || field === undefined) {
            enhancedSchema = enhance(getDereferencedSchema($ref), {
                "ui:field": field !== null && field !== void 0 ? field : "https://widgets.super.ai/fallback@1"
            });
        }
        else {
            enhancedSchema = enhance(field.type === "string" && !allowEmptyString
                ? Object.assign(Object.assign({}, field), { minLength: 1 }) : field.type === "boolean" && requiredField
                ? Object.assign(Object.assign({}, field), { default: false }) : field, field.type === "string"
                ? {
                    "ui:options": {
                        enableCopyTextButton,
                        enableMarkdown
                    }
                }
                : field.type === "boolean"
                    ? { "ui:widget": "radio" } // without this the label is not displayed
                    : undefined);
        }
        // only if one example exists, use that as default
        if (exampleAsDefault &&
            field !== undefined &&
            ((typeof field === "string" &&
                field === "https://widgets.super.ai/checkbox@0.0.4") ||
                (typeof field !== "string" && field.type === "boolean")) &&
            Array.isArray(enhancedSchema.schema.examples) &&
            enhancedSchema.schema.examples.length === 1) {
            return Object.assign(Object.assign({}, enhancedSchema), { schema: Object.assign(Object.assign({}, enhancedSchema.schema), { default: enhancedSchema.schema.examples[0] }) });
        }
        return enhancedSchema;
    }
    if (rest.type === "null" && requiredField) {
        return enhance(Object.assign(Object.assign({}, rest), { default: null }));
    }
    if (rest.const !== undefined && requiredField) {
        return enhance(Object.assign(Object.assign({}, rest), { default: rest.const }));
    }
    return enhance(rest);
}
exports.modernizeSchema = modernizeSchema;
