import { BannerAction } from "../reducers/client/BannerReducer"
import { APIType, BannerType } from "./Types"

export const toggleInactiveWarningBanner = (
    visible: boolean
): BannerAction => ({
    type: BannerType.toggleInactiveWarning,
    payload: {
        visible
    }
})

export const setLastSuccessfulKeepAlive = (
    timestamp: number | undefined
): BannerAction => ({
    type: APIType.setLastSuccessfulKeepAlive,
    payload: timestamp
})
