import React, { FC } from "react"
import { Link } from "../atoms/Link"
import { routePaths } from "../../routes"
import ArrowBackIos from "@material-ui/icons/ArrowBackIos"
import PriorityHigh from "@material-ui/icons/PriorityHigh"

interface NavHeaderProps {
    backlink?: string
    login?: boolean
}
export const NavHeader: FC<NavHeaderProps> = ({
    children,
    login,
    backlink
}) => (
    <Link
        to={backlink || routePaths.home()}
        className="text-white-opaque flex items-center"
    >
        {backlink && <ArrowBackIos className="text-white-opaque" />}
        <h1
            className={`${
                login ? "my-2" : "hidden"
            } md:block text-center my-0 md:text-2xl`}
            style={{ fontFamily: "Comfortaa", fontWeight: 500 }}
            data-testid="title"
        >
            {children}
        </h1>
    </Link>
)

export const NavSubheader: FC = ({ children }) => (
    <h2
        className="text-center my-0 capitalize sm:text-xl text-lg"
        data-testid="subtitle"
    >
        {children}
    </h2>
)

export const NavTaskIndicator: FC = ({ children }) => (
    <Link
        to={routePaths.tasks()}
        className="text-white-opaque flex items-center text-xs md:text-base border border-black text-opacity-87 rounded p-1 bg-grape-600"
    >
        <PriorityHigh style={{ color: "#DC2626" }} />
        <span className="hidden md:block">{children}</span>
    </Link>
)
