import React, { ChangeEvent, FC, useState } from "react"
import { useDispatch } from "react-redux"
import { resendConfirmationCode } from "../../actions/AuthActions"
import { Alert } from "../../components/atoms/Alert"
import { InputProps } from "../../components/atoms/Input"
import { Link } from "../../components/atoms/Link"
import { LoginButtonProps } from "../../components/atoms/LoginButton"
import { renderMultipleInputsAndButton } from "../../helpers/ui"
import { useAppState } from "../../hooks/useReduxState"
import { MainThunkDispatch } from "../../reducers"
import { MessageSentiment } from "../../reducers/client/MessagesReducer"
import { routePaths } from "../../routes"
import { atoms } from "../../texts"

export const ResendCode: FC = () => {
    const dispatch = useDispatch<MainThunkDispatch>()
    const message = useAppState(state => state.client.messages.current)
    const [email, setEmail] = useState("")
    const emailInput = "email"

    const handleResend = async () => {
        await dispatch(resendConfirmationCode({ email }))
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
        setEmail(event.target.value)

    const resendCode: { inputs: InputProps[]; button: LoginButtonProps } = {
        inputs: [
            {
                label: atoms.labels.email,
                placeholder: atoms.placeholder.email,
                name: emailInput,
                required: true,
                onChange: handleChange,
                value: email
            }
        ],
        button: { label: atoms.buttons.resendCode, onClick: handleResend }
    }

    return (
        <div className="min-w-login max-w-login mx-auto flex flex-col items-center ">
            {message?.text && (
                <Alert
                    success={message.type === MessageSentiment.good}
                    message={message.text}
                />
            )}
            {renderMultipleInputsAndButton(resendCode)}
            <hr className="w-full" />
            <Link to={routePaths.login()}>{atoms.links.login}</Link>
        </div>
    )
}
