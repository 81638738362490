import Auth, { CognitoUser } from "@aws-amplify/auth"
import Amplify from "@aws-amplify/core"
import { Severity } from "@sentry/react"
import { config } from "bridger"
import { push } from "connected-react-router"
import { asError } from "../helpers/error"
import { MainThunkDispatch } from "../reducers"
import { routePaths } from "../routes"
import { apiEndpoints, axios } from "../routes/api"
import { notifications } from "../texts"
import { getWorker } from "./api/ResourceActions"
import { dismissMessage, failMessage, successMessage } from "./MessagesActions"
import { reportError } from "./tracking/MonitoringActions"
import { AuthType } from "./Types"

Auth.configure({ authenticationFlowType: "USER_PASSWORD_AUTH" })

const getUserAttributeSub = (user: CognitoUser): Promise<string> => {
    return new Promise((resolve, reject) => {
        user.getUserAttributes((err, attributes) => {
            if (err) {
                reject(err)
            }
            if (attributes) {
                attributes.forEach(attribute => {
                    if (attribute.getName() === "sub") {
                        resolve(attribute.getValue())
                    }
                })
            }
        })
    })
}

export const initAuth = () => async (dispatch: MainThunkDispatch) => {
    dispatch({ type: AuthType.isSigningIn })
    Amplify.configure({ ...config.crowdHero.cognito })

    try {
        const user: CognitoUser = await Auth.currentAuthenticatedUser()
        if (user) {
            let username = user.getUsername()
            if (username.includes("@")) {
                username = await getUserAttributeSub(user)
            }
            await dispatch(getValidSession())
            await dispatch(getWorker({ userUuid: username }))
            dispatch({ type: AuthType.isSignedIn })
        }
    } catch (error) {
        dispatch(reportError({ error, level: Severity.Fatal }))
        dispatch({ type: AuthType.isNotSignedIn })
    }
    dispatch({ type: AuthType.isNotSigningIn })
}

export const signIn =
    ({ email, password }: { email: string; password: string }) =>
    async (dispatch: MainThunkDispatch): Promise<void> => {
        try {
            dispatch({ type: AuthType.toggleLoading, payload: true })
            const user: CognitoUser = await Auth.signIn(email, password)
            let username = user.getUsername()
            if (username.includes("@")) {
                username = await getUserAttributeSub(user)
            }
            await dispatch(getValidSession())
            await dispatch(getWorker({ userUuid: username }))
            dispatch(dismissMessage())
            dispatch(push(routePaths.missions()))
        } catch (error) {
            dispatch(
                reportError({
                    error,
                    level: Severity.Fatal,
                    extraData: { email }
                })
            )
            dispatch(
                failMessage(
                    asError(error).message || notifications.noErrorMessage
                )
            )
        }
        dispatch({ type: AuthType.toggleLoading, payload: false })
    }

export const startResetting =
    ({ email }: { email: string }) =>
    async (dispatch: MainThunkDispatch): Promise<void> => {
        try {
            await Auth.forgotPassword(email)
            dispatch({ type: AuthType.confirmationMode, payload: true })
            dispatch(dismissMessage())
        } catch (error) {
            dispatch(
                reportError({
                    error,
                    level: Severity.Critical,
                    extraData: { email }
                })
            )
            dispatch(
                failMessage(
                    asError(error).message || notifications.noErrorMessage
                )
            )
        }
    }

export const confirmResetPassword =
    ({
        email,
        password,
        passwordConfirmation,
        confirmationCode
    }: {
        email: string
        password: string
        passwordConfirmation: string
        confirmationCode: string
    }) =>
    async (dispatch: MainThunkDispatch): Promise<void> => {
        if (checkPasswordEquality(password, passwordConfirmation)) {
            try {
                await Auth.forgotPasswordSubmit(
                    email,
                    confirmationCode,
                    password
                )

                dispatch(
                    successMessage(notifications.passwordResetSuccessfulMessage)
                )
                setTimeout(() => {
                    dispatch({
                        type: AuthType.confirmationMode,
                        payload: false
                    })
                    dispatch(push(routePaths.login()))
                    dispatch(dismissMessage())
                }, 2 * 1000)
            } catch (error) {
                dispatch(
                    reportError({
                        error,
                        level: Severity.Error,
                        extraData: { email }
                    })
                )
                dispatch(
                    failMessage(
                        asError(error).message ||
                            notifications.passwordResetFailedMessage
                    )
                )
            }
        } else {
            dispatch(failMessage(notifications.passwordNotTheSameMessage))
        }
    }

export const registerNewUser =
    ({
        email,
        password,
        passwordConfirmation
    }: {
        email: string
        password: string
        passwordConfirmation: string
    }) =>
    async (dispatch: MainThunkDispatch): Promise<boolean> => {
        if (checkPasswordEquality(password, passwordConfirmation)) {
            try {
                await Auth.signUp({
                    username: email,
                    password
                })
                dispatch(dismissMessage())
                return true
            } catch (error) {
                dispatch(
                    reportError({
                        error,
                        level: Severity.Critical,
                        extraData: { email }
                    })
                )
                dispatch(
                    failMessage(
                        asError(error).message || notifications.noErrorMessage
                    )
                )
                throw error
            }
        } else {
            dispatch(failMessage(notifications.passwordNotTheSameMessage))
            return false
        }
    }

export const resendConfirmationCode =
    ({ email }: { email: string }) =>
    async (dispatch: MainThunkDispatch): Promise<void> => {
        try {
            await Auth.resendSignUp(email)

            dispatch(successMessage(notifications.codeResentMessage))
        } catch (error) {
            dispatch(
                reportError({
                    error,
                    level: Severity.Critical,
                    extraData: { email }
                })
            )
            dispatch(
                failMessage(
                    asError(error).message || notifications.noErrorMessage
                )
            )
        }
    }

const checkPasswordEquality = (
    password: string,
    passwordConfirmation: string
) => {
    return password === passwordConfirmation
}

export const getValidSession =
    () =>
    async (dispatch: MainThunkDispatch): Promise<string> => {
        try {
            const session = await Auth.currentSession()

            dispatch({
                type: AuthType.isSignedIn
            })
            return session.getIdToken().getJwtToken()
        } catch (error) {
            dispatch(reportError({ error, level: Severity.Critical }))

            throw Error("Invalid user session.")
        }
    }

export const signOut = () => async (dispatch: MainThunkDispatch) => {
    try {
        await Auth.signOut()
        dispatch({ type: AuthType.isNotSignedIn })
        dispatch({ type: AuthType.isNotSigningIn })
        dispatch({ type: AuthType.resetWorker, payload: undefined })
    } catch (error) {
        dispatch(reportError({ error, level: Severity.Warning }))
        throw error
    }
}

export const updateLoginTime =
    ({ workerUuid }: { workerUuid: string }) =>
    async (dispatch: MainThunkDispatch) => {
        try {
            const token = await dispatch(getValidSession())
            const { url, service } = apiEndpoints.updateWorkerLoginStatus({
                workerUuid
            })
            return await axios({ service }).patch(
                url,
                {},
                {
                    headers: {
                        Authorization: token
                    }
                }
            )
        } catch (error) {
            dispatch(reportError({ error, level: Severity.Warning }))
            throw error
        }
    }
