"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isMultipleTypeSchema = exports.isMultipleTypeObject = void 0;
require("json-schema");
const JSONSchema7_1 = require("./JSONSchema7");
function isMultipleTypeObject(v) {
    return typeof v === "object" && v !== null && "schema_object" in v;
}
exports.isMultipleTypeObject = isMultipleTypeObject;
const supportedItems = [
    "Text",
    "Integer",
    "Float",
    "Boolean",
    "Exclusive Choice",
    "Multiple Choice"
];
/**
 * Tests if the give schema conforms with V.S type config
 */
function isMultipleTypeSchema(schema) {
    return (schema.type === "object" &&
        schema.properties !== undefined &&
        "schema_object" in schema.properties &&
        (0, JSONSchema7_1.isJSONSchema7)(schema.properties.schema_object) &&
        schema.properties.schema_object.oneOf !== undefined &&
        schema.properties.schema_object.oneOf.every(item => {
            var _a, _b, _c;
            if (!(0, JSONSchema7_1.isJSONSchema7)(item)) {
                return false;
            }
            const type = (0, JSONSchema7_1.isJSONSchema7)((_a = item.properties) === null || _a === void 0 ? void 0 : _a["selected_item"]) &&
                ((_c = (_b = item.properties) === null || _b === void 0 ? void 0 : _b["selected_item"]) === null || _c === void 0 ? void 0 : _c.const);
            return supportedItems.includes(type);
        }));
}
exports.isMultipleTypeSchema = isMultipleTypeSchema;
