import {
    Button,
    Checkbox,
    FormControlLabel,
    MenuItem,
    TextField
} from "@material-ui/core"
import Save from "@material-ui/icons/Save"
import React, { ChangeEvent, FC, useEffect, useState } from "react"
import validator from "validator"
import { updateWorker } from "../../actions/api/ResourceActions"
import { checkDateFormat } from "../../helpers/date"
import { useAppDispatch, useAppState } from "../../hooks/useReduxState"
import { ToastStatus, useToast } from "../../hooks/useToast"
import {
    transformProfileStateToWorker,
    transformWorkerToProfileState
} from "../../models/Worker"
import { dateformat, errorMessages, pages, successMessages } from "../../texts"

interface ProfileFieldType {
    label: string
    valid: boolean
    helperText?: string
}

export interface ProfileState {
    email: ProfileFieldType & { value: string }
    firstname: ProfileFieldType & { value?: string }
    lastname: ProfileFieldType & { value?: string }
    dob: ProfileFieldType & { value?: string }
    gender: ProfileFieldType & { value?: string }
    telegram: ProfileFieldType & { value?: string }
    phone: ProfileFieldType & { value?: string }
    address: ProfileFieldType & { value?: string }
    zipcode: ProfileFieldType & { value?: string }
    notification: ProfileFieldType & { value: boolean }
}

export const Profile: FC = () => {
    const worker = useAppState(state => state.auth.worker)
    const dispatch = useAppDispatch()
    const [newWorker, setNewWorker] = useState<ProfileState>()
    const successToast = useToast(ToastStatus.SUCCESS)
    const errorToast = useToast(ToastStatus.ERROR)

    const { labelActive, labelNotifications, save } = pages.profile

    useEffect(() => {
        if (worker) {
            setNewWorker(transformWorkerToProfileState(worker))
        }
    }, [worker, dispatch, labelNotifications, labelActive])

    const handleChangeChecked =
        (stateProp: keyof ProfileState) =>
        (event: ChangeEvent<HTMLInputElement>) => {
            if (newWorker) {
                const value: boolean | string =
                    event.target.checked || event.target.value
                setNewWorker({
                    ...newWorker,
                    [stateProp]: {
                        ...newWorker[stateProp],
                        value,
                        ...handleValidation(stateProp, value)
                    }
                })
            }
        }

    const handleValidation = (
        stateProp: keyof ProfileState,
        value: string | boolean
    ): { valid: boolean; helperText?: string } => {
        switch (stateProp) {
            case "email":
                if (typeof value === "string" && !validator.isEmail(value)) {
                    return {
                        valid: false,
                        helperText: pages.profile.validation.invalidEmail
                    }
                }
                return { valid: true, helperText: "" }

            case "dob":
                if (typeof value === "string" && !checkDateFormat(value)) {
                    return {
                        valid: false,
                        helperText: pages.profile.validation.invalidDob
                    }
                }
                return { valid: true, helperText: "" }
            default:
                return { valid: true }
        }
    }

    const handleSave = async () => {
        if (newWorker && worker) {
            try {
                await dispatch(
                    updateWorker({
                        workerOptions: transformProfileStateToWorker(
                            worker,
                            newWorker
                        )
                    })
                )
                successToast(successMessages.profile.updated)
            } catch (error) {
                errorToast(errorMessages.standard)
            }
        }
    }

    const enableSave = (): boolean => {
        if (newWorker) {
            const isValid = Object.entries(newWorker)
                .map(([key, value]) => value.valid)
                .every(val => val === true)
            return !isValid
        }
        return true
    }

    const inputStyle = "w-full md:w-1/2 xl:w-1/3 mb-4"
    return (
        <div className="flex flex-col items-center">
            {newWorker && (
                <>
                    <TextField
                        className={inputStyle}
                        data-testid={newWorker.email.label.toLowerCase()}
                        value={newWorker.email.value || ""}
                        label={newWorker.email.label}
                        error={!newWorker.email.valid}
                        helperText={newWorker.email.helperText}
                        onChange={handleChangeChecked("email")}
                    />
                    <TextField
                        className={inputStyle}
                        data-testid={newWorker.firstname.label.toLowerCase()}
                        value={newWorker.firstname.value || ""}
                        label={newWorker.firstname.label}
                        onChange={handleChangeChecked("firstname")}
                    />
                    <TextField
                        className={inputStyle}
                        data-testid={newWorker.lastname.label.toLowerCase()}
                        value={newWorker.lastname.value || ""}
                        label={newWorker.lastname.label}
                        onChange={handleChangeChecked("lastname")}
                    />
                    <TextField
                        className={inputStyle}
                        data-testid={newWorker.dob.label.toLowerCase()}
                        value={newWorker.dob.value || ""}
                        label={newWorker.dob.label}
                        error={!newWorker.dob.valid}
                        placeholder={dateformat}
                        helperText={newWorker.dob.helperText}
                        onChange={handleChangeChecked("dob")}
                    />

                    <TextField
                        select={true}
                        label={newWorker.gender.label}
                        className={inputStyle}
                        data-testid={newWorker.gender.label.toLowerCase()}
                        value={newWorker.gender.value ?? ""}
                        onChange={handleChangeChecked("gender")}
                    >
                        <MenuItem value="M">Male</MenuItem>
                        <MenuItem value="F">Female</MenuItem>
                        <MenuItem value="O">Other</MenuItem>
                        <MenuItem value="U">Unspecified</MenuItem>
                    </TextField>
                    <TextField
                        className={inputStyle}
                        data-testid={newWorker.telegram.label.toLowerCase()}
                        value={newWorker.telegram.value || ""}
                        label={newWorker.telegram.label}
                        onChange={handleChangeChecked("telegram")}
                    />
                    <TextField
                        className={inputStyle}
                        data-testid={newWorker.phone.label.toLowerCase()}
                        value={newWorker.phone.value || ""}
                        label={newWorker.phone.label}
                        onChange={handleChangeChecked("phone")}
                    />
                    <TextField
                        className={inputStyle}
                        data-testid={newWorker.address.label.toLowerCase()}
                        value={newWorker.address.value || ""}
                        label={newWorker.address.label}
                        onChange={handleChangeChecked("address")}
                    />
                    <TextField
                        className={inputStyle}
                        data-testid={newWorker.zipcode.label.toLowerCase()}
                        value={newWorker.zipcode.value || ""}
                        label={newWorker.zipcode.label}
                        onChange={handleChangeChecked("zipcode")}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="default"
                                checked={newWorker.notification.value}
                                onChange={handleChangeChecked("notification")}
                            />
                        }
                        label={labelNotifications}
                    />
                </>
            )}

            <div className="flex mt-10">
                <Button
                    data-testid={save.toLowerCase()}
                    onClick={handleSave}
                    disabled={enableSave()}
                    className="bg-neon-gradient-400 text-white-opaque flex items-center"
                >
                    <Save className="mr-2" />
                    <span>{save}</span>
                </Button>
            </div>
        </div>
    )
}
