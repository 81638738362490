import React, { FC } from "react"
import { pages } from "../../texts"

export const BonusDetails: FC = () => {
    const bonusMap = [
        { rank: "🥇 1st", bonus: "$30", className: "text-3xl" },
        { rank: "🥈 2nd", bonus: "$20", className: "text-3xl" },
        { rank: "🥉 3rd", bonus: "$10", className: "text-3xl" },
        { rank: "4th - 10th", bonus: "$5", className: "text-2xl" },
        { rank: "11th - 15th", bonus: "$2", className: "text-2xl" }
    ]
    return (
        <div className="container py-8 max-w-screen-md">
            <h1 className="text-2xl md:text-3xl font-bold mb-6">
                <span role="img" aria-label="money emoji">
                    💰
                </span>{" "}
                {pages.bonus.title}
            </h1>

            <h3 className="text-lg md:text-xl mb-6">
                {pages.bonus.description}
            </h3>

            <div className="mb-6 flex flex-col items-center w-full">
                <div className="text-3xl mb-4">{pages.bonus.tiersTitle}</div>
                <div className="w-48">
                    {bonusMap.map(tier => (
                        <div
                            className={`clear-both mb-2 ${tier.className}`}
                            key={tier.rank}
                        >
                            {tier.rank}
                            <span className="float-right">{tier.bonus}</span>
                        </div>
                    ))}
                </div>
            </div>

            <p className="mt-10">{pages.bonus.finePrint}</p>
        </div>
    )
}
