import DefaultAxios, { AxiosInstance } from "axios"
import axiosRetry from "axios-retry"
import { config } from "bridger"
import { TaskResolveOptions } from "resha"

enum APIEndpointName {
    getSignedUrl = "getSignedUrl",
    getFeedbackOptionsByTaskId = "getFeedbackOptionsByTaskId"
}

type APIEndpointConfig<T> = { [key in APIEndpointName]: T }

export interface APIEndpointInfo {
    url: string
    /**
     * Default to nacelle
     */
    service?: BackendService
}

interface APIEndpointFnArgs {
    [arg: string]: any
}

type APIRouteFn<T extends APIEndpointFnArgs> = (args: T) => APIEndpointInfo

interface SignedUrlArgs {
    workerUuid: string
    taskId: number
    seconds: number
    path: string
}

interface TrainingExampleSignedUrlArgs {
    workerUuid: string
    trainingId: number
    exampleId: number
    sequence: number
    seconds: number
    path: string
}

interface UploadUrlArgs {
    workerUuid: string
    taskId: number
    mimeType: string
    objectName?: string
}

interface APIEndpoints extends APIEndpointConfig<APIRouteFn<any>> {
    // /data
    getSignedUrl: APIRouteFn<SignedUrlArgs>
    getUploadDataUrl: APIRouteFn<UploadUrlArgs>
    getTrainingExampleSignedUrl: APIRouteFn<TrainingExampleSignedUrlArgs>

    // /ledger
    getWorkerBillings: APIRouteFn<{
        workerUuid: string
        startDate: string
        endDate: string
    }>

    // /resource
    getWorker: APIRouteFn<{ workerUuid: string }>
    updateWorker: APIRouteFn<{ workerUuid: string }>
    updateWorkerLoginStatus: APIRouteFn<{ workerUuid: string }>

    // /task
    getTask: APIRouteFn<{
        workerUuid: string
        conversation?: number
    }>
    getTrainingTask: APIRouteFn<{
        workerUuid: string
        trainingId: string
        sequence?: number
    }>
    getTrainingExample: APIRouteFn<{
        workerUuid: string
        trainingId: string
        sequence: number
        exampleId: string
    }>
    postTrainingComplete: APIRouteFn<{
        workerUuid: string
        trainingId: string
        sequence: number
    }>
    resolveTask: APIRouteFn<{
        workerUuid: string
        taskId: number
        resolveAction: TaskResolveOptions
    }>
    taskBigPayload: APIRouteFn<{
        workerUuid: string
        taskId: number
        resolveAction: TaskResolveOptions
        seconds: number
    }>

    // /training
    getTrainings: APIRouteFn<{ workerUuid: string }>
    startTraining: APIRouteFn<{ workerUuid: string; trainingId: number }>
    cancelTraining: APIRouteFn<{ workerUuid: string; trainingId: number }>
    getFeedbackOptionsByTaskId: APIRouteFn<{
        taskId: number
        workerUuid: string
    }>
}

export const apiEndpoints: APIEndpoints = Object.freeze({
    // /data
    getSignedUrl: ({ path, seconds, taskId, workerUuid }): APIEndpointInfo => {
        const searchParams = new URLSearchParams()
        const ownerId = path.match(/^([\d]+)\//)
        const pathWithoutOwnerId = path.replace(/^([\d]+)\//, "")

        searchParams.set("path", pathWithoutOwnerId)
        if (ownerId !== null && ownerId[1]) {
            searchParams.set("ownerId", ownerId[1])
        }
        searchParams.set("secondsTtl", `${seconds}`)
        return {
            url: `/data/${workerUuid}/${taskId}/url?${searchParams.toString()}`
        }
    },

    getUploadDataUrl: ({
        mimeType,
        taskId,
        workerUuid,
        objectName
    }): APIEndpointInfo => ({
        url: `/data/${workerUuid}/task/${taskId}${
            objectName === undefined ? "" : `/${objectName}`
        }?mimeType=${encodeURIComponent(mimeType)}&uploadUrl=true`,
        service: "turbine"
    }),
    getTrainingExampleSignedUrl: ({
        path,
        seconds,
        trainingId,
        workerUuid,
        sequence,
        exampleId
    }): APIEndpointInfo => {
        const searchParams = new URLSearchParams()
        const ownerId = path.match(/^([\d]+)\//)
        const pathWithoutOwnerId = path.replace(/^([\d]+)\//, "")

        searchParams.set("path", pathWithoutOwnerId)
        if (ownerId !== null && ownerId[1]) {
            searchParams.set("ownerId", ownerId[1])
        }
        searchParams.set("secondsTtl", `${seconds}`)
        searchParams.set("sequence", `${sequence}`)
        searchParams.set("exampleId", `${exampleId}`)
        return {
            url: `/data/${workerUuid}/training/${trainingId}/url?${searchParams.toString()}`
        }
    },

    // /ledger
    getWorkerBillings: ({
        workerUuid,
        startDate,
        endDate
    }): APIEndpointInfo => ({
        url: `/ledger/billing/${workerUuid}/?startDate=${startDate}&endDate=${endDate}`,
        service: "turbine"
    }),

    // /resource
    getWorker: ({ workerUuid }): APIEndpointInfo => ({
        url: `/resource/hero/${workerUuid}`,
        service: "turbine"
    }),
    updateWorker: ({ workerUuid }): APIEndpointInfo => ({
        url: `/resource/hero/${workerUuid}`,
        service: "turbine"
    }),
    updateWorkerLoginStatus: ({ workerUuid }): APIEndpointInfo => ({
        url: `/resource/workers/login/${workerUuid}?loggedIn=true`,
        service: "turbine"
    }),

    // /task
    getTask: ({ workerUuid, conversation }): APIEndpointInfo => ({
        url: `/taskqueue/pendingmessage/${workerUuid}${
            conversation ? `/${conversation}` : ""
        }`
    }),
    getTrainingTask: ({
        workerUuid,
        trainingId,
        sequence
    }): APIEndpointInfo => ({
        url: `/taskqueue/pendingmessage/${workerUuid}/training/${trainingId}${
            sequence !== undefined ? `?sequence=${sequence}` : ""
        }`
    }),
    getTrainingExample: ({
        workerUuid,
        trainingId,
        sequence,
        exampleId
    }): APIEndpointInfo => ({
        url: `/taskqueue/message/${workerUuid}/training/${trainingId}?sequence=${sequence}&exampleId=${exampleId}`
    }),
    resolveTask: ({ workerUuid, taskId, resolveAction }): APIEndpointInfo => ({
        url: `/taskqueue/tasks/${workerUuid}/${taskId}?action=${resolveAction}`
    }),
    taskBigPayload: ({
        workerUuid,
        taskId,
        resolveAction,
        seconds
    }): APIEndpointInfo => ({
        url: `/task-big-payload/${workerUuid}/${taskId}?action=${resolveAction}&secondsTtl=${seconds}`
    }),
    // /training
    getTrainings: ({ workerUuid }): APIEndpointInfo => ({
        url: `/training/${workerUuid}`,
        service: "turbine"
    }),
    startTraining: ({ workerUuid, trainingId }): APIEndpointInfo => ({
        url: `/training/${workerUuid}/?trainingId=${trainingId}`,
        service: "turbine"
    }),
    cancelTraining: ({ workerUuid, trainingId }): APIEndpointInfo => ({
        url: `/training/${workerUuid}/${trainingId}`,
        service: "turbine"
    }),
    postTrainingComplete: ({
        workerUuid,
        trainingId,
        sequence
    }): APIEndpointInfo => ({
        url: `/training/${workerUuid}/${trainingId}?sequence=${sequence}`,
        service: "turbine"
    }),
    getFeedbackOptionsByTaskId: ({ workerUuid, taskId }): APIEndpointInfo => ({
        url: `taskqueue/feedbackoptions/${workerUuid}/${taskId}`
    })
})

export type BackendService = "turbine" | "nacelle"

type CustomAxiosReturn = (config: { service?: BackendService }) => AxiosInstance

const customAxios = (): CustomAxiosReturn => {
    const axiosInstance = DefaultAxios.create({
        headers: {
            "x-api-key": config.crowdHero.apiKey
        }
    })

    return ({ service }) => {
        switch (service) {
            case "turbine":
                axiosInstance.defaults.baseURL = config.turbineUrl
                break
            default:
                axiosInstance.defaults.baseURL = config.nacelle
                break
        }

        return axiosInstance
    }
}

export const axios = customAxios()

type CustomAxiosRetryReturn = (config: {
    service?: BackendService
    retries?: number
    retryDelay?: () => number
}) => AxiosInstance

const customAxiosRetry = (): CustomAxiosRetryReturn => {
    const axiosInstance = DefaultAxios.create({
        headers: {
            "x-api-key": config.crowdHero.apiKey
        }
    })

    return ({ service, retries = 0, retryDelay }) => {
        axiosRetry(axiosInstance, { retries, retryDelay })
        if (service) {
            axiosInstance.defaults.baseURL = config.turbineUrl
            return axiosInstance
        }
        axiosInstance.defaults.baseURL = config.nacelle
        return axiosInstance
    }
}

export const axiosWithRetry = customAxiosRetry()
