import { ConnectedRouter } from "connected-react-router"
import { History } from "history"
import { FC, useEffect } from "react"
import { useAppState } from "./hooks/useReduxState"
import { Routes } from "./routes"
import { DashStyled } from "dash-styled"
import { HelmetProvider, Helmet } from "react-helmet-async"
import * as Sentry from "@sentry/react"
import { ConnectionLostModal } from "./components/ConnectionLostModal/ConnectionLostModal"
import { TimeoutModal } from "./components/TimeoutModal/TimeoutModal"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

interface Props {
    history: History
}

export const App: FC<Props> = ({ history }) => {
    const { worker } = useAppState(state => state.auth)

    useEffect(() => {
        Sentry.setUser(
            worker === undefined
                ? null
                : { id: worker.uuid, email: worker.email }
        )
    }, [worker])

    return (
        <HelmetProvider>
            <DashStyled>
                <Helmet>
                    <title>super.AI - Crowd Hero</title>
                </Helmet>

                <ConnectionLostModal />
                <TimeoutModal />
                <ConnectedRouter history={history}>
                    <Routes />
                </ConnectedRouter>
                <ToastContainer className="break-words" />
            </DashStyled>
        </HelmetProvider>
    )
}
