"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UniversalSchemaType = void 0;
require("../0.0.3");
require("./instructions-schema");
require("./choice-schema");
require("./image-schema");
require("./geometry-schema");
require("./image-presentation");
require("./satellite-schema");
require("./block-schema");
require("./checkbox");
require("./document");
require("./label-source");
var universal_schema_types_enum_1 = require("./universal_schema_types_enum");
Object.defineProperty(exports, "UniversalSchemaType", { enumerable: true, get: function () { return universal_schema_types_enum_1.UniversalSchemaType; } });
