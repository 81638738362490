"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UniversalSchemaType = void 0;
require("./base-schema");
require("./choice-schema");
require("./geometry-schema");
require("./image-schema");
require("./text-schema");
const universal_schema_types_enum_1 = require("./universal_schema_types_enum");
Object.defineProperty(exports, "UniversalSchemaType", { enumerable: true, get: function () { return universal_schema_types_enum_1.UniversalSchemaType; } });
