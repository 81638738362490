import { push } from "connected-react-router"
import React, { FC, useEffect } from "react"
import { showTimeoutModal } from "../../actions/ModalActions"
import { prettifyCountdown } from "../../helpers/ui"
import { useBoolean } from "../../hooks/useBoolean"
import { useIdle } from "../../hooks/useIdle"
import { useAppDispatch, useAppState } from "../../hooks/useReduxState"
import { routePaths } from "../../routes"
import { componentsText } from "../../texts"
import { Modal } from "../Modal/Modal"

const INITIAL_COUNTDOWN = 60 * 5 - 20 - 5 // 5min - 20s keep alive - 5s buffer
const LAST_CHANCE_BOUNDARY = 60 // 1min notice window
const RED_ZONE = 20 // last 20 seconds

export const DeadManSwitch: FC = () => {
    const dispatch = useAppDispatch()
    const { worker } = useAppState(state => state.auth)
    const [show, { setToFalse, setToTrue }] = useBoolean(false)
    const [isIdle, countdown, setCountdown] = useIdle(
        INITIAL_COUNTDOWN,
        LAST_CHANCE_BOUNDARY
    )

    useEffect(() => {
        if (worker?.active) {
            if (countdown === LAST_CHANCE_BOUNDARY) {
                setToTrue()
            }

            if (isIdle) {
                dispatch(push(routePaths.missions()))
                dispatch(showTimeoutModal())
            }
        }
    }, [countdown, dispatch, isIdle, setToTrue, worker])

    useEffect(() => {
        if (!show) {
            setCountdown(INITIAL_COUNTDOWN)
        }
    }, [setCountdown, show])

    useEffect(() => {
        if (countdown === INITIAL_COUNTDOWN) {
            setToFalse()
        }
    }, [countdown, isIdle, setToFalse])

    return (
        <Modal open={show} disableBackdropClick={true}>
            <h2 className="font-bold md:text-lg text-center mb-0">
                {componentsText.deadMansSwitch.titleCountdown}
            </h2>
            <hr className="bg-gray-400 w-full my-4" />
            <span className="mb-4 text-center">
                {componentsText.deadMansSwitch.description}
                {
                    <span
                        className={`${
                            countdown <= RED_ZONE ? "text-blood-500" : ""
                        }`}
                    >
                        {prettifyCountdown(countdown)}
                    </span>
                }
            </span>
        </Modal>
    )
}
