import { Severity } from "@sentry/react"
import { Worker } from "../../models/Worker"
import { MainThunkDispatch, State } from "../../reducers"
import { apiEndpoints, axios } from "../../routes/api"
import { getValidSession } from "../AuthActions"
import { reportError } from "../tracking/MonitoringActions"
import { AuthType } from "../Types"
import { changeSearchingStatus } from "./TaskActions"

export const getWorker =
    ({ userUuid: workerUuid }: { userUuid: string }) =>
    async (dispatch: MainThunkDispatch, getState: () => State) => {
        const token = await dispatch(getValidSession())
        const { url, service } = apiEndpoints.getWorker({ workerUuid })
        try {
            const response = await axios({ service }).get<Worker>(url, {
                headers: {
                    Authorization: token
                }
            })

            dispatch({ type: AuthType.loggedIn, payload: response.data })
        } catch (error) {
            dispatch(
                reportError({
                    error,
                    level: Severity.Critical,
                    extraData: { workerUuid }
                })
            )
            throw error
        }
    }

export const updateWorker =
    ({ workerOptions }: { workerOptions: Worker }) =>
    async (dispatch: MainThunkDispatch, getState: () => State) => {
        const token = await dispatch(getValidSession())
        const worker = getState().auth.worker
        if (worker) {
            const { url, service } = apiEndpoints.updateWorker({
                workerUuid: worker.uuid
            })
            try {
                await axios({ service }).put(url, workerOptions, {
                    headers: {
                        Authorization: token
                    }
                })
                dispatch({ type: AuthType.loggedIn, payload: workerOptions })
            } catch (error) {
                dispatch(
                    reportError({
                        error,
                        level: Severity.Error,
                        extraData: {
                            workerOptions
                        }
                    })
                )
                throw error
            }
        }
    }

export const activateWorker =
    () => async (dispatch: MainThunkDispatch, getState: () => State) => {
        const { worker } = getState().auth
        if (worker) {
            try {
                dispatch(changeSearchingStatus(true))
                await dispatch(
                    updateWorker({ workerOptions: { ...worker, active: true } })
                )
            } catch (error) {
                dispatch(
                    reportError({
                        error,
                        level: Severity.Error
                    })
                )
                throw error
            }
        }
    }
