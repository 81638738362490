import React, { FC, MouseEvent } from "react"
import { useDispatch } from "react-redux"
import { MainThunkDispatch } from "../../reducers"
import { push } from "connected-react-router"
import { dismissMessage } from "../../actions/MessagesActions"
import styles from "./Link.module.css"

interface LinkProps {
    to: string
    className?: string
    onClick?: Function
}

export const Link: FC<LinkProps> = ({ to, children, className, onClick }) => {
    const dispatch = useDispatch<MainThunkDispatch>()

    const handleRouteChange = (event: MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault()
        dispatch(push(to))
        dispatch(dismissMessage())
        onClick?.()
    }

    return (
        <a
            href={to}
            className={
                className
                    ? className
                    : `mx-4 text-sm hover:underline ${styles.component}`
            }
            onClick={handleRouteChange}
        >
            {children}
        </a>
    )
}
