import moment from "moment"
import { Divider } from "@material-ui/core"
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
    MomentUtils
} from "dash-styled"
import React, {
    useCallback,
    useEffect,
    useState,
    Dispatch,
    SetStateAction
} from "react"
import { getWorkerBillings } from "../../actions/api/BillingsActions"
import { CardFrame } from "../../components/atoms/CardFrame"
import { EarningsExpansionPanel } from "../../components/EarningsExpansionPanel/EarningsExpansionPanel"
import {
    getCHStart,
    getMonthEnd,
    getMonthStart,
    getPrevMonthEnd,
    getPrevMonthStart,
    prepareDateForBackend
} from "../../helpers/date"
import { useAppDispatch, useAppState } from "../../hooks/useReduxState"
import { Billing } from "../../models/Billing"

interface EarningsDetail {
    earnings: Billing[]
    totalEarning: number
    totalTasks: number
}

interface EarningsSummary {
    totalEarning: number
    totalTasks: number
}

interface EarningsRange {
    startDate: Date | null
    endDate: Date | null
}

enum Range {
    ALL = "all",
    THIS_MONTH = "this_month",
    LAST_MONTH = "last_month"
}

export const Earnings = () => {
    const worker = useAppState(appState => appState.auth.worker)
    const [state, setState] = useState<EarningsDetail>({
        earnings: [],
        totalEarning: 0,
        totalTasks: 0
    })
    const [monthToDateEarnings, setMonthToDateEarnings] =
        useState<EarningsSummary>({
            totalEarning: 0,
            totalTasks: 0
        })
    const [lastMonthEarnings, setLastMonthEarnings] = useState<EarningsSummary>(
        {
            totalEarning: 0,
            totalTasks: 0
        }
    )
    const [lifetimeEarnings, setLifetimeEarnings] = useState<EarningsSummary>({
        totalEarning: 0,
        totalTasks: 0
    })
    const [activeRange, setActiveRange] = useState<Range>(Range.THIS_MONTH)
    const dispatch = useAppDispatch()
    const [startDate, setStartDate] = useState<Date | null>(getMonthStart())
    const [endDate, setEndDate] = useState<Date | null>(getMonthEnd())

    const handleStartDateChange = (date: moment.Moment | Date | null) => {
        setStartDate(moment.isMoment(date) ? date.toDate() : date)
    }

    const handleEndDateChange = (date: moment.Moment | Date | null) => {
        setEndDate(moment.isMoment(date) ? date.toDate() : date)
    }

    const handleSetDateRange = (range: Range) => () => {
        setActiveRange(range)
        const curRange = getDateRange(range)
        setStartDate(curRange.startDate)
        setEndDate(curRange.endDate)
    }

    const getDateRange = (range: Range) => {
        const ranges = {
            [Range.ALL]: {
                startDate: getCHStart(),
                endDate: getMonthEnd()
            },
            [Range.THIS_MONTH]: {
                startDate: getMonthStart(),
                endDate: getMonthEnd()
            },
            [Range.LAST_MONTH]: {
                startDate: getPrevMonthStart(),
                endDate: getPrevMonthEnd()
            }
        }
        return ranges[range]
    }

    // TODO: Switch to getWorkerBillingsSummary when available. Currently pulling full history of
    // tasks when unnecessary.
    const getEarningsBase = useCallback(
        async (
            range: EarningsRange,
            cb:
                | Dispatch<SetStateAction<EarningsSummary>>
                | Dispatch<SetStateAction<EarningsDetail>>
        ) => {
            if (worker && range.startDate && range.endDate) {
                try {
                    const response = await dispatch(
                        getWorkerBillings({
                            userUuid: worker.uuid,
                            startDate: prepareDateForBackend(range.startDate),
                            endDate: prepareDateForBackend(range.endDate, true)
                        })
                    )
                    cb(response)
                } catch (error) {
                    console.error(`Error: ${error}`)
                }
            }
        },
        [dispatch, worker]
    )

    const getEarnings = useCallback(async () => {
        getEarningsBase(getDateRange(Range.THIS_MONTH), setMonthToDateEarnings)
        getEarningsBase(getDateRange(Range.LAST_MONTH), setLastMonthEarnings)
        getEarningsBase(getDateRange(Range.ALL), setLifetimeEarnings)
    }, [getEarningsBase])

    const handleRefresh = useCallback(async () => {
        if (startDate && endDate) {
            const range = { startDate, endDate }
            await getEarningsBase(range, setState)
        }
    }, [startDate, endDate, getEarningsBase])

    useEffect(() => {
        getEarnings()
    }, [getEarnings, worker])

    useEffect(() => {
        handleRefresh()
    }, [startDate, endDate, handleRefresh])

    return (
        <div className="flex flex-col">
            <div className="self-center flex text-lg mb-6 w-full justify-evenly sm:justify-center">
                {[
                    {
                        title: "This month",
                        earnings: monthToDateEarnings,
                        range: Range.THIS_MONTH
                    },
                    {
                        title: "Last month",
                        earnings: lastMonthEarnings,
                        range: Range.LAST_MONTH
                    },
                    {
                        title: "Lifetime",
                        earnings: lifetimeEarnings,
                        range: Range.ALL
                    }
                ].map(period => (
                    <CardFrame
                        key={period.title}
                        className="w-1/3 md:w-32 mx-1 sm:mx-8 text-center pb-4"
                        onClick={handleSetDateRange(period.range)}
                        active={period.range === activeRange}
                    >
                        <div className="text-md mb-2">{period.title}</div>
                        <div className="font-bold">
                            $
                            {period.earnings.totalEarning.toLocaleString(
                                undefined,
                                {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                }
                            )}
                        </div>
                    </CardFrame>
                ))}
            </div>
            <Divider />
            <div className="flex flex-col-reverse md:flex-row justify-center md:items-center mt-2">
                <div className="flex justify-center">
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                            className="mr-8 w-40"
                            disableToolbar={true}
                            variant="inline"
                            format="MM/DD/YYYY"
                            margin="normal"
                            id="date-picker-inline"
                            label="Start Date"
                            value={startDate}
                            onChange={handleStartDateChange}
                            autoOk={true}
                            KeyboardButtonProps={{
                                "aria-label": "change start date"
                            }}
                        />
                        <KeyboardDatePicker
                            className="w-40"
                            disableToolbar={true}
                            variant="inline"
                            format="MM/DD/YYYY"
                            margin="normal"
                            id="date-picker-inline"
                            label="End Date"
                            value={endDate}
                            onChange={handleEndDateChange}
                            autoOk={true}
                            KeyboardButtonProps={{
                                "aria-label": "change end date"
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </div>
            </div>
            <div className="self-center md:self-start flex text-lg mb-2">
                <span className="mr-2">
                    Total Earning: ${state.totalEarning.toFixed(2)}
                </span>
                <span>Total Tasks: {state.totalTasks}</span>
            </div>
            {state.earnings.map((earning, index) => (
                <EarningsExpansionPanel key={index} earning={earning} />
            ))}
        </div>
    )
}
