import { LinearProgress } from "@material-ui/core"
import Axios from "axios"
import { push } from "connected-react-router"
import React, { FC, RefObject, useEffect, useRef } from "react"
import { activateWorker } from "../../actions/api/ResourceActions"
import {
    changeSearchingStatus,
    getTask,
    setTaskAction
} from "../../actions/api/TaskActions"
import { Button } from "../../components/atoms/Button"
import { Modal } from "../../components/Modal/Modal"
import { Qualifications } from "../../components/Qualifications"
import { useBoolean } from "../../hooks/useBoolean"
import { useAppDispatch, useAppState } from "../../hooks/useReduxState"
import { routePaths } from "../../routes"
import { pages } from "../../texts"
import { Task } from "./task/Task"

export interface TasksLocationState {
    conversation?: number
}

interface TasksProps extends TasksLocationState {
    toolbarRef: RefObject<HTMLDivElement>
}

export const Tasks: FC<TasksProps> = ({ conversation, toolbarRef }) => {
    const worker = useAppState(state => state.auth.worker)
    const dispatch = useAppDispatch()
    const userIsActive = worker?.active
    const cancelRef = useRef(Axios.CancelToken.source())
    const [open, { setToFalse, setToTrue }] = useBoolean(false)
    const task = useAppState(state => state.api.task.task)

    useEffect(() => {
        if (worker) {
            if (!worker.active) {
                dispatch(activateWorker())
            }
            const cancelToken = cancelRef.current
            const fetchTask = async () => {
                try {
                    dispatch(changeSearchingStatus(true))
                    const newTask = await dispatch(
                        getTask({
                            worker,
                            conversation,
                            cancelToken: cancelToken.token
                        })
                    )
                    if (newTask) {
                        if (newTask.type === "END") {
                            dispatch(push(routePaths.missions()))
                        } else {
                            dispatch(setTaskAction(newTask))
                            dispatch(
                                push(routePaths.task(newTask.id), {
                                    conversation
                                })
                            )
                        }
                    }
                } catch (error) {
                    if ((error as any)?.response?.status === 409) {
                        setToTrue()
                    }
                }
            }

            if (task === undefined) fetchTask()
        }

        return () => {
            dispatch(changeSearchingStatus(false))
        }
    }, [dispatch, conversation, worker, setToTrue, task])

    useEffect(() => {
        const cancelToken = cancelRef.current
        return () => {
            cancelToken.cancel()
        }
    }, [])

    const renderUserWaitingForTask = () =>
        userIsActive && (
            <>
                <p className="mb-16 text-lg">{pages.tasks.waitForTasks}</p>
                <LinearProgress
                    classes={{
                        bar: "bg-white"
                    }}
                    className="bg-neon-gradient-400"
                />
                <Qualifications uuid={worker?.uuid} />
            </>
        )

    const handleClose = () => {
        setToFalse()
        dispatch(push(routePaths.missions()))
    }

    return worker !== undefined && task !== undefined ? (
        <Task
            toolbarRef={toolbarRef}
            task={task}
            worker={worker}
            conversation={conversation}
        />
    ) : (
        <div className="flex flex-col px-3 py-2">
            {renderUserWaitingForTask()}
            <Modal open={open} onClose={handleClose}>
                <h2
                    id="409-modal"
                    className="font-bold md:text-lg text-center mb-0"
                >
                    {pages.tasks.error409}
                </h2>
                <hr className="bg-gray-400 w-full" />
                <Button
                    className=" bg-neon-gradient-400 hover:bg-primary-darker text-white-opaque"
                    onClick={handleClose}
                    capitalize={true}
                >
                    {pages.tasks.modalButton}
                </Button>
            </Modal>
        </div>
    )
}
