export interface Training {
    conversation?: number
    created: string
    description: string
    id: number
    money: number
    parentId: number
    status: TrainingStatus
    title: string
    type: TrainingType
}

export enum TrainingType {
    TRAINING = "TRAINING",
    ONBOARDING = "ONBOARDING",
    QUALIFIER = "QUALIFIER",
    TUTORIAL = "TUTORIAL",
    TEST = "TEST"
}

export enum TrainingStatus {
    UNLOCKED = "UNLOCKED",
    LOCKED = "LOCKED",
    IN_PROGRESS = "IN_PROGRESS",
    COMPLETED = "COMPLETED",
    FAILED = "FAILED"
}

export const sortTrainingStatusInProgressFirst = (
    { status: statusA }: Training,
    { status: statusB }: Training
) =>
    statusA === statusB
        ? 0
        : statusA === TrainingStatus.IN_PROGRESS
        ? -1
        : statusB === TrainingStatus.IN_PROGRESS
        ? 1
        : -1

// TUTORIAL first, then TEST and then remaining trainings (sorted alphabetically within each Type)
export const sortTrainingTypeNewTrainingFirst = (
    { type: typeA, title: titleA }: Training,
    { type: typeB, title: titleB }: Training
) =>
    typeA === typeB
        ? titleA.localeCompare(titleB)
        : typeA === TrainingType.TEST && typeB === TrainingType.TUTORIAL
        ? 1
        : typeA === TrainingType.TUTORIAL && typeB === TrainingType.TEST
        ? -1
        : typeA === TrainingType.TUTORIAL
        ? -1
        : typeB === TrainingType.TUTORIAL
        ? 1
        : typeA === TrainingType.TEST
        ? -1
        : typeB === TrainingType.TEST
        ? 1
        : 0
