// Check dateformat to be yyyy-mm-DD
const dateFormat = /^\d{4}[-]\d{2}[-]\d{2}$/

export const prepareDateWithoutTimeForBackend = (date: Date) => {
    return date.toISOString().slice(0, 10)
}

export const prepareDateForBackend = (date: Date, eod = false) => {
    const month = date.getMonth() + 1
    return `${date.getFullYear()}-${month}-${date.getDate()}${
        !eod ? "T00:00:00.000-00:00" : "T23:59:59.999-00:00"
    }`
}

export const checkDateFormat = (date: string) => dateFormat.test(date)

export const getMonthStart = () => {
    const now = new Date()
    return new Date(now.getFullYear(), now.getMonth(), 1)
}

export const getMonthEnd = () => {
    const now = new Date()
    return new Date(now.getFullYear(), now.getMonth() + 1, 0)
}

export const getPrevMonthStart = () => {
    const now = new Date()
    return new Date(now.getFullYear(), now.getMonth() - 1, 1)
}

export const getPrevMonthEnd = () => {
    const now = new Date()
    return new Date(now.getFullYear(), now.getMonth(), 0)
}

export const getCHStart = () => {
    return new Date(2019, 0, 1) // First recorded task
}
