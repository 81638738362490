import { Reducer } from "redux"
import { AuthType, TrainingsType } from "../../actions/Types"
import { Training, TrainingStatus } from "../../models/Training"
import { AuthAction } from "../auth"

export interface TrainingState {
    trainings: Training[]
    loading: number
}

export interface FetchAllTrainingsAction {
    type: TrainingsType.getTrainings
    payload: Training[]
}
export interface StartTrainingAction {
    type: TrainingsType.startTraining
    payload: number
}
export interface CancelTrainingAction {
    type: TrainingsType.cancelTraining
    payload: number
}

export interface ToggleLoadingForTrainingAction {
    type: TrainingsType.toggleLoading
    payload: number
}

export type TrainingActions =
    | FetchAllTrainingsAction
    | StartTrainingAction
    | CancelTrainingAction
    | ToggleLoadingForTrainingAction

const initialState: TrainingState = {
    trainings: [],
    loading: -1
}

export const TrainingReducer: Reducer<
    TrainingState,
    TrainingActions | AuthAction
> = (state = initialState, action): TrainingState => {
    switch (action.type) {
        case AuthType.isNotSignedIn:
            return initialState

        case TrainingsType.getTrainings:
            return {
                ...state,
                trainings: action.payload
            }
        case TrainingsType.startTraining:
            return {
                ...state,
                trainings: state.trainings.map(training =>
                    training.id === action.payload
                        ? { ...training, status: TrainingStatus.IN_PROGRESS }
                        : training
                )
            }
        case TrainingsType.cancelTraining:
            return {
                ...state,
                trainings: state.trainings.map(training =>
                    training.id === action.payload
                        ? { ...training, status: TrainingStatus.UNLOCKED }
                        : training
                )
            }
        case TrainingsType.toggleLoading:
            return {
                ...state,
                loading: action.payload
            }
        default:
            return state
    }
}
