import * as Sentry from "@sentry/react"
import { config } from "bridger"
import { createBrowserHistory } from "history"
import React from "react"
import { hydrate, render } from "react-dom"
import { Provider } from "react-redux"
import { Store } from "redux"
import { App } from "./App"
import { MainAction, State } from "./reducers"
import { configureStore } from "./store"

import "dash-styled/dist/main.css"
import "resha/build/main.css"
import "ramper/dist/main.css"
import "./index.css"

Sentry.init({
    dsn: config.crowdHero.sentry?.dsn,
    environment: process.env.REACT_APP_BUILD_ENV,
    ignoreErrors: [
        // fetch API network error
        "Failed to fetch",
        // axios network error
        "Network Error",
        // cognito network error
        "Network error",
        "Resource blocked by content blocker",
        "ResizeObserver loop limit exceeded"
    ]
})

// Set up router
const history = createBrowserHistory()
// Create redux store
const store: Store<State, MainAction> = configureStore(history)()

const main = () => {
    const rootElement = document.querySelector<HTMLDivElement>("#root")
    const app = (
        <Provider store={store}>
            <App history={history} />
        </Provider>
    )
    if (rootElement !== null && rootElement.hasChildNodes()) {
        hydrate(app, rootElement)
    } else {
        render(app, rootElement)
    }
}

main()

// Dev only hot reloading
if (process.env.NODE_ENV !== "production" && (module as any).hot) {
    ;(module as any).hot.accept("./App", main)
}
