import { push } from "connected-react-router"
import React, { FC, useEffect } from "react"
import { useDispatch } from "react-redux"
import { useBoolean } from "../../hooks/useBoolean"
import { useAppState } from "../../hooks/useReduxState"
import { Role } from "../../models/Worker"
import { routePaths } from "../../routes"

interface AuthCheckProps {
    isLoginComponent?: boolean
    allowedRoles?: Role[] | undefined
}

export const AuthCheck: FC<AuthCheckProps> = ({
    children,
    isLoginComponent,
    allowedRoles
}) => {
    const [allowed, { setToTrue: setAllowedTrue }] = useBoolean(false)
    const { isSignedIn, worker } = useAppState(state => state.auth)
    const dispatch = useDispatch()

    useEffect(() => {
        const authentificationNotRequired = isSignedIn && isLoginComponent
        if (authentificationNotRequired) {
            dispatch(push(routePaths.missions()))
        }
        const authentificationRequired = !isSignedIn && !isLoginComponent
        if (authentificationRequired) {
            dispatch(push(routePaths.login()))
        }
    }, [dispatch, isLoginComponent, isSignedIn])

    useEffect(() => {
        if (!allowedRoles) {
            setAllowedTrue()
        } else if (allowedRoles && worker) {
            if (allowedRoles.includes(worker.role)) {
                setAllowedTrue()
            } else {
                dispatch(push(routePaths.missions()))
            }
        }
    }, [allowedRoles, dispatch, setAllowedTrue, worker])

    return <>{allowed && children}</>
}
