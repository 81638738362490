import { Severity } from "@sentry/react"
import DefaultAxios, { CancelToken } from "axios"
import { push } from "connected-react-router"
import { withExponentialBackoff } from "../../helpers/request-utils"
import {
    ExampleRenderResponse,
    isBigPayloadTask,
    TaskResolveOptions,
    TaskResponse,
    load,
    save,
    LocalStorageKey,
    clear,
    TaskPayload,
    ReferenceTaskPayload
} from "resha"
import { Worker, WorkerFeedback } from "../../models/Worker"
import { MainThunkDispatch, State } from "../../reducers"
import { routePaths } from "../../routes"
import {
    apiEndpoints,
    axios,
    axiosWithRetry,
    BackendService
} from "../../routes/api"
import { getValidSession } from "../AuthActions"
import { reportError } from "../tracking/MonitoringActions"
import { TaskType } from "../Types"
import { TaskActions } from "../../reducers/api/TaskReducer"

export const getTask =
    ({
        worker,
        conversation,
        cancelToken
    }: {
        worker: Worker
        conversation?: number
        cancelToken: CancelToken
    }) =>
    async (dispatch: MainThunkDispatch) => {
        const { url, service } = apiEndpoints.getTask({
            workerUuid: worker.uuid,
            conversation
        })
        return dispatch(
            fetchTask({
                worker,
                url,
                service,
                cancelToken,
                errorExtraData: { conversation }
            })
        )
    }

export const getTrainingTask =
    ({
        worker,
        trainingId,
        sequence,
        cancelToken
    }: {
        worker: Worker
        trainingId: string
        sequence?: number
        cancelToken: CancelToken
    }) =>
    async (dispatch: MainThunkDispatch) => {
        const { url, service } = apiEndpoints.getTrainingTask({
            workerUuid: worker.uuid,
            trainingId,
            sequence
        })
        return dispatch(
            fetchTask({
                worker,
                url,
                service,
                cancelToken,
                errorExtraData: { trainingId, sequence }
            })
        )
    }

export const fetchTask =
    ({
        worker,
        url,
        service,
        cancelToken,
        errorExtraData
    }: {
        worker: Worker
        url: string
        service?: BackendService
        cancelToken: CancelToken
        errorExtraData?: {
            conversation?: number
            trainingId?: string
            sequence?: number
        }
    }) =>
    async (dispatch: MainThunkDispatch, getState: () => State) => {
        const token = await dispatch(getValidSession())

        if (worker.active && token) {
            let fetchAttemps = 0
            const resolvingTaskId = load(LocalStorageKey.RESOLVING_TASK_ID)

            const remoteFetchTask = async () => {
                const { data } = await axios({ service }).get<TaskResponse>(
                    url,
                    {
                        headers: {
                            Authorization: token,
                            "Cache-Control": "no-cache, no-store"
                        },
                        cancelToken
                    }
                )
                if (data) {
                    const { id, jobId } = data
                    dispatch({
                        type: TaskType.setTaskIds,
                        payload: { id, jobId }
                    })
                    return data
                }
            }

            const withBackoff = withExponentialBackoff()

            const handleError = (error: unknown): void => {
                fetchAttemps += 1

                if (fetchAttemps >= 50) {
                    dispatch(push(routePaths.missions()))
                    dispatch(
                        reportError({
                            error,
                            level: Severity.Warning,
                            extraData: errorExtraData
                        })
                    )
                }
            }

            while (fetchAttemps < 50 && getState().api.task.searching) {
                try {
                    const task = await remoteFetchTask()
                    // if same task id is fetched that was previously resolved (relevant for bigpayload), then wait for 2 seconds and fetch again
                    if (
                        resolvingTaskId !== undefined &&
                        String(task?.id) === resolvingTaskId
                    ) {
                        await new Promise(resolve => setTimeout(resolve, 2000))
                        continue
                    }
                    if (task && getState().api.task.searching) {
                        dispatch(changeSearchingStatus(false))
                        if (resolvingTaskId !== undefined) {
                            clear(LocalStorageKey.RESOLVING_TASK_ID)
                        }
                        return task
                    }
                } catch (error) {
                    await withBackoff(() => handleError(error))

                    if (!DefaultAxios.isCancel(error)) {
                        throw error
                    }
                }
            }
        }
    }

export const changeSearchingStatus =
    (searchingStatus: boolean) =>
    (dispatch: MainThunkDispatch): void => {
        dispatch({ type: TaskType.setSearching, payload: searchingStatus })
    }

export const resolveTask =
    ({
        resolveAction,
        taskId,
        output,
        feedback
    }: {
        taskId: number
        output?: TaskPayload
        resolveAction: TaskResolveOptions
        feedback?: WorkerFeedback
    }) =>
    async (
        dispatch: MainThunkDispatch,
        getState: () => State
    ): Promise<void> => {
        const user = getState().auth.worker
        const token = await dispatch(getValidSession())

        if (user?.active && token) {
            const { url, service } = apiEndpoints.resolveTask({
                workerUuid: user.uuid,
                taskId,
                resolveAction
            })

            const body: {
                values?: ReferenceTaskPayload
                feedback?: WorkerFeedback
            } = {
                values: output,
                feedback
            }
            try {
                if (resolveAction === "RESOLVE") {
                    console.log("Submitting this output", output)
                }
                if (resolveAction !== "REJECT" && isBigPayloadTask(body)) {
                    await resolveBigPayloadTask({
                        workerUuid: user.uuid,
                        taskId,
                        body,
                        resolveAction,
                        token
                    })

                    if (resolveAction === "RESOLVE") {
                        // saves the task id so that it is not fetched again
                        save(LocalStorageKey.RESOLVING_TASK_ID, String(taskId))
                    }
                } else {
                    await axios({
                        service
                    }).patch(url, body, {
                        headers: {
                            Authorization: token
                        }
                    })
                }
                dispatch(resetTaskIds())
            } catch (error) {
                dispatch(
                    reportError({
                        error: Error(
                            `Failed to resolve task: ${
                                error instanceof Error
                                    ? error.message
                                    : "(unknown)"
                            }`
                        ),
                        level: Severity.Fatal,
                        extraData: {
                            taskId,
                            resolveAction,
                            submittedOutput: output,
                            ...("config" in (error as any)
                                ? { config: (error as any).config }
                                : {})
                        }
                    })
                )

                let message: string | undefined

                try {
                    message = (error as any).response?.data?.message
                } catch (ignore) {}

                throw message === undefined ? error : new Error(message)
            }
        }
    }

export const resetTaskIds = () => (dispatch: MainThunkDispatch) => {
    dispatch({ type: TaskType.setTaskIds })
}

export const getFeedbackOptions =
    ({ workerUuid, taskId }: { workerUuid: string; taskId?: number }) =>
    async (dispatch: MainThunkDispatch, getState: () => State) => {
        const token = await dispatch(getValidSession())

        if (workerUuid && taskId) {
            const { url, service } = apiEndpoints.getFeedbackOptionsByTaskId({
                workerUuid,
                taskId
            })

            try {
                const { data } = await axiosWithRetry({
                    service,
                    retries: 3,
                    retryDelay: () => 300
                }).get(url, {
                    headers: {
                        Authorization: token
                    }
                })

                return data
            } catch (error) {
                dispatch(
                    reportError({
                        error,
                        level: Severity.Warning,
                        extraData: {}
                    })
                )
            }
        }
    }

export const getTrainingExample =
    ({
        worker,
        trainingId,
        sequence,
        exampleId,
        cancelToken
    }: {
        worker: Worker
        exampleId: string
        trainingId: string
        sequence: number
        cancelToken: CancelToken
    }) =>
    async (dispatch: MainThunkDispatch) => {
        const token = await dispatch(getValidSession())

        if (worker.active && token) {
            const { url, service } = apiEndpoints.getTrainingExample({
                workerUuid: worker.uuid,
                trainingId,
                sequence,
                exampleId
            })

            const { data } = await axios({
                service
            }).get<ExampleRenderResponse>(url, {
                headers: {
                    Authorization: token,
                    "Cache-Control": "no-cache, no-store"
                },
                cancelToken
            })

            return data
        }
    }

const resolveBigPayloadTask = async ({
    workerUuid,
    taskId,
    body,
    resolveAction,
    token
}: {
    workerUuid: string
    taskId: number
    body: {
        values?: ReferenceTaskPayload
        feedback?: WorkerFeedback
    }
    resolveAction: TaskResolveOptions
    token: string
}) => {
    const { url, service } = apiEndpoints.taskBigPayload({
        workerUuid,
        taskId,
        resolveAction,
        seconds: 600
    })

    const { data } = await axios({
        service
    }).post<{ url: string }>(url, undefined, {
        headers: {
            Authorization: token
        }
    })
    await new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()

        xhr.addEventListener("load", resolve)
        xhr.upload.addEventListener("abort", reject)
        xhr.upload.addEventListener("error", reject)

        xhr.open("PUT", data.url)
        xhr.send(
            new Blob([JSON.stringify(body)], {
                type: "application/json"
            })
        )
    })
}

export const setTaskAction = (task: TaskResponse): TaskActions => ({
    type: TaskType.setTask,
    payload: task
})
