import React from "react"
import { Input, InputProps } from "../components/atoms/Input"
import { LoginButton, LoginButtonProps } from "../components/atoms/LoginButton"
import { MissionsTile } from "../components/MissionsTile/MissionsTile"
import { Training } from "../models/Training"

export const renderMultipleInputsAndButton = ({
    inputs,
    button
}: {
    inputs: InputProps[]
    button: LoginButtonProps
}) => {
    return (
        <>
            {inputs.map(
                (
                    {
                        label,
                        name,
                        onChange,
                        placeholder,
                        value,
                        required,
                        type
                    },
                    index
                ) => (
                    <Input
                        key={index}
                        label={label}
                        placeholder={placeholder}
                        name={name}
                        required={required}
                        onChange={onChange}
                        value={value}
                        type={type}
                    />
                )
            )}
            <LoginButton {...button} />
        </>
    )
}

export const renderTrainingSection = ({
    trainings,
    altSubheader,
    header,
    subheader
}: {
    trainings: Training[]
    header: string
    subheader: string
    altSubheader: string
}) => {
    return (
        <>
            <h4 className="text-xl">{header}</h4>
            <p className="mb-8">
                {trainings.length ? subheader : altSubheader}
            </p>
            <div className="flex justify-start flex-wrap md:-mx-2">
                {trainings.map(
                    (
                        { title, status, description, id, conversation, type },
                        index
                    ) => (
                        <div
                            key={index}
                            className="w-full md:w-6/12 lg:w-4/12 xl:w-3/12 mb-8 md:px-2"
                            style={{ maxWidth: "24rem" }}
                        >
                            <MissionsTile
                                title={title}
                                badge={status}
                                description={description}
                                trainingId={id}
                                conversation={conversation}
                                trainingType={type}
                            />
                        </div>
                    )
                )}
            </div>
        </>
    )
}

export const prettifyCountdown = (countdown: number): string => {
    const minutes = Math.trunc(countdown / 60)
    const seconds = countdown % 60
    return `${minutes > 0 ? `${minutes}m` : ""} ${seconds}s`
}

export const getRank = (
    completed: number,
    {
        topHalf,
        topTen,
        topThirty
    }: {
        topHalf: number
        topThirty: number
        topTen: number
    }
) => {
    if (completed >= topTen) {
        return 10
    }
    if (completed >= topThirty) {
        return 30
    }

    if (completed >= topHalf) {
        return 50
    }
}

export const customRounding = (value: number): number => {
    const roundingValue = value >= 1000 ? 1000 : 100
    const rest = value % roundingValue
    return value - rest
}
