export const getTimeFromSeconds = (seconds: number) => {
    const hours = Math.floor(seconds / (60 * 60))

    const minutesDivisor = seconds % (60 * 60)
    const minutes = Math.floor(minutesDivisor / 60)

    const secondsDivisor = minutesDivisor % 60
    const secs = Math.ceil(secondsDivisor)

    return `${hours > 0 ? hours + "h " : ""}${
        minutes > 0 ? minutes + "m " : ""
    }${secs}s`
}
