"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertToSchemaItemsResponse = exports.extractDataFromSchemaItem = exports.convertSchemaItemToSchemaForm = exports.isSchemaForm = exports.resolveSchemaRef = void 0;
require("json-schema");
const __1 = require("..");
require("../0.0.2");
const _0_0_4_1 = require("../0.0.4");
const Structured_1 = require("./Structured");
function resolveSchemaRef(schema, rootSchema) {
    function resolveRef(obj, rootSchema) {
        var _a;
        if (rootSchema.definitions === undefined) {
            return obj;
        }
        if (Array.isArray(obj)) {
            return obj.map(o => resolveRef(o, rootSchema));
        }
        else if (typeof obj === "object" && obj !== undefined) {
            let dereferencedObject = {};
            for (const [key, value] of Object.entries(obj)) {
                if (key === "$id") {
                    continue;
                }
                if (key === "$ref" &&
                    typeof value === "string" &&
                    !value.startsWith("#/definitions")) {
                    dereferencedObject = Object.assign(Object.assign({}, dereferencedObject), resolveRef((_a = Object.values(rootSchema.definitions).find(definition => typeof definition !== "boolean" &&
                        definition.$id === value)) !== null && _a !== void 0 ? _a : {}, rootSchema));
                }
                else {
                    dereferencedObject = Object.assign(Object.assign({}, dereferencedObject), { [key]: resolveRef(value, rootSchema) });
                }
            }
            return dereferencedObject;
        }
        return obj;
    }
    return resolveRef(schema, rootSchema);
}
exports.resolveSchemaRef = resolveSchemaRef;
function isSchemaForm(items) {
    return (typeof items === "object" &&
        "schema" in items &&
        "uiSchema" in items);
}
exports.isSchemaForm = isSchemaForm;
const getKey = (index) => `sf_${String(index)}`;
function convertSchemaItemToSchemaForm(items, options) {
    return items.reduce((schemaForm, item, i) => {
        var _a, _b;
        const { schema, uiSchema } = schemaForm;
        if (item.type === _0_0_4_1.UniversalSchemaType.STRUCTURED) {
            const structuredSchema = convertToStructuredSchema(item.schema_instance);
            return {
                schema: Object.assign(Object.assign({}, schema), { properties: Object.assign(Object.assign({}, schema.properties), structuredSchema), required: [
                        ...((_a = schema.required) !== null && _a !== void 0 ? _a : []),
                        item.schema_instance.tag
                    ] }),
                uiSchema: Object.assign(Object.assign({}, uiSchema), { [item.schema_instance.tag]: item.schema_instance.type ===
                        _0_0_4_1.UniversalSchemaType.BINARY_CHOICE
                        ? {
                            "ui:widget": "radio",
                            "ui:description": item.schema_instance.description
                        }
                        : {
                            "ui:description": item.schema_instance.description
                        } })
            };
        }
        else {
            const child = (0, __1.modernizeSchema)({ $ref: item.type }, undefined, options);
            return {
                schema: Object.assign(Object.assign({}, schema), { properties: Object.assign(Object.assign({}, schema.properties), { [getKey(i)]: Object.assign(Object.assign({}, child.schema), { title: "" }) }), required: [...((_b = schema.required) !== null && _b !== void 0 ? _b : []), getKey(i)] }),
                uiSchema: Object.assign(Object.assign({}, uiSchema), { [getKey(i)]: child.uiSchema })
            };
        }
    }, {
        schema: { type: "object", properties: {}, required: [] },
        uiSchema: {}
    });
}
exports.convertSchemaItemToSchemaForm = convertSchemaItemToSchemaForm;
function extractDataFromSchemaItem(items) {
    return items === null || items === void 0 ? void 0 : items.reduce((formData, item, i) => {
        if (item.type === _0_0_4_1.UniversalSchemaType.STRUCTURED)
            return Object.assign(Object.assign({}, formData), { [item.schema_instance.tag]: (0, Structured_1.toPrimitiveValue)(item.schema_instance) });
        return Object.assign(Object.assign({}, formData), { [getKey(i)]: item.schema_instance });
    }, {});
}
exports.extractDataFromSchemaItem = extractDataFromSchemaItem;
function convertToSchemaItemsResponse(formData, // the value is mapped as index of the outputItems' schema item
outputItems) {
    if (typeof formData !== "object") {
        throw new Error("Invalid formData");
    }
    const response = [];
    outputItems.forEach((item, index) => {
        response.push(convertToSchemaItem(formData, getKey(index), item));
    });
    return response;
}
exports.convertToSchemaItemsResponse = convertToSchemaItemsResponse;
function convertToSchemaItem(data, key, item) {
    if (data === undefined) {
        throw new Error("Invalid data");
    }
    if (item.type === _0_0_4_1.UniversalSchemaType.STRUCTURED) {
        return Object.assign(Object.assign({}, item), { schema_instance: (0, Structured_1.fromPrimitiveValue)(data[item.schema_instance.tag], item.schema_instance) });
    }
    return Object.assign(Object.assign({}, item), { schema_instance: data[key] });
}
function convertToStructuredSchema(schemaInstance) {
    switch (schemaInstance.type) {
        case _0_0_4_1.UniversalSchemaType.NUMBER:
        case _0_0_4_1.UniversalSchemaType.INTEGER:
            return {
                [schemaInstance.tag]: {
                    type: schemaInstance.type,
                    title: schemaInstance.tag,
                    description: schemaInstance.description
                }
            };
        case _0_0_4_1.UniversalSchemaType.TEXT:
            return {
                [schemaInstance.tag]: {
                    type: "string",
                    title: schemaInstance.tag,
                    description: schemaInstance.description
                }
            };
        case _0_0_4_1.UniversalSchemaType.BINARY_CHOICE:
            return {
                [schemaInstance.tag]: {
                    type: "boolean",
                    title: schemaInstance.tag,
                    description: schemaInstance.description
                }
            };
        case _0_0_4_1.UniversalSchemaType.EXCLUSIVE_CHOICE:
            return {
                [schemaInstance.tag]: {
                    type: "string",
                    enum: schemaInstance.value.choices.map(({ value }) => value),
                    title: schemaInstance.tag,
                    description: schemaInstance.description
                }
            };
        case _0_0_4_1.UniversalSchemaType.MULTIPLE_CHOICE:
            return {
                [schemaInstance.tag]: {
                    type: "array",
                    items: {
                        type: "string",
                        enum: schemaInstance.value.choices.map(({ value }) => value)
                    },
                    uniqueItems: true,
                    minItems: 1,
                    title: schemaInstance.tag,
                    description: schemaInstance.description
                }
            };
        default:
            return { [schemaInstance.tag]: { type: "null" } };
    }
}
