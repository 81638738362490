import {
    AppBar,
    Drawer,
    IconButton,
    makeStyles,
    Toolbar
} from "@material-ui/core"
import Menu from "@material-ui/icons/Menu"
import React, { useEffect, useRef, useState } from "react"
import { useAppState } from "../../hooks/useReduxState"
import { NavHeader, NavSubheader } from "./NavComponents"
import { NavList } from "./NavList"
import { useBoolean } from "../../hooks/useBoolean"

interface NavProps {
    login?: boolean
    title: string
    subtitle?: string
    backLink?: string
}

const useClasses = makeStyles<{}, { offsetTop: number }, "paper">(() => ({
    paper: ({ offsetTop }) => ({
        top: `${offsetTop}px`
    })
}))

export const Nav = React.forwardRef<HTMLDivElement, NavProps>(
    ({ login, subtitle, title, backLink }, ref) => {
        const worker = useAppState(state => state.auth.worker)
        const headerRef = useRef<HTMLElement>()
        const [offsetTop, setOffsetTop] = useState(0)
        const [isMenuOpen, { toggle }] = useBoolean(false)
        const handleMenuClick = () => toggle()
        const classes = useClasses({ offsetTop })

        useEffect(() => {
            setOffsetTop(headerRef?.current?.clientHeight ?? 0)
        }, [])

        return (
            <AppBar
                ref={headerRef}
                position="sticky"
                className="text-white-opaque shadow-none z-500 bg-neon-gradient-900"
            >
                <Toolbar
                    className={`flex ${
                        login ? "flex-col justify-center" : "justify-between"
                    }`}
                >
                    <div
                        className={`${
                            login
                                ? ""
                                : "flex-grow flex-shrink w-1/2 flex justify-start items-center"
                        }`}
                    >
                        {!login && (
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                onClick={handleMenuClick}
                            >
                                <Menu />
                            </IconButton>
                        )}
                        <NavHeader login={login} backlink={backLink}>
                            {title}
                        </NavHeader>
                    </div>
                    {subtitle !== undefined && (
                        <div
                            className={`whitespace-no-wrap ${
                                login
                                    ? "pb-2"
                                    : "w-0 flex items-center justify-center"
                            }`}
                        >
                            <NavSubheader>{subtitle}</NavSubheader>
                        </div>
                    )}
                    <div
                        ref={ref}
                        className="flex-grow flex-shrink w-1/2 flex items-center justify-end"
                    />
                    <Drawer
                        open={isMenuOpen}
                        classes={{ paper: classes.paper }}
                        onClose={handleMenuClick}
                        className="z-400"
                    >
                        <NavList
                            offsetTop={offsetTop}
                            worker={worker}
                            onMenuClick={handleMenuClick}
                        />
                    </Drawer>
                </Toolbar>
            </AppBar>
        )
    }
)
