"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.embedReference = exports.dereferenceIfRequired = exports.dereference = exports.hasReferenceData = exports.LOC_REFERENCE_KEY = void 0;
require("json-schema");
require("./UniversalSchema");
const JSONSchema7_1 = require("./JSONSchema7");
exports.LOC_REFERENCE_KEY = "$loc";
/**
 * Test if the argument is a reference object
 */
function isReferenceObject(obj) {
    return (typeof obj === "object" &&
        obj !== null &&
        typeof obj[exports.LOC_REFERENCE_KEY] === "string");
}
function hasReferenceData(data) {
    // recursively traversing large object to find $loc is a costly operation,
    // so I first check if its serialized string contains '$loc'.
    return (data !== undefined && JSON.stringify(data).includes(exports.LOC_REFERENCE_KEY));
}
exports.hasReferenceData = hasReferenceData;
/**
 * Recursively traverse given data, find and replace all references with actual data
 */
function dereference(data, fetchData) {
    return __awaiter(this, void 0, void 0, function* () {
        function iterate(data, fetchData) {
            return __awaiter(this, void 0, void 0, function* () {
                if (typeof data === "object" && data !== null) {
                    if (isReferenceObject(data)) {
                        return fetchData(data[exports.LOC_REFERENCE_KEY]);
                    }
                    if (Array.isArray(data)) {
                        return Promise.all(data.map(item => iterate(item, fetchData)));
                    }
                    return Promise.all(Object.entries(data).map(([key, childData]) => iterate(childData, fetchData).then(resolvedChild => [
                        key,
                        resolvedChild
                    ]))).then(entries => Object.fromEntries(entries));
                }
                return data;
            });
        }
        return iterate(data, fetchData);
    });
}
exports.dereference = dereference;
function dereferenceIfRequired(data, fetchData) {
    return __awaiter(this, void 0, void 0, function* () {
        if (hasReferenceData(data)) {
            return dereference(data, fetchData);
        }
        return data;
    });
}
exports.dereferenceIfRequired = dereferenceIfRequired;
/**
 * Embed reference to a schema item
 */
function embedReference(data, schema, blobUploader) {
    return __awaiter(this, void 0, void 0, function* () {
        if (schema.type === "array" && Array.isArray(data)) {
            return Promise.all(data.map(d => (0, JSONSchema7_1.isJSONSchema7)(schema.items)
                ? embedReference(d, schema.items, blobUploader)
                : d));
        }
        if (schema.type === "object" && typeof data === "object" && data !== null) {
            return Promise.all(Object.entries(data).map(([key, value]) => {
                var _a;
                const childSchema = (_a = schema.properties) === null || _a === void 0 ? void 0 : _a[key];
                return (childSchema === undefined ||
                    typeof childSchema === "boolean"
                    ? Promise.resolve(value)
                    : embedReference(value, childSchema, blobUploader)).then(d => [key, d]);
            })).then(entries => Object.fromEntries(entries));
        }
        return data;
    });
}
exports.embedReference = embedReference;
