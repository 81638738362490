/**
 * Central routing system
 * All possible routes are managed here, routes are provided
 * with appropriate redirects or an associated view component.
 *
 * Routes that require user data (from authentication) should
 * be wrapped in Auth, and routes that should _only_ appear when
 * a user is not authenticated should be wrapped in skip auth
 * (such they redirect when the user is authenticated). All other
 * routes are accessed regardless.
 *
 * Currently, viewing some routes triggers calls to the APIs to
 * fetch the appropriate data for the view (such as the list of
 * API instances for a user).
 */

import { CircularProgress } from "dash-styled"
import { Severity } from "@sentry/react"
import React, {
    useEffect,
    useRef,
    useMemo,
    RefObject,
    ReactNode,
    useState,
    useCallback,
    FC
} from "react"
import { useDispatch } from "react-redux"
import {
    Redirect,
    Route,
    RouteComponentProps,
    Switch,
    matchPath
} from "react-router"
import { initAuth, signOut, updateLoginTime } from "../actions/AuthActions"
import { reportError } from "../actions/tracking/MonitoringActions"
import { AuthCheck } from "../components/AuthCheck/AuthCheck"
import { useInterval } from "../hooks/useInterval"
import { useAppState } from "../hooks/useReduxState"
import { ToastStatus, useToast } from "../hooks/useToast"
import { BonusDetails } from "../pages/bonus/BonusDetails"
import { Earnings } from "../pages/earnings/Earnings"
import { Home } from "../pages/Home"
import Eula from "../pages/legal/Eula"
import Privacy from "../pages/legal/Privacy"
import { ForgotPassword } from "../pages/login/ForgotPassword"
import { Login } from "../pages/login/Login"
import { Register } from "../pages/login/Register"
import { ResendCode } from "../pages/login/ResendCode"
import { Missions } from "../pages/missions/Missions"
import { Profile } from "../pages/profile/Profile"
import { Tasks, TasksLocationState } from "../pages/tasks/Tasks"
import { MainThunkDispatch } from "../reducers"
import { errorMessages, atoms } from "../texts"
import { Layout, LayoutProps } from "../pages/Layout"
import {
    setLastSuccessfulKeepAlive,
    toggleInactiveWarningBanner
} from "../actions/BannerActions"
import { useIdle } from "../hooks/useIdle"
import { TrainingTask, TrainingTaskProps } from "../pages/training/TrainingTask"
import {
    TrainingExample,
    TrainingExampleProps
} from "../pages/training/TrainingExample"
import {
    TrainingTasks,
    TrainingTasksProps
} from "../pages/training/TrainingTasks"

interface RoutePaths {
    bonus: () => string
    earnings: () => string
    eula: () => string
    forgotPassword: () => string
    home: () => string
    login: () => string
    missions: () => string
    privacy: () => string
    profile: () => string
    register: () => string
    resendCode: () => string
    task: (taskId?: number) => string
    tasks: () => string
    trainingTasks: (trainingId?: string) => string
    trainingTask: (trainingId?: string, taskId?: string) => string
    trainingExample: (trainingId?: string, exampleId?: string) => string
}

interface RouteInfo {
    component: (props: RouteComponentProps<any, any, any>) => ReactNode
    exact?: boolean
    layout?: Partial<LayoutProps>
}

export const routePaths: RoutePaths = Object.freeze<RoutePaths>({
    bonus: () => "/bonus",
    earnings: () => "/earnings",
    eula: () => "/eula",
    forgotPassword: () => "/forgot-password",
    home: () => "/",
    login: () => "/login",
    missions: () => "/missions",
    privacy: () => "/privacy",
    profile: () => "/profile",
    register: () => "/register",
    resendCode: () => "/resend-code",
    task: taskId => `/tasks/${taskId || ":taskid"}`,
    tasks: () => "/tasks",
    trainingTask: (trainingId, taskId) =>
        `/training/${trainingId ?? ":trainingId"}/tasks/${
            taskId ?? ":trainingTaskId"
        }`,
    trainingTasks: trainingId =>
        `/training/${trainingId ?? ":trainingId"}/tasks`,
    trainingExample: (trainingId, exampleId) =>
        `/training/${trainingId ?? ":trainingId"}/example/${
            exampleId ?? ":trainingExampleId"
        }`
})

const createRouteMap = (
    toolbarRef: RefObject<HTMLDivElement>
): [string, RouteInfo][] => {
    /**
     * NOTE:
     * It is important that task and tasks route are rendered as the same component.
     * Otherwise task object in local state get lost on re-routing.
     */
    const TasksOrTask: FC<RouteComponentProps<{}, {}, TasksLocationState>> = ({
        location
    }: RouteComponentProps<{}, {}, TasksLocationState>) => (
        <AuthCheck>
            <Tasks
                conversation={location.state?.conversation}
                toolbarRef={toolbarRef}
            />
        </AuthCheck>
    )

    const loginRoutes: [string, RouteInfo][] = [
        [
            routePaths.login(),
            {
                layout: {
                    subheader: atoms.subheader.loginSubheader,
                    login: true
                },
                component: () => (
                    <AuthCheck isLoginComponent={true}>
                        <Login />
                    </AuthCheck>
                )
            }
        ],
        [
            routePaths.register(),
            {
                layout: {
                    subheader: atoms.subheader.registerSubheader,
                    login: true
                },
                component: () => (
                    <AuthCheck isLoginComponent={true}>
                        <Register />
                    </AuthCheck>
                )
            }
        ],
        [
            routePaths.forgotPassword(),
            {
                layout: {
                    subheader: atoms.subheader.forgotPasswordSubheader,
                    login: true
                },
                component: () => (
                    <AuthCheck isLoginComponent={true}>
                        <ForgotPassword />
                    </AuthCheck>
                )
            }
        ],
        [
            routePaths.resendCode(),
            {
                layout: { login: true },
                component: () => (
                    <AuthCheck isLoginComponent={true}>
                        <ResendCode />
                    </AuthCheck>
                )
            }
        ],
        [
            routePaths.eula(),
            {
                layout: {
                    subheader: atoms.subheader.eulaSubheader,
                    login: true
                },
                component: () => <Eula />
            }
        ],
        [
            routePaths.privacy(),
            {
                layout: {
                    subheader: atoms.subheader.privacySubheader,
                    login: true
                },
                component: () => <Privacy />
            }
        ]
    ]

    const websiteRoutes: [string, RouteInfo][] = [
        [
            routePaths.bonus(),
            {
                layout: { subheader: atoms.subheader.bonus },
                component: () => (
                    <AuthCheck>
                        <BonusDetails />
                    </AuthCheck>
                )
            }
        ],
        [
            routePaths.home(),
            {
                component: () => <Home />,
                exact: true
            }
        ],
        [
            routePaths.missions(),
            {
                layout: { subheader: atoms.subheader.mission },
                component: () => (
                    <AuthCheck>
                        <Missions />
                    </AuthCheck>
                ),
                exact: true
            }
        ],
        [
            routePaths.tasks(),
            {
                layout: {
                    subheader: atoms.subheader.tasks,
                    noPadding: true,
                    backLink: routePaths.missions()
                },
                component: TasksOrTask,
                exact: true
            }
        ],
        [
            routePaths.task(),
            {
                layout: {
                    subheader: atoms.subheader.tasks,
                    noPadding: true,
                    backLink: routePaths.missions()
                },
                component: TasksOrTask,
                exact: true
            }
        ],
        [
            routePaths.trainingTasks(),
            {
                layout: {
                    subheader: atoms.subheader.trainingTasks,
                    backLink: routePaths.missions()
                },
                component: ({
                    location,
                    match: {
                        params: { trainingId }
                    }
                }: RouteComponentProps<
                    { trainingId: string },
                    {},
                    TrainingTasksProps
                >) => (
                    <AuthCheck>
                        <TrainingTasks
                            trainingId={trainingId}
                            sequence={location.state?.sequence}
                        />
                    </AuthCheck>
                ),
                exact: true
            }
        ],
        [
            routePaths.trainingTask(),
            {
                layout: {
                    subheader: atoms.subheader.trainingTasks,
                    noPadding: true,
                    backLink: routePaths.missions()
                },
                component: ({
                    location,
                    match: {
                        params: { trainingId }
                    }
                }: RouteComponentProps<
                    { trainingId: string; trainingTaskId: string },
                    {},
                    TrainingTaskProps
                >) => {
                    const { state } = location
                    if (state && state.task && state.worker) {
                        const { task, worker } = state
                        return (
                            <AuthCheck>
                                <TrainingTask
                                    toolbarRef={toolbarRef}
                                    task={task}
                                    worker={worker}
                                    trainingId={trainingId}
                                />
                            </AuthCheck>
                        )
                    }
                    return (
                        <Redirect to={routePaths.trainingTasks(trainingId)} />
                    )
                },
                exact: true
            }
        ],
        [
            routePaths.trainingExample(),
            {
                layout: {
                    subheader: atoms.subheader.trainingTasks,
                    noPadding: true,
                    backLink: routePaths.missions()
                },
                component: ({
                    location,
                    match: {
                        params: { trainingId }
                    }
                }: RouteComponentProps<
                    { trainingId: string; exampleId: string },
                    {},
                    TrainingExampleProps
                >) => {
                    const { state } = location
                    if (state && state.example && state.worker) {
                        const { example, worker } = state
                        return (
                            <AuthCheck>
                                <TrainingExample
                                    toolbarRef={toolbarRef}
                                    example={example}
                                    worker={worker}
                                    trainingId={trainingId}
                                />
                            </AuthCheck>
                        )
                    }
                    return (
                        <Redirect to={routePaths.trainingTasks(trainingId)} />
                    )
                },
                exact: true
            }
        ],
        [
            routePaths.profile(),
            {
                layout: { subheader: atoms.subheader.profile },
                component: () => (
                    <AuthCheck>
                        <Profile />
                    </AuthCheck>
                )
            }
        ],
        [
            routePaths.earnings(),
            {
                layout: { subheader: atoms.subheader.earnings },
                component: () => (
                    <AuthCheck>
                        <Earnings />
                    </AuthCheck>
                )
            }
        ]
    ]

    return [...loginRoutes, ...websiteRoutes]
}

const KEEP_ALIVE_INTERVAL = 20 // 20 seconds
const KEEP_ALIVE_FAILURE_THRESHOLD = 2 // 2 tries

export const Routes = () => {
    const dispatch = useDispatch<MainThunkDispatch>()
    const isSignedIn = useAppState(state => state.auth.isSignedIn)
    const isSigningIn = useAppState(state => state.auth.isSigningIn)
    const worker = useAppState(state => state.auth.worker)
    const counterForUpdateLoginErrors = useRef(0)
    const errorToast = useToast(ToastStatus.ERROR)
    const isWorking = !isSignedIn && isSigningIn
    const toolbarRef = useRef<HTMLDivElement>(null)
    const routesMap = useMemo(() => createRouteMap(toolbarRef), [])
    const currentPath = useAppState(state => state.router.location.pathname)
    const layoutProps = useMemo(() => {
        const matched = routesMap.find(
            ([path, { exact }]) =>
                matchPath(currentPath, { path, exact }) !== null
        )

        return matched === undefined ? {} : matched[1].layout
    }, [currentPath, routesMap])
    const routes = useMemo(
        () =>
            routesMap.map(([path, { component, exact }]) => (
                <Route
                    key={path}
                    path={path}
                    render={component}
                    exact={exact}
                />
            )),
        [routesMap]
    )
    const [keepAliveLoopTimer, setKeepAliveLoopTimer] = useState(1)
    const [isIdle] = useIdle(KEEP_ALIVE_INTERVAL)
    const [inactivityTimer, setInactivityTimer] = useState(0)

    useInterval(() => {
        if (keepAliveLoopTimer < KEEP_ALIVE_INTERVAL) {
            setKeepAliveLoopTimer(keepAliveLoopTimer + 1)
        } else {
            setKeepAliveLoopTimer(1)
        }

        if (
            counterForUpdateLoginErrors.current >= KEEP_ALIVE_FAILURE_THRESHOLD
        ) {
            setInactivityTimer(inactivityTimer + 1)
        } else {
            setInactivityTimer(0)
        }
    }, 1000)

    useEffect(() => {
        const initAmplify = async () => {
            await dispatch(initAuth())
        }

        initAmplify()
    }, [dispatch])

    const sendUpdateLoginTime = useCallback(async () => {
        if (worker) {
            try {
                await dispatch(updateLoginTime({ workerUuid: worker.uuid }))
                counterForUpdateLoginErrors.current = 0
                dispatch(toggleInactiveWarningBanner(false))
                dispatch(
                    setLastSuccessfulKeepAlive(Math.ceil(Date.now() / 1000))
                )
            } catch (error) {
                counterForUpdateLoginErrors.current += 1
                if (
                    counterForUpdateLoginErrors.current >=
                    KEEP_ALIVE_FAILURE_THRESHOLD
                ) {
                    dispatch(toggleInactiveWarningBanner(true))
                }
                if (counterForUpdateLoginErrors.current >= 50) {
                    try {
                        await dispatch(signOut())
                        counterForUpdateLoginErrors.current = 0
                    } catch (error) {
                        dispatch(
                            reportError({
                                error,
                                level: Severity.Warning
                            })
                        )
                        errorToast(errorMessages.logout)
                    }
                }
            }
        }
    }, [dispatch, errorToast, worker])

    useEffect(() => {
        if (
            keepAliveLoopTimer === KEEP_ALIVE_INTERVAL &&
            (!isIdle || counterForUpdateLoginErrors.current > 0)
        ) {
            setKeepAliveLoopTimer(1)
            sendUpdateLoginTime()
        }
    }, [isIdle, keepAliveLoopTimer, sendUpdateLoginTime])

    useEffect(() => {
        //Initial keep-alive on entering labeling UI
        if (currentPath.startsWith("/tasks/")) {
            sendUpdateLoginTime()
            setKeepAliveLoopTimer(1)
        }
        counterForUpdateLoginErrors.current = 0
    }, [currentPath, sendUpdateLoginTime])

    if (isWorking)
        return (
            <div className="flex justify-center items-center py-32">
                <CircularProgress
                    className="w-24 h-24"
                    style={{ color: "#ffc107" }}
                />
            </div>
        )
    return (
        <Layout ref={toolbarRef} {...layoutProps}>
            <Switch>
                {routes}
                <Redirect to={routePaths.home()} />
            </Switch>
        </Layout>
    )
}
