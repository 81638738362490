import { push } from "connected-react-router"
import { client, getCompletedQualifications, Qualification } from "neql"
import React, { FC, ReactNode, useEffect, useRef, useState } from "react"
import { getValidSession } from "../actions/AuthActions"
import { reportError } from "../actions/tracking/MonitoringActions"
import { useAppDispatch } from "../hooks/useReduxState"
import { routePaths } from "../routes"
import { componentsText } from "../texts"
import { Button } from "./atoms/Button"

interface QualificationProps {
    uuid?: string
}

export const Qualifications: FC<QualificationProps> = ({ uuid }) => {
    const [qualifications, setQualifications] = useState<Qualification[]>([])
    const [possibleQualifications, setPossibleQualifications] = useState(0)
    const [visible, setVisible] = useState(false)
    const dispatch = useAppDispatch()
    const mounted = useRef(false)

    const Header = ({ children }: { children: ReactNode }) => (
        <div className="flex w-full justify-center mb-1 text-uppercase text-sm underline">
            {children}
        </div>
    )
    const Row = ({ name, score }: { name: string; score: number }) => (
        <div className="flex w-full justify-center text-sm mb-1">
            <div className="w-1/3 text-center">{name}</div>
            <div className="w-1/3 text-center">{score.toFixed(2)}</div>
        </div>
    )

    useEffect(() => {
        mounted.current = true
        const getQualifications = async () => {
            if (uuid) {
                try {
                    const token = await dispatch(getValidSession())
                    const {
                        allPossibleQualifications,
                        qualifications: fetchedQualifications
                    } = await getCompletedQualifications(client(token), uuid)
                    if (mounted) {
                        setQualifications(fetchedQualifications)
                        setPossibleQualifications(allPossibleQualifications)
                    }
                } catch (error) {
                    if (mounted) {
                        setQualifications([])
                        setPossibleQualifications(-1)
                    }
                    dispatch(reportError({ error }))
                }
            }
        }
        getQualifications()
        const showQualifications = setTimeout(() => setVisible(true), 1000 * 10)
        return () => {
            clearTimeout(showQualifications)
            mounted.current = false
        }
    }, [dispatch, uuid])

    const handleCta = () => {
        dispatch(push(routePaths.missions()))
    }
    const { cta, header, intro } = componentsText.qualifications

    return (
        <div
            className={`flex flex-col items-center justify-center mt-48 opacity-${
                visible ? 100 : 0
            } transition-400`}
        >
            <div className="flex flex-col items-center mb-8 text-center">
                <p className="mb-4 text-xl font-semibold">{intro.question}</p>
                <p className="">{intro.explanation}</p>
                <Button
                    className="my-8 bg-neon-gradient-400 hover:bg-primary-darker text-white-opaque"
                    onClick={handleCta}
                    capitalize={true}
                >
                    {cta}
                </Button>
                {possibleQualifications > 0 && (
                    <p className="mb-4">
                        {intro.doneTrainings(
                            qualifications?.length,
                            possibleQualifications
                        )}
                    </p>
                )}
            </div>
            {qualifications.length > 0 && (
                <Header>
                    <div className="w-1/3 text-center">
                        {header.qualifications}
                    </div>
                    <div className="w-1/3 text-center">{header.score}</div>
                </Header>
            )}
            {qualifications?.map(({ name, score }, index) => (
                <Row key={index} name={name} score={score} />
            ))}
        </div>
    )
}
