"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValueValid = exports.fromPrimitiveValue = exports.toPrimitiveValue = exports.toReadableText = void 0;
require("../0.0.2");
const _0_0_4_1 = require("../0.0.4");
function toReadableText(structured) {
    try {
        const primitive = toPrimitiveValue(structured);
        return primitive === true
            ? "Yes"
            : primitive === false
                ? "No"
                : primitive === undefined
                    ? "Not selected"
                    : Array.isArray(primitive)
                        ? primitive.length === 0
                            ? "Not selected"
                            : primitive.join(", ")
                        : String(primitive);
    }
    catch (ignore) {
        return "Not selected";
    }
}
exports.toReadableText = toReadableText;
function toPrimitiveValue(structured) {
    var _a, _b, _c, _d, _e;
    switch (structured.type) {
        case _0_0_4_1.UniversalSchemaType.BINARY_CHOICE:
            return typeof structured.value === "boolean"
                ? structured.value
                : undefined;
        case _0_0_4_1.UniversalSchemaType.TEXT:
            return typeof structured.value === "string"
                ? structured.value
                : undefined;
        case _0_0_4_1.UniversalSchemaType.NUMBER:
        case _0_0_4_1.UniversalSchemaType.INTEGER:
            return typeof structured.value === "number"
                ? structured.value
                : undefined;
        case _0_0_4_1.UniversalSchemaType.EXCLUSIVE_CHOICE:
            return ((_b = (_a = structured.value.selection) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : undefined);
        case _0_0_4_1.UniversalSchemaType.MULTIPLE_CHOICE:
            return ((_e = (_d = (_c = structured.value) === null || _c === void 0 ? void 0 : _c.selections) === null || _d === void 0 ? void 0 : _d.map(sel => sel.value)) !== null && _e !== void 0 ? _e : []);
    }
}
exports.toPrimitiveValue = toPrimitiveValue;
function fromPrimitiveValue(value, template) {
    switch (template.type) {
        case _0_0_4_1.UniversalSchemaType.BINARY_CHOICE:
            return typeof value === "boolean"
                ? Object.assign(Object.assign({}, template), { value }) : template;
        case _0_0_4_1.UniversalSchemaType.TEXT:
            return typeof value === "string" ? Object.assign(Object.assign({}, template), { value }) : template;
        case _0_0_4_1.UniversalSchemaType.NUMBER:
        case _0_0_4_1.UniversalSchemaType.INTEGER:
            return typeof value === "number" ? Object.assign(Object.assign({}, template), { value }) : template;
        case _0_0_4_1.UniversalSchemaType.EXCLUSIVE_CHOICE: {
            const typedValue = template.value;
            return Object.assign(Object.assign({}, template), { value: Object.assign(Object.assign({}, typedValue), { selection: typedValue.choices.find(choice => choice.value === value) }) });
        }
        case _0_0_4_1.UniversalSchemaType.MULTIPLE_CHOICE: {
            const typedValue = template.value;
            if (!Array.isArray(value)) {
                return template;
            }
            return Object.assign(Object.assign({}, template), { value: Object.assign(Object.assign({}, typedValue), { selections: value.flatMap(e => {
                        const found = typedValue.choices.find(choice => choice.value === e);
                        return found === undefined ? [] : [found];
                    }) }) });
        }
        default:
            return template;
    }
}
exports.fromPrimitiveValue = fromPrimitiveValue;
function isValueValid(item) {
    const value = item.value;
    if (value === undefined) {
        return false;
    }
    switch (item.type) {
        case _0_0_4_1.UniversalSchemaType.TEXT:
            return typeof value === "string" && value.length > 0;
        case _0_0_4_1.UniversalSchemaType.INTEGER:
            return Number.isInteger(value);
        case _0_0_4_1.UniversalSchemaType.NUMBER:
            return typeof value === "number" && !Number.isNaN(value);
        case _0_0_4_1.UniversalSchemaType.BINARY_CHOICE:
            return typeof value === "boolean";
        case _0_0_4_1.UniversalSchemaType.MULTIPLE_CHOICE:
            return (typeof value !== "string" &&
                typeof value !== "number" &&
                typeof value !== "boolean" &&
                "selections" in value &&
                value.selections !== undefined &&
                value.selections.length > 0);
        case _0_0_4_1.UniversalSchemaType.EXCLUSIVE_CHOICE:
            return (typeof value !== "string" &&
                typeof value !== "number" &&
                typeof value !== "boolean" &&
                "selection" in value &&
                value.selection !== undefined);
        default:
            return false;
    }
}
exports.isValueValid = isValueValid;
