import { Reducer } from "redux"
import { APIType, BannerType } from "../../actions/Types"

export interface BannerState {
    inactiveWarning: boolean
    lastSuccessfulKeepAlive: number | undefined
}

interface InactiveWarningBanner {
    type: BannerType.toggleInactiveWarning
    payload: {
        visible: boolean
    }
}

interface LastSuccessfulKeepAlive {
    type: APIType.setLastSuccessfulKeepAlive
    payload: number | undefined
}

export type BannerAction = InactiveWarningBanner | LastSuccessfulKeepAlive

const initialState: BannerState = {
    inactiveWarning: false,
    lastSuccessfulKeepAlive: undefined
}

export const BannerReducer: Reducer<BannerState, BannerAction> = (
    state = initialState,
    action
): BannerState => {
    switch (action.type) {
        case BannerType.toggleInactiveWarning:
            return {
                ...state,
                inactiveWarning: action.payload.visible
            }
        case APIType.setLastSuccessfulKeepAlive:
            return {
                ...state,
                lastSuccessfulKeepAlive: action.payload
            }
        default:
            return state
    }
}
