"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isVirtuallyStructuredSchema = void 0;
require("json-schema");
const _0_0_1_1 = require("../0.0.1");
require("../0.0.2");
const JSONSchema7_1 = require("./JSONSchema7");
const supportedTypes = [
    _0_0_1_1.UniversalSchemaType.TEXT,
    _0_0_1_1.UniversalSchemaType.INTEGER,
    _0_0_1_1.UniversalSchemaType.NUMBER,
    _0_0_1_1.UniversalSchemaType.BINARY_CHOICE,
    _0_0_1_1.UniversalSchemaType.EXCLUSIVE_CHOICE,
    _0_0_1_1.UniversalSchemaType.MULTIPLE_CHOICE
];
/**
 * Tests if the give schema conforms with V.S type
 */
function isVirtuallyStructuredSchema(schema) {
    return (schema.type === "array" &&
        (0, JSONSchema7_1.isJSONSchema7)(schema.items) &&
        schema.items.allOf !== undefined &&
        schema.items.properties !== undefined &&
        "field_id" in schema.items.properties &&
        "field_type" in schema.items.properties &&
        schema.items.allOf.every(item => {
            var _a, _b, _c, _d, _e, _f;
            if (!(0, JSONSchema7_1.isJSONSchema7)(item)) {
                return false;
            }
            const type = (0, JSONSchema7_1.isJSONSchema7)(item.if) &&
                (0, JSONSchema7_1.isJSONSchema7)((_a = item.if.properties) === null || _a === void 0 ? void 0 : _a["field_type"]) &&
                ((_c = (_b = item.if.properties) === null || _b === void 0 ? void 0 : _b["field_type"]) === null || _c === void 0 ? void 0 : _c.const);
            return (type !== undefined &&
                supportedTypes.includes(type) &&
                (0, JSONSchema7_1.isJSONSchema7)(item.then) &&
                (0, JSONSchema7_1.isJSONSchema7)((_d = item.then.properties) === null || _d === void 0 ? void 0 : _d["field_value"]) &&
                ((_f = (_e = item.then.properties) === null || _e === void 0 ? void 0 : _e["field_value"]) === null || _f === void 0 ? void 0 : _f.$ref) === type);
        }));
}
exports.isVirtuallyStructuredSchema = isVirtuallyStructuredSchema;
