import { combineReducers } from "redux"
import { BannerAction, BannerReducer, BannerState } from "./BannerReducer"
import {
    MessagesReducer,
    MessagesState,
    MessagesAction
} from "./MessagesReducer"
import { ModalAction, ModalReducer, ModalState } from "./ModalReducer"

export const ClientReducer = combineReducers({
    messages: MessagesReducer,
    banner: BannerReducer,
    modal: ModalReducer
})

export interface ClientState {
    messages: MessagesState
    banner: BannerState
    modal: ModalState
}

export type ClientAction = MessagesAction | BannerAction | ModalAction
