import { Badge } from "@material-ui/core"
import { push } from "connected-react-router"
import React, { FC } from "react"
import { useDispatch } from "react-redux"
import {
    cancelTraining,
    startTraining
} from "../../actions/api/TrainingActions"
import { asError } from "../../helpers/error"
import { useAppState } from "../../hooks/useReduxState"
import { ToastStatus, useToast } from "../../hooks/useToast"
import { TrainingStatus, TrainingType } from "../../models/Training"
import { TasksLocationState } from "../../pages/tasks/Tasks"
import { MainThunkDispatch } from "../../reducers"
import { routePaths } from "../../routes"
import { componentsText, errorMessages, successMessages } from "../../texts"
import { Button } from "../atoms/Button"
import { CompanyLogo } from "../atoms/CompanyLogo"
import styles from "./MissionTitle.module.css"

interface MissionsTileProps {
    title: string
    subheader?: string
    badge?: TrainingStatus
    description: string
    trainingId?: number
    image?: string
    conversation?: number
    trainingType?: TrainingType
}

export const MissionsTile: FC<MissionsTileProps> = ({
    image,
    description,
    badge,
    subheader,
    title,
    trainingId,
    conversation,
    trainingType
}) => {
    const isTrainingToggling =
        useAppState(state => state.api.training.loading) === trainingId
    const dispatch = useDispatch<MainThunkDispatch>()
    const successToast = useToast(ToastStatus.SUCCESS)
    const errorToast = useToast(ToastStatus.ERROR)
    const { trainingCancel, trainingStart, resumeButton } =
        componentsText.missionsTile
    const { cancelled } = successMessages.missionsTile
    const { standard } = errorMessages
    const trainingInProgress = badge === TrainingStatus.IN_PROGRESS
    const buttonLabel = () =>
        trainingInProgress ? resumeButton : trainingStart
    const buttonDisabled = () => !!trainingId && isTrainingToggling

    const getBadgeColor = (): { badgeColor: string; tileColor: string } => {
        switch (badge) {
            case TrainingStatus.COMPLETED:
                return {
                    badgeColor: `border ${styles.badge_completed}`,
                    tileColor: ""
                }
            case TrainingStatus.LOCKED:
                return {
                    badgeColor: `border ${styles.badge_locked}`,
                    tileColor: ""
                }
            case TrainingStatus.IN_PROGRESS:
                return {
                    badgeColor: `border ${styles.badge_inProgress}`,
                    tileColor: styles.tile_inProgress
                }
            case TrainingStatus.UNLOCKED:
                return {
                    badgeColor: `border ${styles.badge_unlocked}`,
                    tileColor: ""
                }
            case TrainingStatus.FAILED:
                return {
                    badgeColor: `border ${styles.badge_unlocked} border-blood-500`,
                    tileColor: ""
                }
            default:
                return { badgeColor: "bg-gray-600 text-white", tileColor: "" }
        }
    }
    const routeToTasks = (trainingId?: string, conversation?: number) => {
        if (
            (trainingType === TrainingType.TEST ||
                trainingType === TrainingType.TUTORIAL) &&
            trainingId !== undefined
        ) {
            dispatch(push(routePaths.trainingTasks(trainingId)))
        } else {
            dispatch(
                push<TasksLocationState>(routePaths.tasks(), {
                    conversation
                })
            )
        }
    }

    const handleTrainingToggling = (activate: boolean) => async () => {
        if (trainingId) {
            try {
                if (activate) {
                    const training = await dispatch(
                        startTraining({ trainingId })
                    )
                    routeToTasks(training?.trainingId, training?.conversation)
                } else {
                    await dispatch(cancelTraining({ trainingId }))
                    successToast(cancelled)
                }
            } catch (error) {
                errorToast(asError(error).message ?? standard)
            }
        }
    }

    const handleResume = () => {
        routeToTasks(String(trainingId), conversation)
    }

    const { badgeColor, tileColor } = getBadgeColor()

    return (
        <div
            className={`grid grid-rows-tile gap-4 md:gap-8 items-start cursor-default pt-2 pb-4 border rounded-lg transition-shadow duration-500 ease-in-out hover:shadow-xl ${tileColor}`}
        >
            <div className="row-start-1 flex items-center px-4">
                <div className="w-2/12 flex">
                    <CompanyLogo
                        className={
                            image ? "h-10 md:h-12 lg:h-16 " : "h-8 lg:h-10 "
                        }
                    />
                </div>
                <div className="w-10/12">
                    <h2
                        className={`text-lg mb-1 ${
                            title.length <= 40
                                ? "truncate "
                                : "whitespace-pre-wrap"
                        }`}
                        title={title}
                    >
                        {title}
                    </h2>
                    <div className="flex items-center">
                        <h3 className="mb-0">{subheader}</h3>
                        {trainingType !== undefined && (
                            <Badge
                                className={`${
                                    subheader ? "ml-2" : ""
                                } px-2 py-0.5 ${badgeColor} leading-tight text-xs rounded-xl mr-1`}
                            >
                                {trainingType}
                            </Badge>
                        )}
                        {badge !== undefined && (
                            <Badge
                                className={`${
                                    subheader ? "ml-2" : ""
                                } px-2 py-0.5 ${badgeColor} leading-tight text-xs rounded-xl`}
                            >
                                {badge}
                            </Badge>
                        )}
                    </div>
                </div>
            </div>
            <div className="row-start-2 flex flex-col justify-start">
                {image && (
                    <img
                        className="mb-4 py-2 px-8 object-contain md:p-0 md:w-9/12 md:self-center"
                        src={image}
                        alt="missions logo"
                        loading="lazy"
                    />
                )}

                <p className=" text-black text-opacity-87 text-sm px-4">
                    {description}
                </p>
            </div>
            <div className="row-start-3 flex px-4">
                <Button
                    dataTestid={title.toLowerCase()}
                    className={`mr-2 transition-colors duration-300 ease-in-out border border-transparent ${
                        image
                            ? "border-black text-opacity-87"
                            : "hover:border-gray-700"
                    }`}
                    onClick={
                        trainingInProgress || !trainingId
                            ? handleResume
                            : handleTrainingToggling(true)
                    }
                    loading={!trainingInProgress && buttonDisabled()}
                >
                    {buttonLabel()}
                </Button>
                {badge === TrainingStatus.IN_PROGRESS && (
                    <Button
                        className={`mr-2 transition-colors duration-300 ease-in-out border border-transparent disabled:bg-gray-200 ${
                            image
                                ? "border-black border-opacity-87"
                                : "hover:border-gray-700"
                        }`}
                        disabled={buttonDisabled()}
                        onClick={handleTrainingToggling(false)}
                        loading={buttonDisabled()}
                    >
                        {trainingCancel}
                    </Button>
                )}
            </div>
        </div>
    )
}
