"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.jsonSchemaV001 = void 0;
exports.jsonSchemaV001 = {
    $id: "http://schema.canotic.com/v0.0.1/",
    $schema: "http://json-schema.org/draft-07/schema#",
    definitions: {
        IMAGE: {
            $id: "image",
            description: "The URL of an image file",
            type: "string",
            pattern: "^(?:http(s?)|data)://[\\w\\-\\._~:/?#[\\]@%!\\$&'\\(\\)\\*\\+,;=.]+(?:jpg|gif|png|jpeg|svg|JPG|GIF|PNG|JPEG|SVG)(?!\\w|\\d)"
        },
        COLOR: {
            $id: "color",
            description: "Hex representation of a color",
            type: "string",
            pattern: "^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$"
        },
        DIRECTION: {
            $id: "direction",
            type: "number",
            minimum: 0,
            maximum: 360,
            description: "the angle, in degress, of the vector relative to the horizontal axis of the image"
        },
        POINT: {
            $id: "point",
            description: "A point annotation on an image",
            type: "object",
            properties: {
                x: {
                    type: "integer",
                    minimum: 0,
                    description: "The distance, in pixels, between the point and the left border of the image."
                },
                y: {
                    type: "integer",
                    minimum: 0,
                    description: "The distance, in pixels, between the point and the top border of the image."
                },
                selection: {
                    $ref: "choice"
                }
            },
            required: ["x", "y"],
            additionalProperties: false
        },
        KEY_POINT: {
            $id: "key-point",
            description: "A point annotation on a specific point on an image",
            type: "object",
            properties: {
                x: {
                    type: "integer",
                    minimum: 0,
                    description: "The distance, in pixels, between the point and the left border of the image."
                },
                y: {
                    type: "integer",
                    minimum: 0,
                    description: "The distance, in pixels, between the point and the top border of the image."
                },
                description: {
                    type: "string"
                },
                visible: {
                    type: "boolean",
                    default: true,
                    description: "true if the key point is visible, false if occluded"
                },
                selection: {
                    $ref: "choice"
                }
            },
            required: ["x", "y", "selection"],
            additionalProperties: false
        },
        CIRCLE: {
            $id: "circle",
            description: "A circle annotation on an image",
            type: "object",
            properties: {
                cx: {
                    type: "integer",
                    minimum: 0,
                    description: "The distance, in pixels, between the center of the circle and the left border of the image."
                },
                cy: {
                    type: "integer",
                    minimum: 0,
                    description: "The distance, in pixels, between the center of the circle and the top border of the image."
                },
                radius: {
                    type: "integer",
                    minimum: 0,
                    description: "The radius of the circle"
                },
                selection: {
                    $ref: "choice"
                },
                minRadius: {
                    type: "integer",
                    minimum: 0,
                    default: 0,
                    description: "minimum size of circle in pixels"
                }
            },
            required: ["cx", "cy", "radius"],
            additionalProperties: false
        },
        ELLIPSE: {
            $id: "ellipse",
            description: "An ellipse annotation on an image",
            type: "object",
            properties: {
                cx: {
                    type: "integer",
                    minimum: 0,
                    description: "The distance, in pixels, between the center of the ellipse and the left border of the image."
                },
                cy: {
                    type: "integer",
                    minimum: 0,
                    description: "The distance, in pixels, between the center of the ellipse and the top border of the image."
                },
                width: {
                    type: "integer",
                    minimum: 0,
                    description: "The width along the x axis, in pixels, of the ellipse."
                },
                height: {
                    type: "integer",
                    minimum: 0,
                    description: "The height along the y-axis, in pixels, of the ellipse."
                },
                angle: {
                    type: "number",
                    description: "angle of rotation of the ellipse"
                },
                selection: {
                    $ref: "choice"
                },
                minWidth: {
                    type: "integer",
                    minimum: 0,
                    description: "Minimum width of bounding box"
                },
                minHeight: {
                    type: "integer",
                    minimum: 0,
                    description: "Minimum height of bounding box"
                }
            },
            required: ["cx", "cy", "width", "height", "angle"],
            additionalProperties: false
        },
        EDGE: {
            $id: "edge",
            description: "An edge in an annotation",
            type: "object",
            properties: {
                x1: {
                    type: "integer",
                    minimum: 0,
                    description: "The distance, in pixels, between the first vertex and the left border of the image."
                },
                y1: {
                    type: "integer",
                    minimum: 0,
                    description: "The distance, in pixels, between the first vertex and the top border of the image."
                },
                x2: {
                    type: "integer",
                    minimum: 0,
                    description: "The distance, in pixels, between the second vertex and the left border of the image."
                },
                y2: {
                    type: "integer",
                    minimum: 0,
                    description: "The distance, in pixels, between the second vertex and the top border of the image."
                },
                selection: {
                    $ref: "choice"
                }
            },
            required: ["x1", "y1", "x2", "y2"],
            additionalProperties: false
        },
        DIRECTED_EDGE: {
            $id: "directed-edge",
            description: "An edge with a direction in an annotation",
            type: "object",
            properties: {
                x1: {
                    type: "integer",
                    minimum: 0,
                    description: "The distance, in pixels, between the first vertex and the left border of the image."
                },
                y1: {
                    type: "integer",
                    minimum: 0,
                    description: "The distance, in pixels, between the first vertex and the top border of the image."
                },
                x2: {
                    type: "integer",
                    minimum: 0,
                    description: "The distance, in pixels, between the second vertex and the left border of the image."
                },
                y2: {
                    type: "integer",
                    minimum: 0,
                    description: "The distance, in pixels, between the second vertex and the top border of the image."
                },
                direction: {
                    $ref: "direction"
                },
                selection: {
                    $ref: "choice"
                }
            },
            required: ["x1", "y1", "x2", "y2", "direction"],
            additionalProperties: false
        },
        INDEXED_EDGE: {
            $id: "indexed-edge",
            description: "An edge defined by the index of two vertices",
            type: "object",
            properties: {
                startIndex: {
                    type: "integer",
                    minimum: 0,
                    description: "The index of the starting point of the edge"
                },
                endIndex: {
                    type: "integer",
                    minimum: 0,
                    description: "The index of the ending vertex of the edge"
                },
                selection: {
                    $ref: "choice"
                }
            },
            required: ["startIndex", "endIndex"],
            additionalProperties: false
        },
        LINE: {
            $id: "line",
            description: "An array describing the vertices of the segmented line, listed in order from one end to another.",
            type: "object",
            properties: {
                selection: {
                    $ref: "choice"
                },
                vertices: {
                    type: "array",
                    items: {
                        $ref: "point"
                    },
                    minItems: 1
                },
                minVertices: {
                    type: "integer",
                    minimum: 0,
                    description: "An optional parameter defining the minimum number of vertices in a valid annotation. Must be at least minVertices."
                },
                maxVertices: {
                    type: "integer",
                    minimum: 0,
                    description: "An optional parameter defining the maximum number of vertices in a valid annotation."
                }
            },
            required: ["vertices"]
        },
        BOUNDING_BOX: {
            $id: "bounding-box",
            type: "object",
            properties: {
                top: {
                    type: "integer",
                    minimum: 0,
                    description: "The distance, in pixels, between the top of the bounding box and the left top of the image."
                },
                left: {
                    type: "integer",
                    minimum: 0,
                    description: "The distance, in pixels, between the left of the bounding box and the left border of the image."
                },
                width: {
                    type: "integer",
                    minimum: 0,
                    description: "The width, in pixels, of the bounding box."
                },
                height: {
                    type: "integer",
                    minimum: 0,
                    description: "The height, in pixels, of the bounding box."
                },
                selection: {
                    $ref: "choice"
                },
                minWidth: {
                    type: "integer",
                    minimum: 0,
                    description: "Minimum width of bounding box"
                },
                minHeight: {
                    type: "integer",
                    minimum: 0,
                    description: "Minimum height of bounding box"
                }
            },
            required: ["top", "left", "width", "height"]
        },
        POLYGON: {
            $id: "polygon",
            description: "An array describing the vertices of the polygon, listed in order from one end, ending with the first point.",
            type: "object",
            properties: {
                selection: {
                    $ref: "choice"
                },
                vertices: {
                    type: "array",
                    items: {
                        $ref: "point"
                    },
                    minItems: 1
                },
                minVertices: {
                    type: "integer",
                    minimum: 0,
                    description: "An optional parameter defining the minimum number of vertices in a valid annotation. Must be at least minVertices."
                },
                maxVertices: {
                    type: "integer",
                    minimum: 0,
                    description: "An optional parameter defining the maximum number of vertices in a valid annotation."
                }
            },
            required: ["vertices"]
        },
        DIRECTED_POLYGON: {
            $id: "directed-polygon",
            description: "An array describing the vertices of the polygon, listed in order from one end to another.",
            type: "object",
            properties: {
                selection: {
                    $ref: "choice"
                },
                vertices: {
                    type: "array",
                    items: {
                        $ref: "point"
                    },
                    minItems: 1
                },
                direction: {
                    $ref: "direction"
                },
                minVertices: {
                    type: "integer",
                    minimum: 0,
                    description: "An optional parameter defining the minimum number of vertices in a valid annotation. Must be at least minVertices."
                },
                maxVertices: {
                    type: "integer",
                    minimum: 0,
                    description: "An optional parameter defining the maximum number of vertices in a valid annotation."
                }
            },
            required: ["vertices", "direction"]
        },
        CUBOID: {
            $id: "cuboid",
            type: "object",
            properties: {
                selection: {
                    $ref: "choice"
                },
                vertices: {
                    type: "object",
                    properties: {
                        "face-top-left": {
                            $ref: "point"
                        },
                        "face-bottom-left": {
                            $ref: "point"
                        },
                        "face-top-right": {
                            $ref: "point"
                        },
                        "face-bottom-right": {
                            $ref: "point"
                        },
                        "side-top-corner": {
                            $ref: "point"
                        },
                        "side-bottom-corner": {
                            $ref: "point"
                        }
                    },
                    required: [
                        "face-top-left",
                        "face-bottom-left",
                        "face-top-right",
                        "face-bottom-right",
                        "side-top-corner",
                        "side-bottom-corner"
                    ]
                },
                edges: {
                    type: "object",
                    properties: {
                        "face-top": {
                            $ref: "edge"
                        },
                        "face-right": {
                            $ref: "edge"
                        },
                        "face-bottom": {
                            $ref: "edge"
                        },
                        "face-left": {
                            $ref: "edge"
                        },
                        "side-top": {
                            $ref: "edge"
                        },
                        "side-bottom": {
                            $ref: "edge"
                        }
                    },
                    required: [
                        "face-top",
                        "face-right",
                        "face-bottom",
                        "face-left",
                        "side-top",
                        "side-bottom"
                    ]
                },
                minWidth: {
                    type: "integer",
                    minimum: 0,
                    default: 0,
                    description: "The minimum width in pixels of the bounding cuboids you’d like to be made."
                },
                minHeight: {
                    type: "integer",
                    minimum: 0,
                    default: 0,
                    description: "The minimum height in pixels of the bounding cuboids you’d like to be made."
                }
            },
            required: ["vertices", "edges"]
        },
        CHOICE: {
            $id: "choice",
            description: "Choice type",
            type: "object",
            properties: {
                value: {
                    anyOf: [
                        {
                            $ref: "text"
                        }
                    ]
                },
                tag: {
                    type: "string"
                }
            },
            required: ["value", "tag"]
        },
        UNIVERSAL: {
            $id: "universal",
            description: "Debugging type not interpretable by UI",
            anyOf: [
                {
                    type: "number"
                },
                {
                    type: "string"
                },
                {
                    type: "object"
                },
                {
                    type: "array"
                },
                {
                    type: "boolean"
                }
            ]
        },
        URL: {
            $id: "url",
            description: "A valid URL",
            type: "string",
            format: "uri",
            pattern: "^(?:http(s?)|data)://[\\w\\-\\._~:/?#[\\]@%!\\$&'\\(\\)\\*\\+,;=.]+"
        },
        INTEGER: {
            $id: "integer",
            type: "integer",
            description: "An integer"
        },
        NUMBER: {
            $id: "number",
            type: "number",
            description: "A number"
        },
        TEXT: {
            $id: "text",
            type: "string",
            description: "Text represented as a string"
        },
        VIDEO: {
            $id: "video",
            description: "The URL of an video file",
            type: "string",
            pattern: "^(?:http(s?)|data)://[\\w\\-\\._~:/?#[\\]@%!\\$&'\\(\\)\\*\\+,;=.]+(?:avi|flv|wmv|mov|mp4|AVI|FLV|WMV|MOV|MP4)(?!\\w|\\d)"
        },
        SATELLITE: {
            $id: "satellite",
            description: "The URL of an satellite file",
            type: "string",
            pattern: "^(?:http(s?)|data)://[\\w\\-\\._~:/?#[\\]@%!\\$&'\\(\\)\\*\\+,;=.]+(?:geotiff|tif|GEOTIFF|TIF)(?!\\w|\\d)"
        },
        BINARY_CHOICE: {
            $id: "binary-choice",
            description: "Two state switch",
            type: "boolean"
        },
        EXCLUSIVE_CHOICE: {
            $id: "exclusive-choice",
            description: "Choose one of the choices",
            type: "object",
            properties: {
                choices: {
                    type: "array",
                    description: "List of choices that will be shown to the task annotator",
                    items: {
                        $ref: "choice"
                    },
                    minItems: 1
                },
                selection: {
                    $ref: "choice"
                }
            },
            required: ["choices"]
        },
        MULTIPLE_CHOICE: {
            $id: "multiple-choice",
            description: "Choose one or more of the choices",
            type: "object",
            properties: {
                choices: {
                    type: "array",
                    description: "List of choices that will be shown to the task annotator",
                    items: {
                        $ref: "choice"
                    },
                    minItems: 1
                },
                selections: {
                    type: "array",
                    items: {
                        $ref: "choice"
                    }
                },
                minSelections: {
                    type: "integer"
                },
                maxSelections: {
                    type: "integer"
                }
            },
            required: ["choices"]
        },
        IMAGE_BOUNDING_BOX: {
            $id: "image-bounding-box",
            description: "A 2d square annotation on an image",
            type: "object",
            properties: {
                imageUrl: {
                    $ref: "image"
                },
                annotations: {
                    type: "array",
                    items: {
                        $ref: "bounding-box"
                    }
                },
                choices: {
                    type: "array",
                    description: "Possible values for underlying bounding boxes",
                    items: {
                        $ref: "choice"
                    }
                },
                minWidth: {
                    type: "integer",
                    minimum: 0,
                    default: 0,
                    description: "Minimum width of bounding box"
                },
                minHeight: {
                    type: "integer",
                    minimum: 0,
                    description: "Minimum height of bounding box"
                },
                minAnnotations: {
                    type: "integer",
                    minimum: 0,
                    default: 0,
                    description: "The minimum number of points that will be returned. Should only be used when one is certain that the image contains at least this many annotatable cuboids."
                },
                maxAnnotations: {
                    type: "integer",
                    description: "The maximum number of points that can be annotated in the image. A value of 0 (default) means no limit."
                }
            },
            required: ["imageUrl"]
        },
        IMAGE_SEQUENCE_BOUNDING_BOX: {
            $id: "image-sequence-bounding-box",
            description: "A 2d square annotation on a sequence of images",
            type: "object",
            properties: {
                imageSequence: {
                    type: "array",
                    items: {
                        type: "object",
                        properties: {
                            imageUrl: {
                                $ref: "image"
                            },
                            id: {
                                type: "integer",
                                minimum: 0
                            }
                        }
                    }
                },
                annotations: {
                    type: "array",
                    items: {
                        type: "object",
                        properties: {
                            id: {
                                type: "integer",
                                minimum: 0
                            },
                            annotations: {
                                type: "array",
                                items: {
                                    $ref: "bounding-box"
                                }
                            }
                        }
                    }
                },
                choices: {
                    type: "array",
                    description: "Possible values for underlying bounding boxes",
                    items: {
                        $ref: "choice"
                    }
                },
                minWidth: {
                    type: "integer",
                    minimum: 0,
                    description: "Minimum width of bounding box"
                },
                minHeight: {
                    type: "integer",
                    minimum: 0,
                    description: "Minimum height of bounding box"
                },
                minAnnotations: {
                    type: "integer",
                    minimum: 0,
                    default: 0,
                    description: "The minimum number of points that will be returned. Should only be used when one is certain that the image contains at least this many annotatable cuboids."
                },
                maxAnnotations: {
                    type: "integer",
                    description: "The maximum number of points that can be annotated in the image. A value of 0 (default) means no limit."
                }
            },
            required: ["imageSequence", "choices"]
        },
        IMAGE_POLYGON: {
            $id: "image-polygon",
            description: "A polygon annotation on an image",
            type: "object",
            properties: {
                imageUrl: {
                    $ref: "image"
                },
                annotations: {
                    type: "array",
                    items: {
                        $ref: "polygon"
                    }
                },
                choices: {
                    type: "array",
                    description: "Possible values for underlying bounding boxes",
                    items: {
                        $ref: "choice"
                    }
                },
                minAnnotations: {
                    type: "integer",
                    minimum: 0,
                    default: 0,
                    description: "The minimum number of points that will be returned. Should only be used when one is certain that the image contains at least this many annotatable cuboids."
                },
                maxAnnotations: {
                    type: "integer",
                    description: "The maximum number of points that can be annotated in the image. A value of 0 (default) means no limit."
                }
            },
            required: ["imageUrl"]
        },
        IMAGE_DIRECTED_POLYGON: {
            $id: "image-directed-polygon",
            description: "A directed polygon annotation on an image",
            type: "object",
            properties: {
                imageUrl: {
                    $ref: "image"
                },
                annotations: {
                    type: "array",
                    items: {
                        $ref: "directed-polygon"
                    }
                },
                choices: {
                    type: "array",
                    description: "Possible values for underlying bounding boxes",
                    items: {
                        $ref: "choice"
                    }
                },
                minAnnotations: {
                    type: "integer",
                    minimum: 0,
                    default: 0,
                    description: "The minimum number of points that will be returned. Should only be used when one is certain that the image contains at least this many annotatable cuboids."
                },
                maxAnnotations: {
                    type: "integer",
                    description: "The maximum number of points that can be annotated in the image. A value of 0 (default) means no limit."
                }
            },
            required: ["imageUrl", "choices"]
        },
        IMAGE_LINE: {
            $id: "image-line",
            description: "A line annotation on an image",
            type: "object",
            properties: {
                imageUrl: {
                    $ref: "image"
                },
                annotations: {
                    type: "array",
                    items: {
                        $ref: "line"
                    }
                },
                choices: {
                    type: "array",
                    description: "Objects classes you want to annotate",
                    items: {
                        $ref: "choice"
                    }
                },
                minAnnotations: {
                    type: "integer",
                    minimum: 0,
                    default: 0,
                    description: "The minimum number of points that will be returned. Should only be used when one is certain that the image contains at least this many annotatable cuboids."
                },
                maxAnnotations: {
                    type: "integer",
                    description: "The maximum number of points that can be annotated in the image. A value of 0 (default) means no limit."
                }
            },
            required: ["imageUrl", "choices"]
        },
        IMAGE_CIRCLE: {
            $id: "image-circle",
            description: "A circle annotation on an image",
            type: "object",
            properties: {
                imageUrl: {
                    $ref: "image"
                },
                annotations: {
                    type: "array",
                    items: {
                        $ref: "circle"
                    }
                },
                choices: {
                    type: "array",
                    description: "Objects classes you want to annotate",
                    items: {
                        $ref: "choice"
                    }
                },
                minAnnotations: {
                    type: "integer",
                    minimum: 0,
                    default: 0,
                    description: "The minimum number of circles that will be returned. Should only be used when one is certain that the image contains at least this many annotatable cuboids."
                },
                maxAnnotations: {
                    type: "integer",
                    description: "The maximum number of circles that can be annotated in the image. A value of 0 (default) means no limit."
                }
            },
            required: ["imageUrl", "choices"]
        },
        IMAGE_ELLIPSE: {
            $id: "image-ellipse",
            description: "An ellipse annotation on an image",
            type: "object",
            properties: {
                imageUrl: {
                    $ref: "image"
                },
                annotations: {
                    type: "array",
                    items: {
                        $ref: "ellipse"
                    }
                },
                choices: {
                    type: "array",
                    description: "Objects classes you want to annotate",
                    items: {
                        $ref: "choice"
                    }
                },
                minAnnotations: {
                    type: "integer",
                    minimum: 0,
                    default: 0,
                    description: "The minimum number of ellipses that will be returned. Should only be used when one is certain that the image contains at least this many annotatable cuboids."
                },
                maxAnnotations: {
                    type: "integer",
                    description: "The maximum number of ellipses that can be annotated in the image. A value of 0 (default) means no limit."
                }
            },
            required: ["imageUrl", "choices"]
        },
        IMAGE_POINT: {
            $id: "image-point",
            description: "A point annotation on an image",
            type: "object",
            properties: {
                imageUrl: {
                    $ref: "image"
                },
                annotations: {
                    type: "array",
                    items: {
                        $ref: "point"
                    }
                },
                choices: {
                    type: "array",
                    description: "Objects classes you want to annotate",
                    items: {
                        $ref: "choice"
                    }
                },
                minAnnotations: {
                    type: "integer",
                    minimum: 0,
                    default: 0,
                    description: "The minimum number of points that will be returned. Should only be used when one is certain that the image contains at least this many annotatable cuboids."
                },
                maxAnnotations: {
                    type: "integer",
                    description: "The maximum number of points that can be annotated in the image. A value of 0 (default) means no limit."
                }
            },
            required: ["imageUrl", "choices"]
        },
        IMAGE_KEY_POINT: {
            $id: "image-key-point",
            description: "A point annotation on an image",
            type: "object",
            properties: {
                imageUrl: {
                    $ref: "image"
                },
                annotations: {
                    type: "array",
                    items: {
                        type: "array",
                        items: {
                            $ref: "key-point"
                        }
                    }
                },
                referenceKeyPoints: {
                    type: "array",
                    items: {
                        type: "object",
                        properties: {
                            tag: {
                                type: "string"
                            },
                            description: {
                                $ref: "text"
                            }
                        }
                    }
                },
                referenceEdges: {
                    type: "array",
                    items: {
                        $ref: "indexed-edge"
                    }
                }
            },
            required: ["imageUrl", "referenceKeyPoints"]
        },
        CAMERA_INTRINSICS: {
            $id: "camera-intrinsics",
            type: "object",
            properties: {
                fx: {
                    type: "integer",
                    description: "The focal length, in pixels, of the camera along the horizontal axis"
                },
                fy: {
                    type: "integer",
                    description: "The focal length, in pixels, of the camera along the vertical axis"
                },
                cx: {
                    type: "integer",
                    minimum: 0,
                    description: "The distance, in pixels, between the camera's principal point (optical center) and the left border of the image."
                },
                cy: {
                    type: "integer",
                    minimum: 0,
                    description: "The distance, in pixels, between the camera's principal point (optical center) and the top border of the image."
                },
                scaleFactor: {
                    type: "number",
                    minimum: 0,
                    default: 1,
                    description: "Used to correct focal lengths and offsets if the image annotated is different dimensions from the original (e.g. if the attachment is half the original use a scale factor of 2)."
                },
                skew: {
                    type: "number",
                    minimum: 0,
                    default: 0,
                    description: "The skew, in pixels, of the camera."
                }
            },
            required: ["fx", "fy", "cx", "cy"]
        },
        QUATERNION: {
            $id: "quaternion",
            type: "object",
            description: "",
            properties: {
                w: {
                    type: "number",
                    description: "Scalar component of the quaternion, defining the cosine of half the angle of rotation."
                },
                x: {
                    type: "number",
                    description: "x component of the vector defining the axis of rotation"
                },
                y: {
                    type: "number",
                    description: "y component of the vector defining the axis of rotation"
                },
                z: {
                    type: "number",
                    description: "z component of the vector defining the axis of rotation"
                }
            },
            required: ["w", "x", "y", "z"]
        },
        IMAGE_CUBOID: {
            $id: "image-cuboid",
            description: "A cuboid (3D box) annotation on an image",
            type: "object",
            properties: {
                imageUrl: {
                    $ref: "image"
                },
                annotations: {
                    type: "array",
                    items: {
                        $ref: "cuboid"
                    }
                },
                choices: {
                    type: "array",
                    description: "Objects classes you want to annotate",
                    items: {
                        $ref: "choice"
                    }
                },
                minAnnotations: {
                    type: "integer",
                    minimum: 0,
                    default: 0,
                    description: "The minimum number of cuboids that will be returned. Should only be used when one is certain that the image contains at least this many annotatable cuboids."
                },
                maxAnnotations: {
                    type: "integer",
                    description: "The maximum number of cuboids that can be annotated in the image. A value of 0 (default) means no limit."
                },
                minWidth: {
                    type: "integer",
                    minimum: 0,
                    default: 0,
                    description: "The minimum width in pixels of the bounding cuboids you’d like to be made."
                },
                minHeight: {
                    type: "integer",
                    minimum: 0,
                    default: 0,
                    description: "The minimum height in pixels of the bounding cuboids you’d like to be made."
                },
                cameraIntrinsics: {
                    $ref: "camera-intrinsics"
                },
                cameraHeight: {
                    type: "number",
                    minimum: 0,
                    description: "The height above the ground, in meters, of the camera's principal point (optical center)."
                },
                cameraRotationQuaternion: {
                    $ref: "quaternion",
                    description: "Quaternion defining the rotation of the camera in relation to the world. The z-axis of the camera frame represents the camera’s optical axis."
                }
            },
            required: ["imageUrl", "choices"]
        },
        IMAGE_SEQUENCE_CUBOID: {
            $id: "image-sequence-cuboid",
            description: "A 3d box annotation on a sequence of image images",
            type: "object",
            properties: {
                imageSequence: {
                    type: "array",
                    items: {
                        type: "object",
                        properties: {
                            imageUrl: {
                                $ref: "image"
                            },
                            id: {
                                type: "integer",
                                minimum: 0
                            }
                        }
                    }
                },
                annotations: {
                    type: "array",
                    items: {
                        type: "object",
                        properties: {
                            id: {
                                type: "integer",
                                minimum: 0
                            },
                            annotations: {
                                type: "array",
                                items: {
                                    $ref: "cuboid"
                                }
                            }
                        }
                    }
                },
                choices: {
                    type: "array",
                    description: "Possible values for underlying bounding boxes",
                    items: {
                        $ref: "choice"
                    }
                },
                minWidth: {
                    type: "integer",
                    minimum: 0,
                    default: 0,
                    description: "Minimum width of bounding cuboid"
                },
                minHeight: {
                    type: "integer",
                    minimum: 0,
                    default: 0,
                    description: "Minimum height of bounding cuboid"
                },
                cameraIntrinsics: {
                    $ref: "camera-intrinsics",
                    description: "Camera intrinsics that will be applied for all image frames in the video."
                },
                cameraRotationQuaternionPerFrame: {
                    description: "Array of quaternions defining the rotation of the camera per frame. The z-axis of the camera frame represents the camera’s optical axis.",
                    type: "array",
                    items: {
                        $ref: "quaternion"
                    }
                },
                cameraHeightPerFrame: {
                    description: "Array of the the height above the ground, in meters, of the camera's principal point (optical center) per frame",
                    type: "array",
                    items: {
                        type: "number",
                        minimum: 0,
                        description: "The height above the ground, in meters, of the camera's principal point (optical center)."
                    }
                },
                minAnnotations: {
                    type: "integer",
                    minimum: 0,
                    default: 0,
                    description: "The minimum number of points that will be returned. Should only be used when one is certain that the image contains at least this many annotatable cuboids."
                },
                maxAnnotations: {
                    type: "integer",
                    description: "The maximum number of points that can be annotated in the image. A value of 0 (default) means no limit."
                }
            },
            required: ["imageSequence", "choices", "cameraHeightPerFrame"]
        },
        VIDEO_CUBOID: {
            $id: "video-cuboid",
            description: "A 3d box annotation on a video",
            type: "object",
            properties: {
                videoUrl: {
                    $ref: "video"
                },
                annotations: {
                    type: "array",
                    items: {
                        type: "object",
                        properties: {
                            timestamp: {
                                type: "number",
                                description: "timestamp of the frame in the video"
                            },
                            index: {
                                type: "integer",
                                description: "index of the frame in the video"
                            },
                            annotations: {
                                type: "array",
                                items: {
                                    $ref: "cuboid"
                                }
                            }
                        },
                        required: ["timestamp", "index", "annotations"]
                    }
                },
                choices: {
                    type: "array",
                    description: "Possible values for underlying bounding boxes",
                    items: {
                        $ref: "choice"
                    }
                },
                minWidth: {
                    type: "integer",
                    minimum: 0,
                    default: 0,
                    description: "Minimum width of bounding cuboid"
                },
                minHeight: {
                    type: "integer",
                    minimum: 0,
                    default: 0,
                    description: "Minimum height of bounding cuboid"
                },
                frameRate: {
                    type: "integer",
                    default: 1,
                    description: "Number of frames labeled in one second."
                },
                startTime: {
                    type: "integer",
                    default: 0,
                    description: "Start time in seconds"
                },
                durationTime: {
                    type: "integer",
                    description: "Duration of videos in seconds"
                },
                cameraIntrinsics: {
                    $ref: "camera-intrinsics",
                    description: "Camera intrinsics that will be applied for all frames in the video."
                },
                cameraRotationQuaternionPerFrame: {
                    description: "Array of quaternions defining the rotation of the camera per frame. The z-axis of the camera frame represents the camera’s optical axis.",
                    type: "array",
                    items: {
                        $ref: "quaternion"
                    }
                },
                cameraHeightPerFrame: {
                    description: "Array of the the height above the ground, in meters, of the camera's principal point (optical center) per frame",
                    type: "array",
                    items: {
                        type: "number",
                        minimum: 0,
                        description: "The height above the ground, in meters, of the camera's principal point (optical center)."
                    }
                },
                minAnnotations: {
                    type: "integer",
                    minimum: 0,
                    default: 0,
                    description: "The minimum number of cuboids that will be returned per frame. Should only be used when one is certain that the image contains at least this many annotatable cuboids."
                },
                maxAnnotations: {
                    type: "integer",
                    description: "The maximum number of cuboids that can be annotated per frame. A value of 0 (default) means no limit."
                }
            },
            required: ["videoUrl", "choices", "cameraHeightPerFrame"]
        },
        STRUCTURED: {
            $id: "structured",
            description: "Different data types, structured based on tag",
            type: "object",
            properties: {
                tag: {
                    type: "string"
                },
                type: {
                    type: "string",
                    enum: [
                        "text",
                        "number",
                        "integer",
                        "binary-choice",
                        "multiple-choice",
                        "exclusive-choice"
                    ]
                },
                value: {
                    anyOf: [
                        {
                            $ref: "text"
                        },
                        {
                            $ref: "number"
                        },
                        {
                            $ref: "integer"
                        },
                        {
                            $ref: "binary-choice"
                        },
                        {
                            $ref: "multiple-choice"
                        },
                        {
                            $ref: "exclusive-choice"
                        }
                    ]
                },
                description: {
                    $ref: "text"
                }
            },
            required: ["type", "tag"]
        },
        STRUCTURED_TEXT: {
            $id: "structured-text",
            description: "Text that is structured based on tag",
            type: "object",
            allOf: [
                {
                    $ref: "structured"
                }
            ],
            properties: {
                type: {
                    type: "string",
                    enum: ["text"]
                },
                value: {
                    $ref: "text"
                }
            },
            required: ["tag"]
        },
        TRANSCRIPTION: {
            $id: "transcription",
            description: "Structured text transcription",
            type: "object",
            properties: {
                transcriptions: {
                    type: "array",
                    items: {
                        $ref: "structured-text"
                    }
                },
                fields: {
                    type: "array",
                    items: {
                        $ref: "structured-text"
                    }
                }
            },
            required: ["fields"]
        },
        IMAGE_TRANSCRIPTION: {
            $id: "image-transcription",
            description: "Structured text transcription",
            type: "object",
            properties: {
                imageUrl: {
                    $ref: "image"
                },
                transcriptions: {
                    type: "array",
                    items: {
                        type: "object",
                        properties: {
                            transcription: {
                                $ref: "structured-text"
                            },
                            boundingBox: {
                                $ref: "bounding-box"
                            }
                        },
                        required: ["transcription", "boundingBox"]
                    }
                },
                fields: {
                    type: "array",
                    items: {
                        $ref: "structured-text"
                    }
                }
            },
            required: ["imageUrl", "fields"]
        }
    }
};
