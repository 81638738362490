import { useCallback, useRef } from "react"
import { TaskResolveParams, TaskResponse } from "resha"
import { client, submitFeedbackAsWorker } from "neql"
import { Worker } from "../models/Worker"
import { useAppDispatch } from "./useReduxState"
import { resolveTask } from "../actions/api/TaskActions"
import { getValidSession } from "../actions/AuthActions"
import { uploadData } from "../actions/api/DataActions"
import { ToastStatus, useToast } from "./useToast"
import { errorMessages } from "../texts"
import { reportError } from "../actions/tracking/MonitoringActions"

export const useTaskResolve = (
    worker: Worker,
    task: TaskResponse
): [
    (params: TaskResolveParams) => Promise<void>,
    (blob: Blob) => Promise<string>
] => {
    const dispatch = useAppDispatch()
    const errorToast = useToast(ToastStatus.ERROR)
    const { feedbackSubmission } = errorMessages
    // ref is used since, changing the state should not trigger any events that leads to re-rendering of tasks
    const shouldSubmitOnlyFeedback = useRef(false)

    const handleUploadBlob = useCallback(
        async (blob: Blob, objectName: string | undefined) => {
            if (!shouldSubmitOnlyFeedback.current) {
                const taskId = task.id

                if (taskId === undefined) {
                    return Promise.reject("Task ID is not defined")
                }

                return dispatch(getValidSession()).then(t =>
                    uploadData({
                        blob,
                        taskId,
                        workerUuid: worker.uuid,
                        token: t,
                        objectName
                    })
                )
            }
            return ""
        },
        [shouldSubmitOnlyFeedback, task, dispatch, worker.uuid]
    )
    const handleResolve = useCallback(
        async ({
            resolveAction,
            output,
            feedback,
            workerFeedback
        }: TaskResolveParams) => {
            if (task.id === undefined) {
                return
            }

            try {
                if (!shouldSubmitOnlyFeedback.current) {
                    await dispatch(
                        resolveTask({
                            taskId: task.id,
                            output,
                            resolveAction,
                            feedback
                        })
                    )
                }
                if (workerFeedback) {
                    try {
                        const token = await dispatch(getValidSession())
                        await submitFeedbackAsWorker(
                            client(token),
                            task.id,
                            JSON.stringify(workerFeedback.choices),
                            JSON.stringify(workerFeedback.selections)
                        )
                        shouldSubmitOnlyFeedback.current = false
                    } catch (error) {
                        errorToast(feedbackSubmission)
                        shouldSubmitOnlyFeedback.current = true
                        return
                    }
                }
            } catch (error) {
                reportError({ error })

                throw error
            }
        },
        [
            dispatch,
            errorToast,
            feedbackSubmission,
            shouldSubmitOnlyFeedback,
            task
        ]
    )
    const uploadBlob = useCallback(
        (blob: Blob) => handleUploadBlob(blob, undefined),
        [handleUploadBlob]
    )

    return [handleResolve, uploadBlob]
}
