import React, { FC, useEffect, useState } from "react"

interface AlertProps {
    message: string
    success?: boolean
}

export const Alert: FC<AlertProps> = ({ message, success }) => {
    const [visible, setVisible] = useState(false)
    useEffect(() => {
        setTimeout(() => setVisible(true), 120)
    })
    return (
        <div
            className={`w-full mb-4 py-3 px-5 rounded border border-solid transition-400 ${
                visible ? "opacity-100" : "opacity-0"
            }`}
            style={
                success
                    ? {
                          borderColor: "rgb(110, 231, 183)",
                          backgroundColor: "rgba(167, 243, 208)",
                          color: "rgba(6, 95, 70)"
                      }
                    : {
                          borderColor: "#FCA5A5",
                          backgroundColor: "#FECACA",
                          color: "#991B1B"
                      }
            }
        >
            {message}
        </div>
    )
}
