import {
    MessagesAction,
    MessageSentiment
} from "../reducers/client/MessagesReducer"
import { MessagesType } from "./Types"

export const successMessage = (text: string): MessagesAction => ({
    type: MessagesType.set,
    payload: {
        type: MessageSentiment.good,
        text
    }
})

export const warnMessage = (text: string): MessagesAction => ({
    type: MessagesType.set,
    payload: {
        type: MessageSentiment.good,
        text
    }
})

export const failMessage = (text: string, error?: unknown): MessagesAction => {
    const errorMessage =
        error instanceof Error
            ? error.message
            : typeof error === "string"
            ? error
            : undefined
    const message =
        errorMessage === undefined ? text : `${text} ${errorMessage}`

    return {
        type: MessagesType.set,
        payload: {
            type: MessageSentiment.bad,
            text: message
        }
    }
}

export const dismissMessage = (): MessagesAction => ({
    type: MessagesType.dismiss
})
