"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.jsonSchemaV002 = void 0;
const schema_json_1 = require("../0.0.1/schema_json");
exports.jsonSchemaV002 = Object.assign(Object.assign({}, schema_json_1.jsonSchemaV001), { $id: "http://schema.canotic.com/v0.0.2/", definitions: Object.assign(Object.assign({}, schema_json_1.jsonSchemaV001.definitions), { IMAGE_CIRCLE: Object.assign(Object.assign({}, schema_json_1.jsonSchemaV001.definitions.IMAGE_CIRCLE), { properties: Object.assign(Object.assign({}, schema_json_1.jsonSchemaV001.definitions.IMAGE_CIRCLE.properties), { choices: Object.assign(Object.assign({}, schema_json_1.jsonSchemaV001.definitions.IMAGE_CIRCLE.properties
                    .choices), { minItems: 2 }) }), required: ["imageUrl"] }), IMAGE_COMPARISON: {
            $id: "image-comparison",
            description: "Finding similar features in the images",
            type: "object",
            properties: {
                images: {
                    type: "array",
                    items: {
                        $ref: "image-circle"
                    },
                    minItems: 2,
                    maxItems: 2
                }
            }
        } }) });
