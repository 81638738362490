export type SpecialWorker = typeof Worker & (new () => Worker)

export enum IntervalWorkerMessages {
    START = "START",
    TICK = "TICK",
    STOP = "STOP"
}

declare const self: Worker

let id: NodeJS.Timeout
self.onmessage = (message: MessageEvent) => {
    if (message.data) {
        const {
            delay,
            message: intervalWorkerMessage
        }: {
            delay: number
            message: IntervalWorkerMessages
        } = message.data

        const tick = () => {
            self.postMessage(IntervalWorkerMessages.TICK)
        }
        switch (intervalWorkerMessage) {
            case IntervalWorkerMessages.START:
                id = setInterval(tick, delay)
                break
            case IntervalWorkerMessages.STOP:
                clearInterval(id)
                break
            default:
                break
        }
    }
}

export const IntervalWorker = {} as SpecialWorker
