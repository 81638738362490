import { push } from "connected-react-router"
import React, {
    FC,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useState
} from "react"
import { Banner } from "dash-styled"
import { updateLoginTime } from "../../actions/AuthActions"
import {
    setLastSuccessfulKeepAlive,
    toggleInactiveWarningBanner
} from "../../actions/BannerActions"
import {
    showConnectionLostModal,
    showTimeoutModal
} from "../../actions/ModalActions"
import { getTimeFromSeconds } from "../../helpers/time"
import { useInterval } from "../../hooks/useInterval"
import { useAppDispatch, useAppState } from "../../hooks/useReduxState"
import { Worker } from "../../models/Worker"
import { routePaths } from "../../routes"
import { DeadManSwitch } from "../DeadManSwitch/DeadManSwitch"

const INACTIVITY_TIMEOUT_COUNTER_TOTAL = 60 * 5 - 5 // 5min - 5s buffer
const KEEP_ALIVE_INTERVAL = 20 // 20 seconds
const SPINNER_DEBOUNCE_TIME = 1000 * 1 // 1 second

interface DeadManCountdownProps {
    worker: Worker
    children: ReactNode
}
export const DeadManCountdown: FC<DeadManCountdownProps> = ({
    worker,
    children
}) => {
    const dispatch = useAppDispatch()
    const {
        inactiveWarning: showInactiveWarningBanner,
        lastSuccessfulKeepAlive
    } = useAppState(state => state.client.banner)
    const [
        inactiveWarningBannerVisibleTimestamp,
        setInactiveWarningBannerVisibleTimestamp
    ] = useState<number | undefined>(undefined)
    const [inactiveWarningBannerTimer, setInactiveWarningBannerTimer] =
        useState(0)
    const [bannerRetryLoading, setBannerRetryLoading] = useState(false)

    const handleBannerRetryClicked = useCallback(() => {
        setBannerRetryLoading(true)
        dispatch(updateLoginTime({ workerUuid: worker.uuid }))
            .then(() => {
                dispatch(toggleInactiveWarningBanner(false))
            })
            .finally(() => {
                setTimeout(() => {
                    setBannerRetryLoading(false)
                }, SPINNER_DEBOUNCE_TIME)
            })
    }, [dispatch, worker.uuid])

    useInterval(() => {
        if (showInactiveWarningBanner) {
            setInactiveWarningBannerTimer(inactiveWarningBannerTimer + 1)
        } else {
            setInactiveWarningBannerTimer(0)
        }
    }, 1000)

    useEffect(() => {
        if (showInactiveWarningBanner) {
            setInactiveWarningBannerVisibleTimestamp(
                Math.ceil(Date.now() / 1000)
            )
        }
    }, [showInactiveWarningBanner])

    const timeLeft = useMemo(() => {
        let total = INACTIVITY_TIMEOUT_COUNTER_TOTAL

        if (inactiveWarningBannerVisibleTimestamp !== undefined) {
            total =
                total -
                (lastSuccessfulKeepAlive
                    ? inactiveWarningBannerVisibleTimestamp -
                      lastSuccessfulKeepAlive
                    : KEEP_ALIVE_INTERVAL)
        }

        return total - inactiveWarningBannerTimer
    }, [
        inactiveWarningBannerTimer,
        inactiveWarningBannerVisibleTimestamp,
        lastSuccessfulKeepAlive
    ])

    useEffect(() => {
        if (timeLeft === 0) {
            dispatch(push(routePaths.home()))
            if (showInactiveWarningBanner) {
                dispatch(showConnectionLostModal())
            } else {
                dispatch(showTimeoutModal())
            }
        }
    }, [dispatch, showInactiveWarningBanner, timeLeft])

    useEffect(() => {
        return () => {
            dispatch(toggleInactiveWarningBanner(false))
            dispatch(setLastSuccessfulKeepAlive(undefined))
        }
    }, [dispatch])

    return (
        <>
            {children}
            <Banner
                visible={showInactiveWarningBanner}
                title="We're having trouble connecting"
                description={`Please check your internet connection. If we can’t connect, you’ll lose this task in ${getTimeFromSeconds(
                    timeLeft
                )}`}
                buttonText="Retry"
                type="warn"
                loading={bannerRetryLoading}
                onButtonClick={handleBannerRetryClicked}
            />
            {!showInactiveWarningBanner && <DeadManSwitch />}
        </>
    )
}
