import {
    Divider,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary
} from "@material-ui/core"
import ExpandMore from "@material-ui/icons/ExpandMore"
import React, { FC, useState } from "react"
import { Billing } from "../../models/Billing"

interface EarningsExpansionPanelProps {
    earning: Billing
}

export const EarningsExpansionPanel: FC<EarningsExpansionPanelProps> = ({
    earning
}) => {
    const [expanded, setExpanded] = useState<string | false>(false)

    const handleChange =
        (panel: string) =>
        (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false)
        }

    const Row = ({
        amount,
        quantity,
        tag,
        table
    }: {
        tag?: string
        amount: number
        quantity: number
        table?: boolean
    }) => (
        <div className={`flex justify-start w-full ${table ? "text-sm" : ""}`}>
            <div className={`${table ? "w-1/3" : "w-4/12 md:w-1/4"}`}>
                {tag}
            </div>
            <div className={`${table ? "w-1/3" : "w-3/12 md:w-1/4"}`}>
                {`${table ? "" : "Tasks: "}${quantity}`}
            </div>
            <div className={`${table ? "w-1/3" : "w-5/12 md:w-1/4"}`}>
                {`${table ? "" : "Amount: "}${amount.toFixed(2)}`}
            </div>
        </div>
    )
    return (
        <ExpansionPanel
            className="mb-2"
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
        >
            <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <Row
                    tag={earning.tag}
                    amount={earning.amount}
                    quantity={earning.qty}
                />
            </ExpansionPanelSummary>
            <Divider className="w-full mb-4" />
            <ExpansionPanelDetails>
                <div className="flex flex-col w-full">
                    <div className="flex justify-start mb-4 text-sm">
                        <div className="w-1/3">Task</div>
                        <div className="w-1/3">Quantity</div>
                        <div className="w-1/3">Amount</div>
                    </div>
                    <Divider className="w-full mb-4" />
                    {earning.children.map(({ tag, amount, qty }, index) => (
                        <Row
                            key={index}
                            tag={tag}
                            amount={amount}
                            quantity={qty}
                            table={true}
                        />
                    ))}
                </div>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    )
}
