/* eslint-disable import/no-webpack-loader-syntax */

import { useEffect, useRef } from "react"
// @ts-ignore
import IntervalWorker from "workerize-loader!../worker/interval-worker"
import { IntervalWorkerMessages } from "../worker/interval-worker"

export const useInterval = (callback: () => void, delay: number) => {
    const savedCallback = useRef<() => void>(() => {})

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    // Set up the interval.
    useEffect(() => {
        const intervalWorker = new IntervalWorker()
        intervalWorker.postMessage({
            delay,
            message: IntervalWorkerMessages.START
        })
        const handleWorkerMessage = () => {
            savedCallback.current()
        }
        intervalWorker.addEventListener("message", handleWorkerMessage)
        return () => {
            intervalWorker.removeEventListener("message", handleWorkerMessage)
            intervalWorker.postMessage({
                delay,
                message: IntervalWorkerMessages.STOP
            })
            intervalWorker.terminate()
        }
    }, [delay])
}
