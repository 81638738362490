import { Reducer } from "redux"
import { AuthType } from "../../actions/Types"
import { Worker } from "../../models/Worker"

export interface AuthState {
    confirmationMode: boolean
    worker?: Worker
    isSigningIn: boolean
    isSignedIn: boolean
    loading: boolean
}

interface LoggedInSuccessfullyAction {
    type: AuthType.loggedIn
    payload: Worker
}

interface ChangeConfirmationModeAction {
    type: AuthType.confirmationMode
    payload: boolean
}

interface SigningInAction {
    type: AuthType.isSigningIn
}

interface NotSigningInAction {
    type: AuthType.isNotSigningIn
}

interface SuccessfullySignedInAction {
    type: AuthType.isSignedIn
}

interface FailedSignedInAction {
    type: AuthType.isNotSignedIn
}

interface ResetWorkerAction {
    type: AuthType.resetWorker
    payload: undefined
}

interface ToggleLoadingForAuthAction {
    type: AuthType.toggleLoading
    payload: boolean
}

export type AuthAction =
    | ChangeConfirmationModeAction
    | LoggedInSuccessfullyAction
    | SigningInAction
    | NotSigningInAction
    | SuccessfullySignedInAction
    | FailedSignedInAction
    | ResetWorkerAction
    | ToggleLoadingForAuthAction

const initialState: AuthState = {
    confirmationMode: false,
    isSignedIn: false,
    isSigningIn: true,
    loading: false
}

export const AuthReducer: Reducer<AuthState, AuthAction> = (
    state = initialState,
    action
): AuthState => {
    switch (action.type) {
        case AuthType.confirmationMode:
            return {
                ...state,
                confirmationMode: action.payload
            }
        case AuthType.loggedIn:
            return {
                ...state,
                worker: action.payload
            }
        case AuthType.isSigningIn:
            return {
                ...state,
                isSigningIn: true
            }
        case AuthType.isNotSigningIn:
            return {
                ...state,
                isSigningIn: false
            }
        case AuthType.isSignedIn:
            return {
                ...state,
                isSignedIn: true
            }
        case AuthType.isNotSignedIn:
            return {
                ...state,
                isSignedIn: false
            }
        case AuthType.resetWorker:
            return {
                ...state,
                worker: undefined
            }
        case AuthType.toggleLoading:
            return {
                ...state,
                loading: action.payload
            }
        default:
            return state
    }
}
