"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isJSONSchema7 = void 0;
require("json-schema");
function isJSONSchema7(target) {
    return (target !== undefined &&
        typeof target !== "boolean" &&
        !Array.isArray(target));
}
exports.isJSONSchema7 = isJSONSchema7;
