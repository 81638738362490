"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isMultipleTypeObject = exports.isMultipleTypeSchema = exports.toReadableText = exports.isValueValid = exports.toPrimitiveValue = exports.fromPrimitiveValue = exports.convertToSchemaItemsResponse = exports.extractDataFromSchemaItem = exports.convertSchemaItemToSchemaForm = exports.isSchemaForm = exports.isVirtuallyStructuredSchema = exports.isJSONSchema7 = exports.isSimpleType = exports.isUploadableType = exports.taskOutputModernizeOptions = exports.taskInputModernizeOptions = exports.modernizeSchema = exports.findUniversalSchemaTypeByField = exports.createTextSchemaItem = exports.outputTypeFields = exports.databaseMatchingUiFields = exports.classificationModelFieldUiFields = exports.annotationModelFieldUiFields = exports.annotationTypeFields = exports.annotationOutputTypes = exports.dereferenceIfRequired = exports.hasReferenceData = exports.LOC_REFERENCE_KEY = exports.embedReference = exports.dereference = void 0;
var ReferenceObject_1 = require("./misc/ReferenceObject");
Object.defineProperty(exports, "dereference", { enumerable: true, get: function () { return ReferenceObject_1.dereference; } });
Object.defineProperty(exports, "embedReference", { enumerable: true, get: function () { return ReferenceObject_1.embedReference; } });
Object.defineProperty(exports, "LOC_REFERENCE_KEY", { enumerable: true, get: function () { return ReferenceObject_1.LOC_REFERENCE_KEY; } });
Object.defineProperty(exports, "hasReferenceData", { enumerable: true, get: function () { return ReferenceObject_1.hasReferenceData; } });
Object.defineProperty(exports, "dereferenceIfRequired", { enumerable: true, get: function () { return ReferenceObject_1.dereferenceIfRequired; } });
var UniversalSchema_1 = require("./misc/UniversalSchema");
Object.defineProperty(exports, "annotationOutputTypes", { enumerable: true, get: function () { return UniversalSchema_1.annotationOutputTypes; } });
Object.defineProperty(exports, "annotationTypeFields", { enumerable: true, get: function () { return UniversalSchema_1.annotationTypeFields; } });
Object.defineProperty(exports, "annotationModelFieldUiFields", { enumerable: true, get: function () { return UniversalSchema_1.annotationModelFieldUiFields; } });
Object.defineProperty(exports, "classificationModelFieldUiFields", { enumerable: true, get: function () { return UniversalSchema_1.classificationModelFieldUiFields; } });
Object.defineProperty(exports, "databaseMatchingUiFields", { enumerable: true, get: function () { return UniversalSchema_1.databaseMatchingUiFields; } });
Object.defineProperty(exports, "outputTypeFields", { enumerable: true, get: function () { return UniversalSchema_1.outputTypeFields; } });
Object.defineProperty(exports, "createTextSchemaItem", { enumerable: true, get: function () { return UniversalSchema_1.createTextSchemaItem; } });
Object.defineProperty(exports, "findUniversalSchemaTypeByField", { enumerable: true, get: function () { return UniversalSchema_1.findUniversalSchemaTypeByField; } });
Object.defineProperty(exports, "modernizeSchema", { enumerable: true, get: function () { return UniversalSchema_1.modernizeSchema; } });
Object.defineProperty(exports, "taskInputModernizeOptions", { enumerable: true, get: function () { return UniversalSchema_1.taskInputModernizeOptions; } });
Object.defineProperty(exports, "taskOutputModernizeOptions", { enumerable: true, get: function () { return UniversalSchema_1.taskOutputModernizeOptions; } });
Object.defineProperty(exports, "isUploadableType", { enumerable: true, get: function () { return UniversalSchema_1.isUploadableType; } });
Object.defineProperty(exports, "isSimpleType", { enumerable: true, get: function () { return UniversalSchema_1.isSimpleType; } });
var JSONSchema7_1 = require("./misc/JSONSchema7");
Object.defineProperty(exports, "isJSONSchema7", { enumerable: true, get: function () { return JSONSchema7_1.isJSONSchema7; } });
var VirtuallyStructured_1 = require("./misc/VirtuallyStructured");
Object.defineProperty(exports, "isVirtuallyStructuredSchema", { enumerable: true, get: function () { return VirtuallyStructured_1.isVirtuallyStructuredSchema; } });
var SchemaForm_1 = require("./misc/SchemaForm");
Object.defineProperty(exports, "isSchemaForm", { enumerable: true, get: function () { return SchemaForm_1.isSchemaForm; } });
Object.defineProperty(exports, "convertSchemaItemToSchemaForm", { enumerable: true, get: function () { return SchemaForm_1.convertSchemaItemToSchemaForm; } });
Object.defineProperty(exports, "extractDataFromSchemaItem", { enumerable: true, get: function () { return SchemaForm_1.extractDataFromSchemaItem; } });
Object.defineProperty(exports, "convertToSchemaItemsResponse", { enumerable: true, get: function () { return SchemaForm_1.convertToSchemaItemsResponse; } });
var Structured_1 = require("./misc/Structured");
Object.defineProperty(exports, "fromPrimitiveValue", { enumerable: true, get: function () { return Structured_1.fromPrimitiveValue; } });
Object.defineProperty(exports, "toPrimitiveValue", { enumerable: true, get: function () { return Structured_1.toPrimitiveValue; } });
Object.defineProperty(exports, "isValueValid", { enumerable: true, get: function () { return Structured_1.isValueValid; } });
Object.defineProperty(exports, "toReadableText", { enumerable: true, get: function () { return Structured_1.toReadableText; } });
var MultipleType_1 = require("./misc/MultipleType");
Object.defineProperty(exports, "isMultipleTypeSchema", { enumerable: true, get: function () { return MultipleType_1.isMultipleTypeSchema; } });
Object.defineProperty(exports, "isMultipleTypeObject", { enumerable: true, get: function () { return MultipleType_1.isMultipleTypeObject; } });
