import React, { ReactNode } from "react"

const appName = "Crowd Hero"
const workersName = "Crowd Heroes"
const labelerName = "Hero"

const joinOneSpace = " "

const userAgreement = "User Agreement"
const privacy = "Privacy Policy"
export const dateformat = "YYYY-MM-DD"

export const appInfo = {
    name: appName,
    url: "https://crowd-hero.super.ai"
}

export const notifications: {
    passwordNotTheSameMessage: string
    noErrorMessage: string
    passwordResetSuccessfulMessage: string
    passwordResetFailedMessage: string
    codeResentMessage: string
} = {
    passwordNotTheSameMessage: "Sorry your passwords do not match.",
    noErrorMessage: ["Something went wrong.", "Please contact us!"].join(
        joinOneSpace
    ),
    passwordResetSuccessfulMessage: [
        "Password reset was successful.",
        "Bringing you back to the login."
    ].join(joinOneSpace),
    passwordResetFailedMessage: [
        "Sorry we were unable to reset your password.",
        "Please contact us."
    ].join(joinOneSpace),
    codeResentMessage: "Code was sent again!"
}

export const successMessages = {
    missionsTile: {
        started: [
            "Successfully activated the training.",
            "Going to the tasks page.",
            "You should receive the training any moment."
        ].join(joinOneSpace),
        cancelled: "Successfully deactived the training."
    },
    missions: {
        congratulations: [
            "Congratulations.",
            `You are now a ${labelerName.toLowerCase()}!`
        ].join(joinOneSpace)
    },
    profile: {
        refreshed: "Data refreshed.",
        updated: "Your data was updated."
    }
}

export const warningMessages = {
    task: {
        invalid: [
            "The data provided by you is not enough for submitting.",
            "Please fill out and resolve all the tasks and fields."
        ].join(joinOneSpace)
    }
}

export const errorMessages = {
    logout: "Logging out failed. Something went wrong",
    standard: "Something went wrong.",
    feedbackSubmission:
        "Submission failed. Please check your internet connection"
}

export const pages = {
    tasks: {
        waitForTasks: "Waiting for a new task ...",
        jobId: "job id:",
        taskId: "task id:",
        inactive: [
            "You are currently inactive.",
            "Set yourself to active on the top right corner to become eligible to receiving tasks."
        ].join(joinOneSpace),
        error409: [
            "This account has been logged into from another browser.",
            "Missions command can only be used in one browser at a time."
        ].join(joinOneSpace),
        modalButton: "Back to missions"
    },
    task: {
        submit: "Submit",
        skip: "Skip",
        feedback: "Feedback"
    },
    missions: {
        trainingHeader: "Trainings",
        completedHeader: "Completed",
        trainingSubheader: [
            "Complete these trainings to qualify for more tasks.",
            "The higher you score, the more tasks you'll receive."
        ].join(joinOneSpace),
        noCompletedSubheader: [
            "You didn't complete any training yet.",
            "You receive more tasks if you complete trainings."
        ].join(joinOneSpace),
        completedSubheader: [
            "You're now qualified to receive tasks based on your training score.",
            "You can retry trainings at any time to try to achieve a higher score."
        ].join(joinOneSpace),
        noTrainingSubheader: [
            "Congratulations",
            "You completed every available training for you.",
            "By increasing your score you can achieve even more tasks."
        ].join(joinOneSpace),
        agreementLogout: "Logout",
        agreementAgree: "Agree",
        missionDescription: "Complete tasks and get rewarded with $$$."
    },
    profile: {
        labelNotifications: "Receive notifications",
        labelActive: "Set Active",
        refresh: "Refresh",
        save: "Save",
        genderLabel: "Gender",
        validation: {
            invalidEmail: "Please use the standard email format",
            invalidDob: `Please use this format ${dateformat}`
        }
    },
    earnings: {
        refresh: "Refresh"
    },
    register: {
        header: "We have sent a confirmation link to your email address:",
        confirmation: [
            "In order to complete the **sign-up** process, please click the confirmation link.",
            "If you do not receive a confirmation email, please check your spam folder."
        ].join(joinOneSpace),
        questionForResending: "Can't find your code?",
        questionForHavingAlreadyAccount: "Already have an account?"
    },
    layout: {
        pageTitle: `${appName}`
    },
    bonus: {
        title: `${appName} Bonus Program`,
        description: [
            `Earn a bonus up to $30! Be one of the top 15 ${workersName} in number of tasks`,
            "completed in a month and earn one of the bonuses below."
        ].join(joinOneSpace),
        tiersTitle: "Bonus tiers by rank",
        finePrint: [
            `Minimum quality score required and ${labelerName} must not have been banned or disqualified.`,
            "Bonus is calculated after end of calendar month and will be paid out with your",
            "monthly earnings. Bonus structure and payouts subject to change. We reserve the",
            `right to withold bonuses from ${labelerName}es that abuse the system.`
        ].join(joinOneSpace)
    }
}

export const componentsText = {
    input: {
        video: {
            notPlayableMessage: "Your browser does not support playing videos.",
            downloadMessage:
                "If you have problem playing the video, please download and watch from here."
        },
        fallback: (schemaType: string = "No type provided.") =>
            [
                `Note: **${schemaType}** is not yet fully supported by the UI.`,
                "We are showing JSON data directly."
            ].join(joinOneSpace)
    },
    nav: {
        toggle: {
            active: "Active",
            inactive: "Inactive"
        },
        list: {
            earnings: "Earnings",
            feedback: "Feedback",
            feedbackDesc: [`Let us know what you think about ${appName}`],
            home: "Missions",
            logout: "Logout",
            previousCHLabel: "Revert to previous version",
            privacy,
            profile: "Profile",
            toggleDarkmode: "Toggle dark mode",
            userAgreement
        },
        taskIndicator: "Task in progress",
        abandon: "Abandon",
        keepWorking: "Keep working"
    },
    telegram: {
        link: "Add",
        text: (Add: ReactNode) => (
            <>
                It looks like we don't have your Telegram.
                {Add}
                your Telegram to your profile so we can notify you when there
                are new tasks to complete.
            </>
        )
    },
    missionsTile: {
        trainingStart: "Start",
        trainingCancel: "Cancel",
        resumeButton: "Resume"
    },
    workerfeedback: {
        label: "Feedback",
        multipleHeading: "Select any applicable options",
        textHeading:
            "Please provide any details you think can help improve this task."
    },
    taskcountdown: "Time remaining: ",
    workerPerformance: {
        title: "Your stats for the past week",
        leaderboardText: (moreTasks: number, percentile?: number) =>
            [
                `${
                    percentile
                        ? `You're in the top ${percentile}% of ${workersName}!`
                        : `Complete more tasks to earn rewards and bonuses.`
                }`,
                `${moreTasks} more to earn the top spot.`
            ].join(joinOneSpace),
        completedTasks: (completed: number) => `Tasks completed: ${completed}`,
        skippedTasks: (amount: number) => `Tasks skipped: ${amount}`,
        availableTasks: (amount: number) =>
            `More than ${amount} tasks available`
    },
    qualifications: {
        cta: "qualify for more tasks",
        intro: {
            question: "Not getting enough tasks?",
            explanation:
                "Complete new trainings or score higher on existing trainings to get more tasks.",
            doneTrainings: (
                qualifications: number,
                possibleQualifications: number
            ) =>
                `You've completed ${qualifications} out of ${possibleQualifications} trainings.`
        },
        header: {
            qualifications: "Qualifications",
            score: "Score"
        }
    },
    bonusprogram: {
        title: "Bonus program - Earn up to $30 extra",
        description: [
            "Earn bonuses by completing more tasks with high accuracy.",
            "Click for details!"
        ].join(joinOneSpace),
        newBanner: "New!"
    },
    taskSkipModal: {
        title: "Reason for being unable to complete task",
        placeholder: [
            "Please describe why you're unable to complete the task.",
            "Include any information that may help us resolve the issue."
        ].join(joinOneSpace),
        requiredText: "The reason for skipping the task is required.",
        skip: "Skip",
        resume: "Resume"
    },
    deadMansSwitch: {
        titleCountdown: "Still there?",
        ackCountdown: "I'm still here",
        description:
            "You’ve been quiet for a while. If you do nothing, you’ll lose this task in "
    },
    modal: {
        connectionLost: {
            title: "We lost connectivity",
            description:
                "Tasks expire if you have connectivity problems for too long",
            ackButton: "OK"
        },
        timeout: {
            title: "Time ran out",
            description:
                "Labeling tasks expire after you have been inactive for 5 mins. You've lost your saved draft",
            ackButton: "OK"
        }
    }
}

export const atoms = {
    subheader: {
        bonus: "Bonus Program",
        loginSubheader: "super.AI Inc",
        registerSubheader: "create new user",
        forgotPasswordSubheader: "forgot password",
        eulaSubheader: "user agreement",
        privacySubheader: "privacy",
        mission: "missions home",
        earnings: "earnings",
        profile: "profile",
        tasks: "tasks",
        trainingTasks: "training tasks"
    },
    buttons: {
        login: "Login",
        next: "Next",
        signUp: "Sign Up",
        resetPassword: "Reset Password",
        resendCode: "Resend Code"
    },
    labels: {
        email: "Email:",
        password: "Password:",
        passwordConfirmation: "Confirm Password:",
        confirmationCode: "Verification Code:"
    },
    placeholder: {
        email: "email...",
        password: "password...",
        confirmationCode: "verification code...",
        emailRegister: "john@super.ai",
        passwordRegister: "at least 8 characters",
        passwordConfirmationRegister: "at least 8 characters"
    },
    links: {
        login: "Login",
        register: "Register",
        resendCode: "Resend Code",
        signUp: "Sign Up",
        forgotPassword: "Forgot Password",
        userAgreement,
        privacy
    }
}
