import React, {
    ChangeEvent,
    FC,
    KeyboardEvent,
    MouseEvent,
    useState
} from "react"
import { useDispatch } from "react-redux"
import { confirmResetPassword, startResetting } from "../../actions/AuthActions"
import { Alert } from "../../components/atoms/Alert"
import { LoginButtonProps } from "../../components/atoms/LoginButton"
import { InputProps } from "../../components/atoms/Input"
import { Link } from "../../components/atoms/Link"
import { renderMultipleInputsAndButton } from "../../helpers/ui"
import { useAppState } from "../../hooks/useReduxState"
import { MainThunkDispatch } from "../../reducers"
import { MessageSentiment } from "../../reducers/client/MessagesReducer"
import { routePaths } from "../../routes"
import { atoms } from "../../texts"

interface ResettingState {
    email: string
    confirmationCode: string
    password: string
    confirmationPassword: string
}

export const ForgotPassword: FC = () => {
    const dispatch = useDispatch<MainThunkDispatch>()
    const confirmationMode = useAppState(state => state.auth.confirmationMode)
    const message = useAppState(state => state.client.messages.current)

    const [formState, setFormState] = useState<ResettingState>({
        email: "",
        password: "",
        confirmationPassword: "",
        confirmationCode: ""
    })

    const emailInput = "email"
    const confirmationCode = "confirmation-code"
    const passwordInput = "password"
    const passwordConfirmationInput = "password-confirmation"

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { id, value } = event.target
        switch (id) {
            case emailInput:
                setFormState({ ...formState, email: value.toLowerCase() })
                break
            case passwordInput:
                setFormState({ ...formState, password: value })
                break
            case passwordConfirmationInput:
                setFormState({ ...formState, confirmationPassword: value })
                break
            case confirmationCode:
                setFormState({ ...formState, confirmationCode: value })
                break

            default:
                console.error("Unknown input id!")
                break
        }
    }

    const handleClick = async (
        event: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>
    ) => {
        event.preventDefault()
        if (confirmationMode) {
            await dispatch(
                confirmResetPassword({
                    email: formState.email,
                    password: formState.password,
                    passwordConfirmation: formState.confirmationPassword,
                    confirmationCode: formState.confirmationCode
                })
            )
        } else {
            await dispatch(startResetting({ email: formState.email }))
        }
    }

    const renderFirstStep = (): {
        inputs: InputProps[]
        button: LoginButtonProps
    } => ({
        inputs: [
            {
                label: atoms.labels.email,
                placeholder: atoms.placeholder.email,
                name: emailInput,
                required: true,
                onChange: handleChange,
                value: formState.email
            }
        ],
        button: { label: atoms.buttons.next, onClick: handleClick }
    })
    const confirmationStep = (): {
        inputs: InputProps[]
        button: LoginButtonProps
    } => ({
        inputs: [
            {
                label: atoms.labels.confirmationCode,
                placeholder: atoms.placeholder.confirmationCode,
                name: confirmationCode,
                required: true,
                onChange: handleChange,
                value: formState.confirmationCode
            },
            {
                label: atoms.labels.password,
                placeholder: atoms.placeholder.passwordRegister,
                name: passwordInput,
                type: passwordInput,
                required: true,
                onChange: handleChange,
                value: formState.password
            },
            {
                label: atoms.labels.passwordConfirmation,
                placeholder: atoms.placeholder.passwordConfirmationRegister,
                name: passwordConfirmationInput,
                type: passwordInput,
                required: true,
                onChange: handleChange,
                value: formState.confirmationPassword
            }
        ],
        button: { label: atoms.buttons.resetPassword, onClick: handleClick }
    })

    return (
        <div className="min-w-login max-w-login mx-auto flex flex-col items-center ">
            {message?.text && (
                <Alert
                    success={message.type === MessageSentiment.good}
                    message={message.text}
                />
            )}
            {confirmationMode
                ? renderMultipleInputsAndButton(confirmationStep())
                : renderMultipleInputsAndButton(renderFirstStep())}

            <hr className="w-full" />
            <Link to={routePaths.login()}>{atoms.links.login}</Link>
        </div>
    )
}
