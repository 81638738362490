import { combineReducers } from "redux"
import { TaskActions, TaskReducer, TaskState } from "./TaskReducer"
import {
    TrainingActions,
    TrainingReducer,
    TrainingState
} from "./TrainingsReducer"

export const ApiReducer = combineReducers({
    training: TrainingReducer,
    task: TaskReducer
})

export interface ApiState {
    training: TrainingState
    task: TaskState
}

export type ApiAction = TrainingActions | TaskActions
