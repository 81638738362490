import { Training } from "../../models/Training"
import { Worker } from "../../models/Worker"
import { MainThunkDispatch, State } from "../../reducers"
import { apiEndpoints, axios } from "../../routes/api"
import { getValidSession } from "../AuthActions"
import { TrainingsType } from "../Types"
import { Severity } from "@sentry/react"
import { reportError } from "../tracking/MonitoringActions"
import { resetTaskIds } from "./TaskActions"
import { AxiosError } from "axios"

export const getTrainings =
    ({ worker }: { worker: Worker }) =>
    async (dispatch: MainThunkDispatch): Promise<void> => {
        const token = await dispatch(getValidSession())

        try {
            const apiEndpoint = apiEndpoints.getTrainings({
                workerUuid: worker.uuid
            })

            const response = await axios({
                service: apiEndpoint.service
            }).get<Training[]>(apiEndpoint.url, {
                headers: {
                    Authorization: token
                }
            })

            dispatch({
                type: TrainingsType.getTrainings,
                payload: response.data
            })
        } catch (error) {
            reportError({ error })
            throw error
        }
    }

export const startTraining =
    ({ trainingId }: { trainingId: number }) =>
    async (dispatch: MainThunkDispatch, getState: () => State) => {
        const workerUuid = getState().auth.worker?.uuid
        const token = await dispatch(getValidSession())
        if (workerUuid) {
            dispatch(toggleLoading(trainingId))
            try {
                const { service, url } = apiEndpoints.startTraining({
                    workerUuid,
                    trainingId
                })
                const { data } = await axios({ service }).put<{
                    conversation?: number
                    trainingId?: string
                }>(
                    url,
                    {},
                    {
                        headers: {
                            Authorization: token
                        }
                    }
                )
                dispatch({
                    type: TrainingsType.startTraining,
                    payload: trainingId
                })
                dispatch(toggleLoading())
                return data
            } catch (error) {
                dispatch(toggleLoading())
                dispatch(
                    reportError({
                        error,
                        level: Severity.Error,
                        extraData: {
                            trainingId,
                            start: true
                        }
                    })
                )
                const axiosError = error as AxiosError
                if (axiosError?.response?.data?.message) {
                    throw new Error(axiosError.response.data.message)
                }
                throw error
            }
        }
    }

export const cancelTraining =
    ({ trainingId }: { trainingId: number }) =>
    async (dispatch: MainThunkDispatch, getState: () => State) => {
        const workerUuid = getState().auth.worker?.uuid
        const token = await dispatch(getValidSession())

        if (workerUuid) {
            dispatch(toggleLoading(trainingId))
            try {
                const { service, url } = apiEndpoints.cancelTraining({
                    workerUuid,
                    trainingId
                })
                await axios({ service }).delete(url, {
                    headers: {
                        Authorization: token
                    }
                })
                dispatch({
                    type: TrainingsType.cancelTraining,
                    payload: trainingId
                })
                dispatch(resetTaskIds())
                dispatch(toggleLoading())
            } catch (error) {
                dispatch(toggleLoading())
                dispatch(
                    reportError({
                        error,
                        level: Severity.Error,
                        extraData: {
                            trainingId,
                            cancel: true
                        }
                    })
                )
                throw error
            }
        }
    }

export const markTrainingComplete =
    ({ trainingId, sequence }: { trainingId: string; sequence: number }) =>
    async (dispatch: MainThunkDispatch, getState: () => State) => {
        const workerUuid = getState().auth.worker?.uuid
        const token = await dispatch(getValidSession())

        if (workerUuid) {
            try {
                const { service, url } = apiEndpoints.postTrainingComplete({
                    workerUuid,
                    trainingId,
                    sequence
                })
                await axios({ service }).post(
                    url,
                    {},
                    {
                        headers: {
                            Authorization: token
                        }
                    }
                )

                dispatch(resetTaskIds())
            } catch (error) {
                dispatch(
                    reportError({
                        error,
                        level: Severity.Error,
                        extraData: {
                            trainingId
                        }
                    })
                )
                throw error
            }
        }
    }

const toggleLoading =
    (trainingId: number = -1) =>
    (dispatch: MainThunkDispatch): void => {
        dispatch({
            type: TrainingsType.toggleLoading,
            payload: trainingId
        })
    }
