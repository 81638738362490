import { useState, useCallback, SetStateAction, Dispatch } from "react"

export function useBoolean(initialValue: boolean): [
    boolean,
    {
        setToTrue: () => void
        setToFalse: () => void
        toggle: () => void
        setValue: Dispatch<SetStateAction<boolean>>
    }
] {
    const [value, setValue] = useState(initialValue)
    const setToTrue = useCallback(() => setValue(true), [setValue])
    const setToFalse = useCallback(() => setValue(false), [setValue])
    const toggle = useCallback(
        () => setValue(prevValue => !prevValue),
        [setValue]
    )

    return [value, { setToTrue, setToFalse, toggle, setValue }]
}
