import React, { FC, MouseEvent, KeyboardEvent } from "react"
import { CircularProgress } from "dash-styled"

export interface LoginButtonProps {
    label: string
    onClick: (
        event: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>
    ) => void
    type?: "button" | "submit" | "reset"
    disabled?: boolean
}

export const LoginButton: FC<LoginButtonProps> = ({
    type,
    onClick,
    label,
    disabled
}) => {
    return (
        <button
            type={type}
            disabled={disabled}
            className="w-full px-3 py-2 border rounded bg-neon-gradient-400 text-white transition-bg-250"
            onClick={onClick}
        >
            {disabled ? (
                <CircularProgress className="text-white w-5 h-5" />
            ) : (
                label
            )}
        </button>
    )
}
