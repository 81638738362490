import React, { FC } from "react"
import symbolSm from "../assets/superai_symbol_sm.png"

interface CompanyLogoProps {
    className?: string
}

export const CompanyLogo: FC<CompanyLogoProps> = ({ className }) => {
    return (
        <img
            className={`${className}`}
            style={{ filter: "drop-shadow(0 0 0.3rem #cccfdb)" }}
            src={symbolSm}
            alt="super.AI Logo"
            loading="lazy"
        />
    )
}
