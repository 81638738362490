import { captureException, configureScope, Severity } from "@sentry/react"
import { MainThunkDispatch, State } from "../../reducers"

export const reportError =
    ({
        error,
        level = Severity.Warning,
        extraData
    }: {
        error: unknown
        level?: Severity
        extraData?: {
            [key: string]: any
        }
    }) =>
    (dispatch: MainThunkDispatch, getState: () => State) => {
        const {
            auth: { worker },
            api: { task }
        } = getState()
        if (window.navigator.onLine) {
            configureScope(scope => {
                if (extraData) {
                    scope.setExtras(extraData)
                }

                scope.setUser({ email: worker?.email })
                scope.setTag("superai.taskID", task.id)
                scope.setLevel(level)
                if (error instanceof Error) {
                    captureException(error)
                } else if (typeof error === "string") {
                    console.error(error)
                    captureException(new Error(error))
                } else {
                    console.error(error)
                    captureException(error)
                }
            })
        }
    }
