import { push } from "connected-react-router"
import React, { FC, useEffect, useCallback, useMemo, RefObject } from "react"
import {
    changeSearchingStatus,
    resetTaskIds
} from "../../actions/api/TaskActions"
import { useAppDispatch } from "../../hooks/useReduxState"
import { Worker } from "../../models/Worker"
import { routePaths } from "../../routes"
import { TaskApp, ExampleRenderResponse } from "resha"
import { DeadManCountdown } from "../../components/DeadManCountdown/DeadManCountdown"
import { useUrlSigner } from "../../hooks/useUrlSigner"
import { ToastStatus, useToast } from "../../hooks/useToast"

export interface TrainingExampleProps {
    example: ExampleRenderResponse
    worker: Worker
    trainingId: string
    toolbarRef: RefObject<HTMLDivElement>
}

export const TrainingExample: FC<TrainingExampleProps> = ({
    example,
    worker,
    trainingId,
    toolbarRef
}) => {
    const showErrorMessage = useToast(ToastStatus.ERROR)
    const dispatch = useAppDispatch()
    const data = useMemo(
        () => ({ type: "example" as const, example }),
        [example]
    )
    const handleTaskSubmit = useCallback(async () => {
        dispatch(
            push(routePaths.trainingTasks(trainingId), {
                sequence: example.sequence
            })
        )
    }, [dispatch, example, trainingId])
    const handleSignUrl = useUrlSigner(worker, undefined, example)

    useEffect(() => {
        return () => {
            dispatch(resetTaskIds())
            dispatch(changeSearchingStatus(false))
        }
    }, [dispatch])

    return (
        <DeadManCountdown worker={worker}>
            <TaskApp
                toolbarRef={toolbarRef}
                allowSkip={false}
                shouldPollPrelabels={false}
                data={data}
                urlSigner={handleSignUrl}
                onTaskResolve={handleTaskSubmit}
                onError={showErrorMessage}
            />
        </DeadManCountdown>
    )
}
