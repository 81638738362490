const TWOMINUTES = 120 * 1000

export const sleep = async (time: number) =>
    await new Promise(resolve => window.setTimeout(resolve, time))

export const increaseTimeout = (timeout: number) => {
    if (timeout === 0) {
        return 500
    }
    if (timeout > 0 && timeout * 2 < TWOMINUTES) {
        return timeout * 2
    }
    return TWOMINUTES
}

export const withExponentialBackoff = () => {
    let time = 0
    return async (fn: () => void | Promise<void>) => {
        time = increaseTimeout(time)
        await fn()
        await sleep(time)
    }
}
