import { push } from "connected-react-router"
import React, { FC } from "react"
import { useAppDispatch } from "../../hooks/useReduxState"
import { routePaths } from "../../routes"
import { componentsText } from "../../texts"
import { CardFrame } from "../atoms/CardFrame"
import { customRounding } from "../../helpers/ui"

interface AvailableTasksBannerProps {
    availableTasks: number
}

export const AvailableTasksBanner: FC<AvailableTasksBannerProps> = ({
    availableTasks
}) => {
    const dispatch = useAppDispatch()

    const handleClick = () => {
        dispatch(push(routePaths.tasks()))
    }

    return (
        <CardFrame onClick={handleClick}>
            <div className="font-medium text-lg self-start flex items-center">
                <span
                    className="mr-2 text-lg md:text-xl"
                    role="img"
                    aria-label="memo emoji"
                >
                    📝
                </span>
                <h2 className="md:text-lg mb-0 ">
                    {componentsText.workerPerformance.availableTasks(
                        customRounding(availableTasks)
                    )}
                </h2>
            </div>
        </CardFrame>
    )
}
