"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UniversalSchemaType = void 0;
var UniversalSchemaType;
(function (UniversalSchemaType) {
    UniversalSchemaType["BINARY_CHOICE"] = "binary-choice";
    UniversalSchemaType["BOUNDING_BOX"] = "bounding-box";
    UniversalSchemaType["CAMERA_INTRINSICS"] = "camera-intrinsics";
    UniversalSchemaType["CHOICE"] = "choice";
    UniversalSchemaType["CIRCLE"] = "circle";
    UniversalSchemaType["COLOR"] = "color";
    UniversalSchemaType["CUBOID"] = "cuboid";
    UniversalSchemaType["DIRECTED_EDGE"] = "directed-edge";
    UniversalSchemaType["DIRECTED_POLYGON"] = "directed-polygon";
    UniversalSchemaType["DIRECTION"] = "direction";
    UniversalSchemaType["EDGE"] = "edge";
    UniversalSchemaType["ELLIPSE"] = "ellipse";
    UniversalSchemaType["EXCLUSIVE_CHOICE"] = "exclusive-choice";
    UniversalSchemaType["IMAGE_BOUNDING_BOX"] = "image-bounding-box";
    UniversalSchemaType["IMAGE_CIRCLE"] = "image-circle";
    UniversalSchemaType["IMAGE_CUBOID"] = "image-cuboid";
    UniversalSchemaType["IMAGE_DIRECTED_POLYGON"] = "image-directed-polygon";
    UniversalSchemaType["IMAGE_ELLIPSE"] = "image-ellipse";
    UniversalSchemaType["IMAGE_KEYPOINT"] = "image-key-point";
    UniversalSchemaType["IMAGE_LINE"] = "image-line";
    UniversalSchemaType["IMAGE_POINT"] = "image-point";
    UniversalSchemaType["IMAGE_POLYGON"] = "image-polygon";
    UniversalSchemaType["IMAGE"] = "image";
    UniversalSchemaType["IMAGE_SEQUENCE_BOUNDING_BOX"] = "image-sequence-bounding-box";
    UniversalSchemaType["IMAGE_SEQUENCE_CUBOID"] = "image-sequence-cuboid";
    UniversalSchemaType["IMAGE_TRANSCRIPTION"] = "image-transcription";
    UniversalSchemaType["INDEXED_EDGE"] = "indexed-edge";
    UniversalSchemaType["INTEGER"] = "integer";
    UniversalSchemaType["KEYPOINT"] = "key-point";
    UniversalSchemaType["LINE"] = "line";
    UniversalSchemaType["MULTIPLE_CHOICE"] = "multiple-choice";
    UniversalSchemaType["NUMBER"] = "number";
    UniversalSchemaType["POINT"] = "point";
    UniversalSchemaType["POLYGON"] = "polygon";
    UniversalSchemaType["QUATERNION"] = "quaternion";
    UniversalSchemaType["SATELLITE"] = "satellite";
    UniversalSchemaType["STRUCTURED_TEXT"] = "structured-text";
    UniversalSchemaType["TEXT"] = "text";
    UniversalSchemaType["TRANSCRIPTION"] = "transcription";
    UniversalSchemaType["UNIVERSAL"] = "universal";
    UniversalSchemaType["URL"] = "url";
    UniversalSchemaType["VIDEO_CUBOID"] = "video-cuboid";
    UniversalSchemaType["VIDEO"] = "video";
    UniversalSchemaType["IMAGE_COMPARISON_TYPE"] = "image-comparison";
    UniversalSchemaType["STRUCTURED"] = "structured";
    UniversalSchemaType["INSTRUCTIONS"] = "instructions";
    UniversalSchemaType["IMAGE_PRESENTATION"] = "image-presentation";
    UniversalSchemaType["SATELLITE_POLYGON"] = "satellite-polygon";
    UniversalSchemaType["BLOCK"] = "block";
    UniversalSchemaType["CHECKBOX"] = "checkbox";
    UniversalSchemaType["DOCUMENT"] = "document";
})(UniversalSchemaType = exports.UniversalSchemaType || (exports.UniversalSchemaType = {}));
