import { Reducer } from "redux"
import { ModalType } from "../../actions/Types"

export enum ExplanationModalType {
    timeout = "timeout",
    connectionLost = "connectionLost"
}

export interface ModalState {
    showExplanationModal: ExplanationModalType | undefined
}

const initialState: ModalState = {
    showExplanationModal: undefined
}

interface ShowModalAction {
    type: ModalType
}

export type ModalAction = ShowModalAction

export const ModalReducer: Reducer<ModalState, ModalAction> = (
    state = initialState,
    action
): ModalState => {
    switch (action.type) {
        case ModalType.hideExplanationModal:
            return {
                ...state,
                showExplanationModal: undefined
            }
        case ModalType.showConnectionLostModal:
            return {
                ...state,
                showExplanationModal: ExplanationModalType.connectionLost
            }
        case ModalType.showTimeoutModal:
            return {
                ...state,
                showExplanationModal: ExplanationModalType.timeout
            }
        default:
            return state
    }
}
