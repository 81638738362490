import React, { ReactNode } from "react"
import { Nav } from "../components/Nav/Nav"
import { pages } from "../texts"

export interface LayoutProps {
    subheader?: string
    login?: boolean
    noPadding?: boolean
    backLink?: string
    children?: ReactNode
}

export const Layout = React.forwardRef<HTMLDivElement, LayoutProps>(
    ({ subheader, children, login, noPadding, backLink }, toolbarRef) => {
        return (
            <>
                <Nav
                    login={login}
                    title={pages.layout.pageTitle}
                    subtitle={subheader}
                    ref={toolbarRef}
                    backLink={backLink}
                />
                <div
                    className={
                        noPadding ? "" : "px-1 md:px-8 pt-4 flex-1 bg-rhino-100"
                    }
                    style={{
                        ...(noPadding ? { height: "calc(100vh - 4rem)" } : {})
                    }}
                >
                    {children}
                </div>
            </>
        )
    }
)
