export const getMillisecondsUntilExpire = (urlString: string): number => {
    const url = new URL(urlString)
    const expire = parseInt(url.searchParams.get("Expires") || "", 10) * 1000

    return expire - Date.now()
}

export const extractDataUrlPath = (url: string): string | undefined => {
    const matched = url.match(/^data:\/\/(.*)$/)

    return matched ? matched[1] : undefined
}
