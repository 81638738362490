import React, { FC, useCallback } from "react"
import { hideExplanationModal } from "../../actions/ModalActions"
import { useAppDispatch, useAppState } from "../../hooks/useReduxState"
import { Modal } from "../Modal/Modal"
import { componentsText } from "../../texts"
import { Button } from "../atoms/Button"
import { ExplanationModalType } from "../../reducers/client/ModalReducer"

export const ConnectionLostModal: FC = () => {
    const dispatch = useAppDispatch()
    const explanationModal = useAppState(
        state => state.client.modal.showExplanationModal
    )

    const handleClose = useCallback(() => {
        dispatch(hideExplanationModal())
    }, [dispatch])

    return (
        <Modal
            open={explanationModal === ExplanationModalType.connectionLost}
            onClose={handleClose}
            disableBackdropClick={true}
        >
            <h2 className="font-bold md:text-lg text-center mb-0">
                {componentsText.modal.connectionLost.title}
            </h2>
            <hr className="bg-gray-400 w-full my-4" />
            <span className="mb-4 text-center">
                {componentsText.modal.connectionLost.description}
            </span>
            <div className="w-full flex">
                <Button
                    className="w-full bg-primary-lighter hover:bg-primary-darker text-white transition-colors duration-200 ease-in-out capitalize text-center"
                    capitalize={true}
                    onClick={handleClose}
                >
                    {componentsText.modal.connectionLost.ackButton}
                </Button>
            </div>
        </Modal>
    )
}
