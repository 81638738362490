import {
    connectRouter,
    RouterAction,
    RouterState
} from "connected-react-router"
import { History } from "history"
import { combineReducers } from "redux"
import { ThunkAction, ThunkDispatch } from "redux-thunk"
import { AuthAction, AuthReducer, AuthState } from "./auth"
import { ClientAction, ClientReducer, ClientState } from "./client"
import { ApiReducer, ApiState, ApiAction } from "./api"
import { BannerAction } from "./client/BannerReducer"

export type MainAction =
    | RouterAction
    | AuthAction
    | ClientAction
    | ApiAction
    | BannerAction
export type MainThunkResult<R> = ThunkAction<R, State, undefined, MainAction>
export type MainThunkDispatch = ThunkDispatch<State, undefined, MainAction>

export interface State {
    router: RouterState
    auth: AuthState
    client: ClientState
    api: ApiState
}

export const createRootReducer = (history: History) =>
    combineReducers({
        router: connectRouter(history),
        auth: AuthReducer,
        client: ClientReducer,
        api: ApiReducer
    })
