import { push } from "connected-react-router"
import React, {
    FC,
    useEffect,
    useState,
    useCallback,
    useMemo,
    RefObject
} from "react"
import {
    changeSearchingStatus,
    resetTaskIds,
    getFeedbackOptions
} from "../../../actions/api/TaskActions"
import { useAppDispatch } from "../../../hooks/useReduxState"
import { Worker } from "../../../models/Worker"
import { routePaths } from "../../../routes"
import { TasksLocationState } from "../Tasks"
import {
    TaskResponse,
    FeedbackOptionsResponse,
    TaskApp,
    TaskResolveParams
} from "resha"
import { DeadManCountdown } from "../../../components/DeadManCountdown/DeadManCountdown"
import { useUrlSigner } from "../../../hooks/useUrlSigner"
import { useTaskResolve } from "../../../hooks/useTaskResolve"
import { ToastStatus, useToast } from "../../../hooks/useToast"

interface TaskProps {
    task: TaskResponse
    worker: Worker
    conversation?: number
    toolbarRef: RefObject<HTMLDivElement>
}

export const Task: FC<TaskProps> = ({
    task,
    worker,
    conversation,
    toolbarRef
}) => {
    const dispatch = useAppDispatch()

    const showErrorMessage = useToast(ToastStatus.ERROR)

    const [handleTaskResolving] = useTaskResolve(worker, task)

    const [adminFeedback, setAdminFeedback] =
        useState<FeedbackOptionsResponse>()

    const onTaskResolving = useCallback(
        async (params: TaskResolveParams) => {
            if (params.resolveAction === "REJECT") {
                await handleTaskResolving(params)

                dispatch(
                    push<TasksLocationState>(routePaths.tasks(), {
                        conversation
                    })
                )
                dispatch(changeSearchingStatus(true))
                return
            }

            if (params.resolveAction === "STORE") {
                if (task.type !== "TASK") {
                    return
                }

                await handleTaskResolving(params)
                return
            }

            switch (task.type) {
                case "TASK":
                    if (params.output === undefined) {
                        return
                    }

                    await handleTaskResolving(params)
                    dispatch(
                        push<TasksLocationState>(routePaths.tasks(), {
                            conversation
                        })
                    )
                    dispatch(changeSearchingStatus(true))
                    break
                case "END":
                    dispatch(push(routePaths.tasks()))

                    break
                default:
                    break
            }
        },
        [conversation, dispatch, handleTaskResolving, task.type]
    )

    const handleSignUrl = useUrlSigner(worker, task)

    const data = useMemo(() => ({ type: "task" as const, task }), [task])

    useEffect(() => {
        return () => {
            dispatch(resetTaskIds())
            dispatch(changeSearchingStatus(false))
        }
    }, [dispatch])

    useEffect(() => {
        dispatch(
            getFeedbackOptions({ workerUuid: worker.uuid, taskId: task?.id })
        ).then(data => {
            setAdminFeedback(data)
        })
    }, [worker, task, setAdminFeedback, dispatch])

    if (task.id === undefined) {
        return null
    }

    return (
        <DeadManCountdown worker={worker}>
            <TaskApp
                toolbarRef={toolbarRef}
                allowSkip={true}
                shouldPollPrelabels={false}
                data={data}
                appFeedback={adminFeedback}
                urlSigner={handleSignUrl}
                onTaskResolve={onTaskResolving}
                onError={showErrorMessage}
            />
        </DeadManCountdown>
    )
}
