import { push } from "connected-react-router"
import React, {
    ChangeEvent,
    FC,
    KeyboardEvent,
    MouseEvent,
    useState
} from "react"
import { useDispatch } from "react-redux"
import { registerNewUser } from "../../actions/AuthActions"
import { Alert } from "../../components/atoms/Alert"
import { InputProps } from "../../components/atoms/Input"
import { Link } from "../../components/atoms/Link"
import {
    LoginButton,
    LoginButtonProps
} from "../../components/atoms/LoginButton"
import { Markdown } from "dash-styled"
import { renderMultipleInputsAndButton } from "../../helpers/ui"
import { useAppState } from "../../hooks/useReduxState"
import { ToastStatus, useToast } from "../../hooks/useToast"
import { MainThunkDispatch } from "../../reducers"
import { MessageSentiment } from "../../reducers/client/MessagesReducer"
import { routePaths } from "../../routes"
import { atoms, errorMessages, pages } from "../../texts"

interface RegisterState {
    email: string
    password: string
    passwordConfirmation: string
}

export const Register: FC = () => {
    const dispatch = useDispatch<MainThunkDispatch>()
    const message = useAppState(state => state.client.messages.current)
    const errorToast = useToast(ToastStatus.ERROR)
    const [registered, setRegistered] = useState(false)
    const [loading, setLoading] = useState(false)
    const [formState, setFormState] = useState<RegisterState>({
        email: "",
        password: "",
        passwordConfirmation: ""
    })
    const emailInput = "email"
    const passwordInput = "password"
    const passwordConfirmationInput = "password-confirmation"

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { id, value } = event.target
        switch (id) {
            case emailInput:
                setFormState({ ...formState, email: value.toLowerCase() })
                break
            case passwordInput:
                setFormState({ ...formState, password: value })
                break
            case passwordConfirmationInput:
                setFormState({ ...formState, passwordConfirmation: value })
                break

            default:
                console.error("Unknown input id!")
                break
        }
    }

    const handleSignUpClick = async (
        event: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>
    ) => {
        event.preventDefault()
        try {
            setLoading(true)
            const isRegistered = await dispatch(
                registerNewUser({
                    email: formState.email,
                    password: formState.password,
                    passwordConfirmation: formState.passwordConfirmation
                })
            )
            setRegistered(isRegistered)
            setLoading(false)
        } catch (error) {
            errorToast(errorMessages.standard)
            setLoading(false)
        }
    }

    const registerForm: { inputs: InputProps[]; button: LoginButtonProps } = {
        inputs: [
            {
                label: atoms.labels.email,
                placeholder: atoms.placeholder.emailRegister,
                name: emailInput,
                required: true,
                onChange: handleChange,
                value: formState.email
            },

            {
                label: atoms.labels.password,
                placeholder: atoms.placeholder.passwordRegister,
                name: passwordInput,
                type: passwordInput,
                required: true,
                onChange: handleChange,
                value: formState.password
            },

            {
                label: atoms.labels.passwordConfirmation,
                placeholder: atoms.placeholder.passwordConfirmationRegister,
                name: passwordConfirmationInput,
                type: passwordInput,
                required: true,
                onChange: handleChange,
                value: formState.passwordConfirmation
            }
        ],
        button: {
            label: atoms.buttons.signUp,
            type: "submit",
            disabled: loading,
            onClick: handleSignUpClick
        }
    }

    const renderRegisterForm = () => (
        <form className="min-w-login max-w-login mx-auto flex flex-col items-center ">
            {message?.text && (
                <Alert
                    success={message.type === MessageSentiment.good}
                    message={message.text}
                />
            )}
            {renderMultipleInputsAndButton(registerForm)}
            <hr className="w-full" />
            <div className="w-full flex justify-around">
                <span className="mr-2">
                    {pages.register.questionForHavingAlreadyAccount}
                </span>
                <Link to={routePaths.login()}>{atoms.links.login}</Link>
            </div>
        </form>
    )

    const handleLoginClick = () => dispatch(push(routePaths.login()))

    const renderRegisterConfirmation = () => (
        <div className="min-w-login max-w-login mx-auto flex flex-col items-center ">
            <small>{pages.register.header}</small>
            <p className="my-4">
                <small>
                    <strong>{formState.email}</strong>
                </small>
            </p>
            <div className="mb-4">
                <Markdown text={pages.register.confirmation} />
            </div>
            <LoginButton label="Login" onClick={handleLoginClick} />
            <hr className="w-full" />
            <div className="w-full flex justify-around">
                <span className="mr-2">
                    {pages.register.questionForResending}
                </span>
                <Link to={routePaths.resendCode()}>
                    {atoms.links.resendCode}
                </Link>
            </div>
            <hr className="w-full" />
            <div className="w-full flex justify-center">
                <Link to={routePaths.register()}>{atoms.links.register}</Link>
                <Link to={routePaths.login()}>{atoms.links.login}</Link>
            </div>
        </div>
    )

    return registered ? renderRegisterConfirmation() : renderRegisterForm()
}
