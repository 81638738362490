import { Severity } from "@sentry/react"
import { client, getStatsForLeaderBoard } from "neql"
import React, { FC, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { updateWorker } from "../../actions/api/ResourceActions"
import { getTrainings } from "../../actions/api/TrainingActions"
import { getValidSession, signOut } from "../../actions/AuthActions"
import { reportError } from "../../actions/tracking/MonitoringActions"
import missionsImage from "../../components/assets/wordmark-tag.svg"
import { AvailableTasksBanner } from "../../components/AvailableTasksBanner/AvailableTasksBanner"
import { BonusProgram } from "../../components/BonusProgram/BonusProgram"
import { MissionsTile } from "../../components/MissionsTile/MissionsTile"
import { TelegramBanner } from "../../components/TelegramBanner/TelegramBanner"
import { WorkerPerformance } from "../../components/WorkerPerfomance/WorkerPerfomance"
import { getRank, renderTrainingSection } from "../../helpers/ui"
import { useAppState } from "../../hooks/useReduxState"
import { ToastStatus, useToast } from "../../hooks/useToast"
import {
    sortTrainingStatusInProgressFirst,
    sortTrainingTypeNewTrainingFirst,
    TrainingStatus
} from "../../models/Training"
import { MainThunkDispatch } from "../../reducers"
import {
    componentsText,
    errorMessages,
    pages,
    successMessages
} from "../../texts"
import Eula from "../legal/Eula"
import { useMemo } from "react"

const initialState = {
    completed: 0,
    skipped: 0,
    mostActiveHero: 0,
    availableTasks: 0
}

export const Missions: FC = () => {
    const trainings = useAppState(
        reduxState => reduxState.api.training.trainings
    )
    const worker = useAppState(reduxState => reduxState.auth.worker)
    const dispatch = useDispatch<MainThunkDispatch>()
    const successToast = useToast(ToastStatus.SUCCESS)
    const errorToast = useToast(ToastStatus.ERROR)
    const completedTrainings = useMemo(
        () =>
            trainings
                ?.filter(({ status }) => status === TrainingStatus.COMPLETED)
                .sort(sortTrainingTypeNewTrainingFirst),
        [trainings]
    )

    const trainingsToDo = useMemo(
        () =>
            trainings
                ?.filter(({ status }) => status !== TrainingStatus.COMPLETED)
                .sort(sortTrainingTypeNewTrainingFirst)
                .sort(sortTrainingStatusInProgressFirst),
        [trainings]
    )

    const [state, setState] =
        useState<{
            completed: number
            skipped: number
            leaderboard?: number
            mostActiveHero: number
            availableTasks: number
        }>(initialState)

    const {
        trainingHeader,
        noTrainingSubheader,
        completedHeader,
        completedSubheader,
        noCompletedSubheader,
        trainingSubheader,
        agreementAgree,
        agreementLogout,
        missionDescription
    } = pages.missions
    const {
        missions: { congratulations }
    } = successMessages
    const { standard, logout: errorLogout } = errorMessages

    useEffect(() => {
        if (worker) {
            const fetchTrainings = async () => {
                await dispatch(getTrainings({ worker }))
            }

            fetchTrainings()
        }
    }, [dispatch, errorToast, worker])

    const handleLogout = async () => {
        try {
            await dispatch(signOut())
        } catch (error) {
            errorToast(errorLogout)
        }
    }
    const handleAgreeing = async () => {
        if (worker) {
            try {
                await dispatch(
                    updateWorker({
                        workerOptions: {
                            ...worker,
                            agreed: new Date().toISOString()
                        }
                    })
                )
                successToast(congratulations)
            } catch (error) {
                errorToast(standard)
            }
        }
    }

    useEffect(() => {
        const getTasks = async (): Promise<void> => {
            if (worker) {
                try {
                    const token = await dispatch(getValidSession())
                    const { completed, skipped, leaderboard, availableTasks } =
                        await getStatsForLeaderBoard(client(token), worker.uuid)
                    setState({
                        completed,
                        skipped,
                        leaderboard: getRank(completed, leaderboard),
                        mostActiveHero: leaderboard.mostActiveHero,
                        availableTasks
                    })
                } catch (error) {
                    dispatch(reportError({ error, level: Severity.Warning }))
                    setState(initialState)
                }
            }
        }

        getTasks()
    }, [dispatch, worker])

    const renderAgreementModal = () => (
        <>
            <div className="fixed inset-0 bg-black bg-opacity-87 opacity-50 z-60" />
            <div className="fixed inset-0 z-70 mx-3 my-6 md:mx-16 md:my-14 px-2 py-1 rounded bg-white shadow-lg border overflow-scroll">
                <Eula />
                <div className="flex justify-end items-center py-8">
                    <button
                        className="px-4 py-2 mr-6 text-base border rounded text-black text-opacity-87 transition-colors duration-200 ease-in-out hover:bg-gray-200"
                        onClick={handleLogout}
                    >
                        {agreementLogout}
                    </button>
                    <button
                        className="px-4 py-2 mr-6 text-base border rounded text-black text-opacity-87 transition-colors duration-200 ease-in-out hover:bg-gray-200"
                        onClick={handleAgreeing}
                    >
                        {agreementAgree}
                    </button>
                </div>
            </div>
        </>
    )

    return (
        <>
            {worker && !!!worker.agreed && renderAgreementModal()}

            {worker && !worker?.telegram && <TelegramBanner />}
            <div className="flex flex-col md:flex-row md:mb-16">
                <div className="mb-4 md:mb-0 md:w-1/2 flex md:justify-start">
                    <div className="w-full md:w-10/12 xl:w-2/3">
                        <MissionsTile
                            title="Missions Command"
                            subheader="super.AI Missions"
                            image={missionsImage}
                            description={missionDescription}
                        />
                    </div>
                </div>
                <div className="md:w-1/2 flex flex-col">
                    <BonusProgram
                        title={componentsText.bonusprogram.title}
                        description={componentsText.bonusprogram.description}
                        newBanner={componentsText.bonusprogram.newBanner}
                    />
                    {state.availableTasks >= 200 && (
                        <AvailableTasksBanner
                            availableTasks={state.availableTasks}
                        />
                    )}
                    <WorkerPerformance
                        completed={state.completed}
                        mostActiveHero={state.mostActiveHero}
                        leaderboard={state.leaderboard}
                        skipped={state.skipped}
                    />
                </div>
            </div>

            {renderTrainingSection({
                trainings: trainingsToDo,
                header: trainingHeader,
                subheader: trainingSubheader,
                altSubheader: noTrainingSubheader
            })}
            {renderTrainingSection({
                trainings: completedTrainings,
                header: completedHeader,
                subheader: completedSubheader,
                altSubheader: noCompletedSubheader
            })}
        </>
    )
}
