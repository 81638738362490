import React, { FC, ReactNode } from "react"
import { Link } from "../atoms/Link"
import { routePaths } from "../../routes"
import { componentsText } from "../../texts"

interface TelegramBannerProps {
    telegram?: string
}

export const TelegramBanner: FC<TelegramBannerProps> = ({ telegram }) => {
    const addlink: ReactNode = (
        <Link
            className="ml-1 mr-1 text-rhino-900 font-semibold"
            to={routePaths.profile()}
        >
            {componentsText.telegram.link}
        </Link>
    )

    return (
        <div
            className={`rounded-md bg-rhino-250 text-rhino-800 flex items-start shadow  px-2 md:px-6 py-1 md:py-4 mb-4 md:mb-8`}
        >
            {componentsText.telegram.text(addlink)}
        </div>
    )
}
