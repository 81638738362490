import React, { FC } from "react"

interface CardFrameProps {
    show?: boolean
    justify?: string
    className?: string
    onClick?: (
        event: Event | React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => void
    active?: boolean
}

export const CardFrame: FC<CardFrameProps> = ({
    children,
    show,
    justify = "items-center",
    className,
    onClick,
    active
}) => {
    return (
        <div
            className={`flex flex-col ${justify} bg-white cursor-default p-2 md:p-4 mb-4 md:mb-6 border rounded-lg transition-all ease-in-out hover:shadow-xl duration-500 ease-in-out
                ${show ?? true ? "opacity-100" : "opacity-0"} ${
                active ? "border-black border-opacity-87" : ""
            } ${onClick ? "cursor-pointer" : ""} ${className}`}
            onClick={onClick}
        >
            {children}
        </div>
    )
}
