import React, { FC } from "react"
import { Redirect } from "react-router"
import { useAppState } from "../hooks/useReduxState"
import { routePaths } from "../routes"

export const Home: FC = () => {
    const isSignedIn = useAppState(state => state.auth.isSignedIn)

    if (isSignedIn) {
        return <Redirect to={routePaths.missions()} />
    }
    return <Redirect to={routePaths.login()} />
}
