import { CircularProgress } from "dash-styled"
import React, { FC } from "react"

interface ButtonProps {
    onClick: () => void
    loading?: boolean
    disabled?: boolean
    capitalize?: boolean
    className?: string
    loadingStyle?: string
    dataTestid?: string
}

export const Button: FC<ButtonProps> = ({
    onClick,
    loading,
    disabled,
    children,
    className,
    capitalize = true,
    loadingStyle = "text-black text-opacity-87",
    dataTestid
}) => {
    return (
        <button
            data-testid={dataTestid}
            className={`${className} px-3 py-1 flex border rounded text-sm transition-bg duration-200 ease-in-out justify-center ${
                capitalize && "capitalize"
            } ${disabled ? "opacity-50 cursor-not-allowed" : ""}`}
            onClick={onClick}
            disabled={loading || disabled}
        >
            <CircularProgress
                className={`${loadingStyle} ${
                    loading ? "block" : "hidden"
                } w-5 h-5 absolute`}
            />
            <div className={`${loading ? "invisible" : "visible"}`}>
                {children}
            </div>
        </button>
    )
}
