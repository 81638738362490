import { LinearProgress } from "@material-ui/core"
import Axios from "axios"
import { push } from "connected-react-router"
import React, { FC, useEffect, useRef } from "react"
import { shouldQueryExample, shouldRefetchTask } from "resha"
import { activateWorker } from "../../actions/api/ResourceActions"
import {
    changeSearchingStatus,
    getTrainingExample,
    getTrainingTask
} from "../../actions/api/TaskActions"
import { Button } from "../../components/atoms/Button"
import { Modal } from "../../components/Modal/Modal"
import { useBoolean } from "../../hooks/useBoolean"
import { useAppDispatch, useAppState } from "../../hooks/useReduxState"
import { routePaths } from "../../routes"
import { pages } from "../../texts"

export interface TrainingTasksProps {
    trainingId: string
    sequence?: number
}

export const TrainingTasks: FC<TrainingTasksProps> = ({
    trainingId,
    sequence
}) => {
    const worker = useAppState(state => state.auth.worker)
    const dispatch = useAppDispatch()
    const userIsActive = worker?.active
    const cancelRef = useRef(Axios.CancelToken.source())

    const [open, { setToFalse, setToTrue }] = useBoolean(false)

    useEffect(() => {
        if (worker) {
            if (!worker.active) {
                dispatch(activateWorker())
            }
            const cancelToken = cancelRef.current
            const fetchTask = async () => {
                try {
                    dispatch(changeSearchingStatus(true))
                    const task = await dispatch(
                        getTrainingTask({
                            worker,
                            trainingId,
                            sequence,
                            cancelToken: cancelToken.token
                        })
                    )

                    if (task) {
                        if (
                            shouldQueryExample(task) &&
                            trainingId &&
                            task.sequence !== undefined &&
                            task.exampleId !== undefined
                        ) {
                            const example = await dispatch(
                                getTrainingExample({
                                    worker,
                                    trainingId,
                                    sequence: task.sequence,
                                    exampleId: task.exampleId,
                                    cancelToken: cancelToken.token
                                })
                            )

                            dispatch(
                                push(
                                    routePaths.trainingExample(
                                        trainingId,
                                        String(example?.id ?? "")
                                    ),
                                    {
                                        example,
                                        worker
                                    }
                                )
                            )
                        } else if (shouldRefetchTask(task)) {
                            fetchTask()
                        } else {
                            dispatch(
                                push(
                                    routePaths.trainingTask(
                                        trainingId,
                                        String(task.id ?? "no-id")
                                    ),
                                    {
                                        task,
                                        worker
                                    }
                                )
                            )
                        }
                    }
                } catch (error) {
                    if ((error as any)?.response?.status === 409) {
                        setToTrue()
                    }
                }
            }

            fetchTask()
        }

        return () => {
            dispatch(changeSearchingStatus(false))
        }
    }, [dispatch, worker, setToTrue, trainingId, sequence])

    useEffect(() => {
        const cancelToken = cancelRef.current
        return () => {
            cancelToken.cancel()
        }
    }, [])

    const renderUserWaitingForTask = () =>
        userIsActive && (
            <>
                <p className="mb-16 text-lg">{pages.tasks.waitForTasks}</p>
                <LinearProgress
                    classes={{
                        bar: "bg-white"
                    }}
                    className="bg-neon-gradient-400"
                />
            </>
        )

    const handleClose = () => {
        setToFalse()
        dispatch(push(routePaths.missions()))
    }

    return (
        <div className="flex flex-col px-3 py-2">
            {renderUserWaitingForTask()}
            <Modal open={open} onClose={handleClose}>
                <h2
                    id="409-modal"
                    className="font-bold md:text-lg text-center mb-0"
                >
                    {pages.tasks.error409}
                </h2>
                <hr className="bg-gray-400 w-full" />
                <Button
                    className=" bg-neon-gradient-400 hover:bg-primary-darker text-white-opaque"
                    onClick={handleClose}
                    capitalize={true}
                >
                    {pages.tasks.modalButton}
                </Button>
            </Modal>
        </div>
    )
}
