import { Reducer } from "redux"
import { MessagesType } from "../../actions/Types"

export enum MessageSentiment {
    good = "GOOD",
    warn = "WARN",
    bad = "BAD"
}

export interface MessageState {
    id: number
    text: string
    type: MessageSentiment
}

export interface MessagesState {
    current: MessageState | undefined
}

interface MessagesSetAction {
    type: MessagesType.set
    payload: {
        text: string
        type: MessageSentiment
    }
}

interface MessagesDismissAction {
    type: MessagesType.dismiss
}

export type MessagesAction = MessagesSetAction | MessagesDismissAction

const initialState: MessagesState = {
    current: undefined
}

export const MessagesReducer: Reducer<MessagesState, MessagesAction> = (
    state = initialState,
    action
): MessagesState => {
    switch (action.type) {
        case MessagesType.set:
            return {
                ...state,
                current: {
                    id: state.current === undefined ? 0 : state.current.id + 1,
                    ...action.payload
                }
            }
        case MessagesType.dismiss:
            return {
                ...state,
                current: {
                    id: state.current === undefined ? 0 : state.current.id + 1,
                    text: "",
                    type: MessageSentiment.good
                }
            }
        default:
            return state
    }
}
