import React, { FC } from "react"
import { Fade, Backdrop, Modal as StyledModal } from "dash-styled"
import { CardFrame } from "../atoms/CardFrame"

interface ModalProps {
    open: boolean
    onClose?: () => void
    size?: "sm" | "md" | "lg" | "xl"
    disableBackdropClick?: boolean
}

export const Modal: FC<ModalProps> = ({
    children,
    open,
    onClose,
    size = "sm",
    disableBackdropClick
}) => {
    return (
        <StyledModal
            disableBackdropClick={disableBackdropClick}
            className="flex items-center justify-center px-6"
            open={open}
            onClose={onClose}
            closeAfterTransition={true}
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500
            }}
        >
            <Fade in={open}>
                <CardFrame className={`md:max-w-${size}`}>{children}</CardFrame>
            </Fade>
        </StyledModal>
    )
}
