import React, { FC } from "react"
import { appInfo } from "../../texts"

const Privacy: FC = () => {
    return (
        <div className="container py-8">
            <h1 className="text-2xl md:text-4xl font-bold">Privacy Policy</h1>

            <p className="mb-2">Effective date: April 21, 2018</p>

            <p className="mb-2">
                Super.AI Inc ("us", "we", or "our") operates the{" "}
                <a href={appInfo.url}>{appInfo.url}</a> website and the{" "}
                {appInfo.name} mobile application (the "Service").
            </p>

            <p className="mb-2">
                This page informs you of our policies regarding the collection,
                use, and disclosure of personal data when you use our Service
                and the choices you have associated with that data.
            </p>

            <p className="mb-2">
                We use your data to provide and improve the Service. By using
                the Service, you agree to the collection and use of information
                in accordance with this policy. Unless otherwise defined in this
                Privacy Policy, terms used in this Privacy Policy have the same
                meanings as in our Terms and Conditions.
            </p>

            <h2 className="text-3xl font-bold">Definitions</h2>

            <h3 className="text-2xl">Personal Data</h3>
            <p className="mb-2">
                Personal Data means data about a living individual who can be
                identified from those data (or from those and other information
                either in our possession or likely to come into our possession).
            </p>

            <h3 className="text-2xl">Usage Data</h3>
            <p className="mb-2">
                Usage Data is data collected automatically either generated by
                the use of the Service or from the Service infrastructure itself
                (for example, the duration of a page visit).
            </p>

            <h3 className="text-2xl">Cookies</h3>
            <p className="mb-2">
                Cookies are small pieces of data stored on a User’s device.
            </p>

            <h3 className="text-2xl">Data Controller</h3>
            <p className="mb-2">
                Data Controller means a person who (either alone or jointly or
                in common with other persons) determines the purposes for which
                and the manner in which any personal data are, or are to be,
                processed.
            </p>
            <p className="mb-2">
                For the purpose of this Privacy Policy, we are a Data Controller
                of your data.
            </p>

            <h3 className="text-2xl">Data Processor (or Service Providers)</h3>
            <p className="mb-2">
                Data Processor (or Service Provider) means any person (other
                than an employee of the Data Controller) who processes the data
                on behalf of the Data Controller.
            </p>
            <p className="mb-2">
                We may use the services of various Service Providers in order to
                process your data more effectively.
            </p>

            <h3 className="text-2xl">Data Subject</h3>
            <p className="mb-2">
                Data Subject is any living individual who is the subject of
                Personal Data.
            </p>

            <h3 className="text-2xl">User</h3>
            <p className="mb-2">
                The User is the individual using our Service. The User
                corresponds to the Data Subject, who is the subject of Personal
                Data.
            </p>

            <h2 className="text-3xl font-bold">
                Information Collection and Use
            </h2>

            <p className="mb-2">
                We collect several different types of information for various
                purposes to provide and improve our Service to you.
            </p>

            <h3 className="text-2xl">Types of Data Collected</h3>

            <h4 className="text-base font-bold">Personal Data</h4>

            <p className="mb-2">
                While using our Service, we may ask you to provide us with
                certain personally identifiable information that can be used to
                contact or identify you ("Personal Data"). Personally
                identifiable information may include, but is not limited to:
            </p>

            <ul className="list-disc my-4">
                <li className="ml-8">Email address</li>
                <li className="ml-8">First name and last name</li>
                <li className="ml-8">Phone number</li>
                <li className="ml-8">
                    Address, State, Province, ZIP/Postal code, City
                </li>
                <li className="ml-8">Cookies and Usage Data</li>
            </ul>

            <p className="mb-2">
                We may use your Personal Data to contact you with newsletters,
                marketing or promotional materials and other information that
                may be of interest to you. You may opt out of receiving any, or
                all, of these communications from us by following the
                unsubscribe link or instructions provided in any email we send.
            </p>

            <h4 className="text-base font-bold">Usage Data</h4>

            <p className="mb-2">
                We may also collect information that your browser sends whenever
                you visit our Service or when you access the Service by or
                through a mobile device ("Usage Data").
            </p>
            <p className="mb-2">
                This Usage Data may include information such as your computer's
                Internet Protocol address (e.g. IP address), browser type,
                browser version, the pages of our Service that you visit, the
                time and date of your visit, the time spent on those pages,
                unique device identifiers and other diagnostic data.
            </p>
            <p className="mb-2">
                When you access the Service by or through a mobile device, this
                Usage Data may include information such as the type of mobile
                device you use, your mobile device unique ID, the IP address of
                your mobile device, your mobile operating system, the type of
                mobile Internet browser you use, unique device identifiers and
                other diagnostic data.
            </p>

            <h4 className="text-base font-bold">Location Data</h4>
            <p className="mb-2">
                We may use and store information about your location if you give
                us permission to do so (“Location Data”). We use this data to
                provide features of our Service, to improve and customize our
                Service.
            </p>
            <p className="mb-2">
                You can enable or disable location services when you use our
                Service at any time, through your device settings.
            </p>

            <h4 className="text-base font-bold">Tracking &amp; Cookies Data</h4>
            <p className="mb-2">
                We use cookies and similar tracking technologies to track the
                activity on our Service and hold certain information.
            </p>
            <p className="mb-2">
                Cookies are files with small amount of data which may include an
                anonymous unique identifier. Cookies are sent to your browser
                from a website and stored on your device. Tracking technologies
                also used are beacons, tags, and scripts to collect and track
                information and to improve and analyze our Service.
            </p>
            <p className="mb-2">
                You can instruct your browser to refuse all cookies or to
                indicate when a cookie is being sent. However, if you do not
                accept cookies, you may not be able to use some portions of our
                Service.
            </p>
            <p className="mb-2">Examples of Cookies we use:</p>
            <ul className="list-disc my-4">
                <li className="ml-8">
                    <strong>Session Cookies.</strong> We use Session Cookies to
                    operate our Service.
                </li>
                <li className="ml-8">
                    <strong>Preference Cookies.</strong> We use Preference
                    Cookies to remember your preferences and various settings.
                </li>
                <li className="ml-8">
                    <strong>Security Cookies.</strong> We use Security Cookies
                    for security purposes.
                </li>
            </ul>

            <h2 className="text-3xl font-bold">Use of Data</h2>

            <p className="mb-2">
                Super.AI Inc uses the collected data for various purposes:
            </p>
            <ul className="list-disc my-4">
                <li className="ml-8">To provide and maintain our Service</li>
                <li className="ml-8">
                    To notify you about changes to our Service
                </li>
                <li className="ml-8">
                    To allow you to participate in interactive features of our
                    Service when you choose to do so
                </li>
                <li className="ml-8">To provide customer support</li>
                <li className="ml-8">
                    To gather analysis or valuable information so that we can
                    improve our Service
                </li>
                <li className="ml-8">To monitor the usage of our Service</li>
                <li className="ml-8">
                    To detect, prevent and address technical issues
                </li>
                <li className="ml-8">
                    To provide you with news, special offers and general
                    information about other goods, services and events which we
                    offer that are similar to those that you have already
                    purchased or enquired about unless you have opted not to
                    receive such information
                </li>
            </ul>

            <h2 className="text-3xl font-bold">Retention of Data</h2>
            <p className="mb-2">
                Super.AI Inc will retain your Personal Data only for as long as
                is necessary for the purposes set out in this Privacy Policy. We
                will retain and use your Personal Data to the extent necessary
                to comply with our legal obligations (for example, if we are
                required to retain your data to comply with applicable laws),
                resolve disputes, and enforce our legal agreements and policies.
            </p>
            <p className="mb-2">
                Super.AI Inc will also retain Usage Data for internal analysis
                purposes. Usage Data is generally retained for a shorter period
                of time, except when this data is used to strengthen the
                security or to improve the functionality of our Service, or we
                are legally obligated to retain this data for longer time
                periods.
            </p>

            <h2 className="text-3xl font-bold">Transfer of Data</h2>
            <p className="mb-2">
                Your information, including Personal Data, may be transferred to
                — and maintained on — computers located outside of your state,
                province, country or other governmental jurisdiction where the
                data protection laws may differ than those from your
                jurisdiction.
            </p>
            <p className="mb-2">
                If you are located outside United States and choose to provide
                information to us, please note that we transfer the data,
                including Personal Data, to United States and process it there.
            </p>
            <p className="mb-2">
                Your consent to this Privacy Policy followed by your submission
                of such information represents your agreement to that transfer.
            </p>
            <p className="mb-2">
                Super.AI Inc will take all steps reasonably necessary to ensure
                that your data is treated securely and in accordance with this
                Privacy Policy and no transfer of your Personal Data will take
                place to an organization or a country unless there are adequate
                controls in place including the security of your data and other
                personal information.
            </p>

            <h2 className="text-3xl font-bold">Disclosure of Data</h2>

            <h3 className="text-2xl">Disclosure for Law Enforcement</h3>
            <p className="mb-2">
                Under certain circumstances, super.AI Inc may be required to
                disclose your Personal Data if required to do so by law or in
                response to valid requests by public authorities (e.g. a court
                or a government agency).
            </p>

            <h3 className="text-2xl">Legal Requirements</h3>
            <p className="mb-2">
                Super.AI Inc may disclose your Personal Data in the good faith
                belief that such action is necessary to:
            </p>
            <ul className="list-disc my-4">
                <li className="ml-8">To comply with a legal obligation</li>
                <li className="ml-8">
                    To protect and defend the rights or property of super.AI Inc
                </li>
                <li className="ml-8">
                    To prevent or investigate possible wrongdoing in connection
                    with the Service
                </li>
                <li className="ml-8">
                    To protect the personal safety of users of the Service or
                    the public
                </li>
                <li className="ml-8">To protect against legal liability</li>
            </ul>

            <h2 className="text-3xl font-bold">Security of Data</h2>
            <p className="mb-2">
                The security of your data is important to us, but remember that
                no method of transmission over the Internet, or method of
                electronic storage is 100% secure. While we strive to use
                commercially acceptable means to protect your Personal Data, we
                cannot guarantee its absolute security.
            </p>

            <h2 className="text-3xl font-bold">"Do Not Track" Signals</h2>
            <p className="mb-2">
                We do not support Do Not Track ("DNT"). Do Not Track is a
                preference you can set in your web browser to inform websites
                that you do not want to be tracked.
            </p>
            <p className="mb-2">
                You can enable or disable Do Not Track by visiting the
                Preferences or Settings page of your web browser.
            </p>

            <h2 className="text-3xl font-bold">Your Rights</h2>
            <p className="mb-2">
                Super.AI Inc aims to take reasonable steps to allow you to
                correct, amend, delete, or limit the use of your Personal Data.
            </p>
            <p className="mb-2">
                Whenever made possible, you can update your Personal Data
                directly within your account settings section. If you are unable
                to change your Personal Data, please contact us to make the
                required changes.
            </p>
            <p className="mb-2">
                If you wish to be informed what Personal Data we hold about you
                and if you want it to be removed from our systems, please
                contact us.
            </p>
            <p className="mb-2">
                In certain circumstances, you have the right:
            </p>
            <ul className="list-disc my-4">
                <li className="ml-8">
                    To access and receive a copy of the Personal Data we hold
                    about you
                </li>
                <li className="ml-8">
                    To rectify any Personal Data held about you that is
                    inaccurate
                </li>
                <li className="ml-8">
                    To request the deletion of Personal Data held about you
                </li>
            </ul>
            <p className="mb-2">
                You have the right to data portability for the information you
                provide to super.AI Inc. You can request to obtain a copy of
                your Personal Data in a commonly used electronic format so that
                you can manage and move it.
            </p>
            <p className="mb-2">
                Please note that we may ask you to verify your identity before
                responding to such requests.
            </p>

            <h2 className="text-3xl font-bold">Service Providers</h2>
            <p className="mb-2">
                We may employ third party companies and individuals to
                facilitate our Service ("Service Providers"), to provide the
                Service on our behalf, to perform Service-related services or to
                assist us in analyzing how our Service is used.
            </p>
            <p className="mb-2">
                These third parties have access to your Personal Data only to
                perform these tasks on our behalf and are obligated not to
                disclose or use it for any other purpose.
            </p>

            <h2 className="text-3xl font-bold">Links to Other Sites</h2>
            <p className="mb-2">
                Our Service may contain links to other sites that are not
                operated by us. If you click on a third party link, you will be
                directed to that third party's site. We strongly advise you to
                review the Privacy Policy of every site you visit.
            </p>
            <p className="mb-2">
                We have no control over and assume no responsibility for the
                content, privacy policies or practices of any third party sites
                or services.
            </p>

            <h2 className="text-3xl font-bold">Children's Privacy</h2>
            <p className="mb-2">
                Our Service does not address anyone under the age of 13
                ("Children").
            </p>
            <p className="mb-2">
                We do not knowingly collect personally identifiable information
                from anyone under the age of 13. If you are a parent or guardian
                and you are aware that your Children has provided us with
                Personal Data, please contact us. If we become aware that we
                have collected Personal Data from children without verification
                of parental consent, we take steps to remove that information
                from our servers.
            </p>

            <h2 className="text-3xl font-bold">
                Changes to This Privacy Policy
            </h2>
            <p className="mb-2">
                We may update our Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page.
            </p>
            <p className="mb-2">
                We will let you know via email and/or a prominent notice on our
                Service, prior to the change becoming effective and update the
                "effective date" at the top of this Privacy Policy.
            </p>
            <p className="mb-2">
                You are advised to review this Privacy Policy periodically for
                any changes. Changes to this Privacy Policy are effective when
                they are posted on this page.
            </p>

            <h2 className="text-3xl font-bold">Contact Us</h2>
            <p className="mb-2">
                If you have any questions about this Privacy Policy, please
                contact us:
            </p>
            <ul className="list-disc my-4">
                <li className="ml-8">
                    By email: <a href="support@super.ai">support@super.ai</a>
                </li>

                <li className="ml-8">
                    By mail: super.AI, 10400 NE 4th St, Bellevue, WA, 98004,
                    United States
                </li>
            </ul>
        </div>
    )
}

export default Privacy
