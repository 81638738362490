"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.jsonSchemaV004 = void 0;
const schema_json_1 = require("../0.0.3/schema_json");
exports.jsonSchemaV004 = Object.assign(Object.assign({}, schema_json_1.jsonSchemaV003), { $id: "http://schema.canotic.com/v0.0.4/", definitions: Object.assign(Object.assign({}, schema_json_1.jsonSchemaV003.definitions), { INSTRUCTIONS: {
            $id: "instructions",
            type: "array",
            items: {
                type: "object",
                required: ["title", "detail"],
                properties: {
                    title: {
                        $ref: "text"
                    },
                    detail: {
                        $ref: "text"
                    }
                }
            },
            minItems: 1
        }, IMAGE: Object.assign(Object.assign({}, schema_json_1.jsonSchemaV003.definitions.IMAGE), { pattern: "^(?:http(s?)|data)://[\\w\\-\\._~:/?#[\\]@%!\\$&'\\(\\)\\*\\+,;=.]+" }), IMAGE_PRESENTATION: {
            $id: "image-presentation",
            description: "An container with an image with additional presentation properties.",
            type: "object",
            properties: {
                image: {
                    $ref: "image"
                },
                timeout: {
                    type: "number",
                    minimum: 0,
                    description: "Time in (fractional) seconds, after which the object object vanishes (is hidden)."
                }
            },
            required: ["image"]
        }, VIDEO: Object.assign(Object.assign({}, schema_json_1.jsonSchemaV003.definitions.VIDEO), { pattern: "^(?:http(s?)|data)://[\\w\\-\\._~:/?#[\\]@%!\\$&'\\(\\)\\*\\+,;=.]+" }), SATELLITE: Object.assign(Object.assign({}, schema_json_1.jsonSchemaV003.definitions.SATELLITE), { pattern: "^(?:http(s?)|data)://[\\w\\-\\._~:/?#[\\]@%!\\$&'\\(\\)\\*\\+,;=.]+" }), CHOICE: {
            $id: "choice",
            description: "Choice type",
            type: "object",
            properties: {
                value: {
                    anyOf: [
                        {
                            $ref: "text"
                        }
                    ]
                },
                tag: {
                    type: "string"
                },
                parent: {
                    type: "string",
                    description: "value of parent node"
                },
                selectable: {
                    type: "boolean",
                    description: "whether the parent node is selectable."
                },
                instructions: {
                    type: "string",
                    description: "Optional choice specific instructions."
                },
                examples: {
                    type: "string",
                    description: "Optional examples of choice class."
                }
            },
            required: ["value", "tag"]
        }, CHECKBOX: {
            $id: "checkbox",
            description: "Checkbox with optional name and two state switch.",
            type: "object",
            properties: {
                name: {
                    $ref: "text"
                },
                checked: {
                    type: "boolean"
                }
            },
            required: ["checked"]
        }, CHECKBOX_LIST: {
            $id: "checkbox-list",
            description: "List of checkboxes with name and two state switch.",
            type: "array",
            items: {
                $ref: "checkbox"
            },
            minItems: 1
        }, BLOCK: {
            $id: "block",
            description: "An object whose contents are rendered in a block.",
            type: "object"
        }, BOUNDING_BOX: {
            $id: "bounding-box",
            type: "object",
            properties: {
                top: {
                    type: "integer",
                    minimum: 0,
                    description: "The distance, in pixels, between the top of the bounding box and the left top of the image."
                },
                left: {
                    type: "integer",
                    minimum: 0,
                    description: "The distance, in pixels, between the left of the bounding box and the left border of the image."
                },
                width: {
                    type: "integer",
                    minimum: 0,
                    description: "The width, in pixels, of the bounding box."
                },
                height: {
                    type: "integer",
                    minimum: 0,
                    description: "The height, in pixels, of the bounding box."
                },
                selection: {
                    $ref: "choice"
                },
                minWidth: {
                    type: "integer",
                    minimum: 0,
                    description: "Minimum width of bounding box"
                },
                minHeight: {
                    type: "integer",
                    minimum: 0,
                    description: "Minimum height of bounding box"
                },
                subAnnotations: {
                    type: "array",
                    items: {
                        $ref: "structured"
                    }
                }
            },
            required: ["top", "left", "width", "height"]
        }, IMAGE_BOUNDING_BOX: {
            $id: "image-bounding-box",
            description: "A 2d square annotation on an image",
            type: "object",
            properties: {
                imageUrl: {
                    $ref: "image"
                },
                annotations: {
                    type: "array",
                    items: {
                        $ref: "bounding-box"
                    }
                },
                choices: {
                    type: "array",
                    description: "Possible values for underlying bounding boxes",
                    items: {
                        $ref: "choice"
                    }
                },
                minWidth: {
                    type: "integer",
                    minimum: 0,
                    default: 0,
                    description: "Minimum width of bounding box"
                },
                minHeight: {
                    type: "integer",
                    minimum: 0,
                    description: "Minimum height of bounding box"
                },
                minAnnotations: {
                    type: "integer",
                    minimum: 0,
                    default: 0,
                    description: "The minimum number of points that will be returned. Should only be used when one is certain that the image contains at least this many annotatable cuboids."
                },
                maxAnnotations: {
                    type: "integer",
                    description: "The maximum number of points that can be annotated in the image. A value of 0 (default) means no limit."
                },
                subAnnotations: {
                    type: "array",
                    items: {
                        $ref: "structured"
                    }
                }
            },
            required: ["imageUrl"]
        }, KEY_POINT: {
            $id: "key-point",
            description: "A point annotation on a specific point on an image",
            type: "object",
            properties: {
                x: {
                    type: "integer",
                    minimum: 0,
                    description: "The distance, in pixels, between the point and the left border of the image."
                },
                y: {
                    type: "integer",
                    minimum: 0,
                    description: "The distance, in pixels, between the point and the top border of the image."
                },
                description: {
                    type: "string"
                },
                visible: {
                    type: "boolean",
                    default: true,
                    description: "true if the key point is visible, false if occluded"
                },
                keyFrame: {
                    type: "boolean"
                },
                selection: {
                    $ref: "choice"
                }
            },
            required: ["x", "y", "selection"],
            additionalProperties: false
        }, SATELLITE_POLYGON: {
            $id: "satellite-polygon",
            description: "A polygon annotation on an GeoTIFF file",
            type: "object",
            properties: {
                imageUrl: {
                    $ref: "satellite"
                },
                annotations: {
                    type: "array",
                    items: {
                        allOf: [
                            {
                                $ref: "geojson-feature"
                            },
                            {
                                type: "object",
                                properties: {
                                    type: {
                                        type: "string",
                                        const: "Feature"
                                    },
                                    properties: {
                                        type: "object",
                                        description: "Key-value pairs containing selected choice and sub annotations",
                                        properties: {
                                            __selection: {
                                                description: "Value of selected choice",
                                                type: "string"
                                            }
                                        },
                                        additionalProperties: true
                                    },
                                    geometry: {
                                        $ref: "geojson-polygon"
                                    }
                                }
                            }
                        ]
                    }
                },
                choices: {
                    type: "array",
                    description: "Possible values for underlying bounding boxes",
                    items: {
                        $ref: "choice"
                    }
                },
                subAnnotations: {
                    type: "array",
                    items: {
                        $ref: "structured"
                    }
                },
                minAnnotations: {
                    type: "integer",
                    minimum: 0,
                    default: 0,
                    description: "The minimum number of points that will be returned. Should only be used when one is certain that the image contains at least this many annotatable cuboids."
                },
                maxAnnotations: {
                    type: "integer",
                    description: "The maximum number of points that can be annotated in the image. A value of 0 (default) means no limit."
                }
            },
            required: ["imageUrl"]
        }, GEOJSON_COORDINATE: {
            $id: "geojson-coordinate",
            title: "GeoJSON Coordinate",
            type: "array",
            minItems: 2,
            items: {
                type: "number"
            }
        }, GEOJSON_BBOX: {
            $id: "geojson-bbox",
            title: "GeoJSON Bounding Box",
            type: "array",
            minItems: 4,
            items: {
                type: "number"
            }
        }, GEOJSON_POINT: {
            $id: "geojson-point",
            title: "GeoJSON Point",
            type: "object",
            required: ["type", "coordinates"],
            properties: {
                type: {
                    type: "string",
                    enum: ["Point"]
                },
                coordinates: {
                    $ref: "geojson-coordinate"
                },
                bbox: {
                    $ref: "geojson-bbox"
                }
            }
        }, GEOJSON_LINE_STRING: {
            $id: "geojson-line-string",
            title: "GeoJSON LineString",
            type: "object",
            required: ["type", "coordinates"],
            properties: {
                type: {
                    type: "string",
                    enum: ["LineString"]
                },
                coordinates: {
                    type: "array",
                    minItems: 2,
                    items: {
                        $ref: "geojson-coordinate"
                    }
                },
                bbox: {
                    $ref: "geojson-bbox"
                }
            }
        }, GEOJSON_POLYGON: {
            $id: "geojson-polygon",
            title: "GeoJSON Polygon",
            type: "object",
            required: ["type", "coordinates"],
            properties: {
                type: {
                    type: "string",
                    enum: ["Polygon"]
                },
                coordinates: {
                    type: "array",
                    items: {
                        type: "array",
                        minItems: 4,
                        items: {
                            $ref: "geojson-coordinate"
                        }
                    }
                },
                bbox: {
                    $ref: "geojson-bbox"
                }
            }
        }, GEOJSON_MULTI_POINT: {
            $id: "geojson-multi-point",
            title: "GeoJSON MultiPoint",
            type: "object",
            required: ["type", "coordinates"],
            properties: {
                type: {
                    type: "string",
                    enum: ["MultiPoint"]
                },
                coordinates: {
                    type: "array",
                    items: {
                        $ref: "geojson-coordinate"
                    }
                },
                bbox: {
                    $ref: "geojson-bbox"
                }
            }
        }, GEOJSON_MULTI_LINE_STRING: {
            $id: "geojson-multi-line-string",
            title: "GeoJSON MultiLineString",
            type: "object",
            required: ["type", "coordinates"],
            properties: {
                type: {
                    type: "string",
                    enum: ["MultiLineString"]
                },
                coordinates: {
                    type: "array",
                    items: {
                        type: "array",
                        minItems: 2,
                        items: {
                            $ref: "geojson-coordinate"
                        }
                    }
                },
                bbox: {
                    $ref: "geojson-bbox"
                }
            }
        }, GEOJSON_MULTI_POLYGON: {
            $id: "geojson-multi-polygon",
            title: "GeoJSON MultiPolygon",
            type: "object",
            required: ["type", "coordinates"],
            properties: {
                type: {
                    type: "string",
                    enum: ["MultiPolygon"]
                },
                coordinates: {
                    type: "array",
                    items: {
                        type: "array",
                        items: {
                            type: "array",
                            minItems: 4,
                            items: {
                                $ref: "geojson-coordinate"
                            }
                        }
                    }
                },
                bbox: {
                    $ref: "geojson-bbox"
                }
            }
        }, GEOJSON_GEOMETRY_COLLECTION: {
            $id: "geojson-geometry-collection",
            title: "GeoJSON GeometryCollection",
            type: "object",
            required: ["type", "geometries"],
            properties: {
                type: {
                    type: "string",
                    enum: ["GeometryCollection"]
                },
                geometries: {
                    type: "array",
                    items: {
                        oneOf: [
                            {
                                $ref: "geojson-point"
                            },
                            {
                                $ref: "geojson-line-string"
                            },
                            {
                                $ref: "geojson-polygon"
                            },
                            {
                                $ref: "geojson-multi-point"
                            },
                            {
                                $ref: "geojson-multi-line-string"
                            },
                            {
                                $ref: "geojson-multi-polygon"
                            }
                        ]
                    }
                },
                bbox: {
                    $ref: "geojson-bbox"
                }
            }
        }, GEOJSON_FEATURE: {
            $id: "geojson-feature",
            title: "GeoJSON Feature",
            type: "object",
            required: ["type", "properties", "geometry"],
            properties: {
                type: {
                    type: "string",
                    enum: ["Feature"]
                },
                properties: {
                    oneOf: [
                        {
                            type: "null"
                        },
                        {
                            type: "object"
                        }
                    ]
                },
                geometry: {
                    oneOf: [
                        {
                            type: "null"
                        },
                        {
                            $ref: "geojson-point"
                        },
                        {
                            $ref: "geojson-line-string"
                        },
                        {
                            $ref: "geojson-polygon"
                        },
                        {
                            $ref: "geojson-multi-point"
                        },
                        {
                            $ref: "geojson-multi-line-string"
                        },
                        {
                            $ref: "geojson-multi-polygon"
                        },
                        {
                            $ref: "geojson-geometry-collection"
                        }
                    ]
                },
                bbox: {
                    $ref: "geojson-bbox"
                }
            }
        }, GEOJSON_FEATURE_COLLECTION: {
            $id: "geojson-feature-collection",
            title: "GeoJSON FeatureCollection",
            type: "object",
            required: ["type", "features"],
            properties: {
                type: {
                    type: "string",
                    enum: ["FeatureCollection"]
                },
                features: {
                    type: "array",
                    items: {
                        $ref: "geojson-feature"
                    }
                },
                bbox: {
                    $ref: "geojson-bbox"
                }
            }
        }, DOCUMENT: {
            $id: "document",
            description: "The URL of a PDF file",
            type: "string",
            pattern: "^(?:http(s?)|data)://[\\w\\-\\._~:/?#[\\]@%!\\$&'\\(\\)\\*\\+,;=.]+"
        } }) });
