import { APIEndpointInfo, apiEndpoints, axios } from "../../routes/api"
import { captureException } from "@sentry/react"
import { MainThunkDispatch } from "../../reducers"
import { getValidSession } from "../AuthActions"

export const getSignedUrl =
    (
        params: {
            uuid: string
            path: string
            seconds?: number
        } & (
            | { taskId: number }
            | { sequence: number; exampleId: number; trainingId: number }
        )
    ) =>
    async (dispatch: MainThunkDispatch): Promise<string> => {
        const token = await dispatch(getValidSession())
        try {
            let apiEndpoint: APIEndpointInfo
            if ("taskId" in params) {
                const { path, seconds = 600, uuid, taskId } = params
                apiEndpoint = apiEndpoints.getSignedUrl({
                    path,
                    seconds,
                    taskId,
                    workerUuid: uuid
                })
            } else {
                const {
                    path,
                    seconds = 600,
                    uuid,
                    trainingId,
                    sequence,
                    exampleId
                } = params

                apiEndpoint = apiEndpoints.getTrainingExampleSignedUrl({
                    path,
                    seconds,
                    trainingId,
                    workerUuid: uuid,
                    exampleId,
                    sequence
                })
            }

            const response = await axios({
                service: apiEndpoint.service
            }).get<{
                path: string
                signedUrl: string
            }>(apiEndpoint.url, {
                headers: {
                    Authorization: token
                }
            })

            return response.data.signedUrl
        } catch (error) {
            if ("taskId" in params)
                captureException(error, {
                    tags: { "superai.taskID": params.taskId }
                })
            else
                captureException(error, {
                    tags: { "superai.trainingID": params.trainingId }
                })

            throw error
        }
    }

interface DataURLModel {
    created: string
    description: string
    id: number
    mimeType: string
    ownerId: number
    path: string
    signedUrl: string
    status: "ACTIVE" | "INACTIVE"
    updated: string
    uploadUrl: string
}

export function getDataUrl({ ownerId, path }: DataURLModel): string {
    return `data://${ownerId}/${path}`
}

export async function uploadData({
    blob,
    taskId,
    token,
    workerUuid,
    objectName,
    onProgress
}: {
    token: string
    blob: Blob
    taskId: number
    workerUuid: string
    objectName?: string
    onProgress?: (progress: number) => void
}) {
    const { url, service } = apiEndpoints.getUploadDataUrl({
        mimeType: blob.type,
        taskId,
        workerUuid,
        objectName
    })

    try {
        const { data } = await (objectName === undefined
            ? axios({ service }).post
            : axios({ service }).put)<DataURLModel>(url, undefined, {
            headers: { Authorization: token }
        })

        if (data === undefined) {
            throw new Error(`Failed to retrieve url for uploading data.`)
        }

        await new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest()

            xhr.addEventListener("load", resolve)
            xhr.upload.addEventListener("abort", reject)
            xhr.upload.addEventListener("error", reject)
            if (onProgress) {
                xhr.upload.addEventListener("progress", ({ loaded, total }) =>
                    onProgress(loaded / total)
                )
            }
            xhr.open("PUT", data.uploadUrl)
            xhr.send(blob)
        })

        return getDataUrl(data)
    } catch (error) {
        captureException(error, { tags: { "superai.taskID": taskId } })
        throw error
    }
}
