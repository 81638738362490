import { Divider, Slider, Typography, Mark } from "@material-ui/core"
import React, { FC, useMemo } from "react"
import { componentsText } from "../../texts"
import { CardFrame } from "../atoms/CardFrame"

interface WorkerPerformanceProps {
    completed: number
    skipped: number
    leaderboard?: number
    mostActiveHero: number
}

export const WorkerPerformance: FC<WorkerPerformanceProps> = ({
    completed,
    mostActiveHero,
    skipped,
    leaderboard
}) => {
    const marks = useMemo((): Mark[] => {
        const showZero = completed / mostActiveHero >= 0.05
        return [
            ...(showZero ? [{ value: 0, label: 0 }] : []),
            { value: completed, label: "You" },
            {
                value: mostActiveHero,
                label: mostActiveHero
            }
        ]
    }, [completed, mostActiveHero])

    return (
        <CardFrame justify="items-between">
            <div className="font-medium text-lg self-start flex items-center mb-2">
                <span
                    className="mr-2 text-lg md:text-xl"
                    role="img"
                    aria-label="increase chart emoji"
                >
                    📈
                </span>
                <h2 className="md:text-lg mb-0 ">
                    {componentsText.workerPerformance.title}
                </h2>
            </div>
            <div className="text-gray-800 mt-6">
                <div style={{ width: "calc(100% - 24px)" }}>
                    <div className="w-full text-right text-xs text-gray-600">
                        Top spot
                    </div>
                    {completed && mostActiveHero && (
                        <div className="text-center">
                            <Slider
                                classes={{
                                    valueLabel: ""
                                }}
                                value={completed}
                                className="h-3 w-11/12"
                                valueLabelDisplay="on"
                                max={mostActiveHero}
                                marks={marks}
                                style={{}}
                            />
                        </div>
                    )}
                </div>
                <Typography className="w-20 m-auto -mt-12 mb-6 text-xs opacity-50 text-gray-600">
                    Completed
                </Typography>
            </div>
            <Divider className="mb-4" />
            <span className="text-sm">
                {componentsText.workerPerformance.leaderboardText(
                    mostActiveHero - completed,
                    leaderboard
                )}
            </span>
            <div className="flex flex-col mb-4 md:mb-2 ">
                <span className="mb-1 text-sm">
                    {componentsText.workerPerformance.skippedTasks(skipped)}
                </span>
            </div>
        </CardFrame>
    )
}
