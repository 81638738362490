import React, {
    ChangeEvent,
    FC,
    KeyboardEvent,
    MouseEvent,
    useState
} from "react"
import { useDispatch } from "react-redux"
import { signIn } from "../../actions/AuthActions"
import { Alert } from "../../components/atoms/Alert"
import { InputProps } from "../../components/atoms/Input"
import { Link } from "../../components/atoms/Link"
import { LoginButtonProps } from "../../components/atoms/LoginButton"
import { renderMultipleInputsAndButton } from "../../helpers/ui"
import { useAppState } from "../../hooks/useReduxState"
import { MainThunkDispatch } from "../../reducers"
import { MessageSentiment } from "../../reducers/client/MessagesReducer"
import { routePaths } from "../../routes"
import { atoms } from "../../texts"

interface LoginFormState {
    email: string
    password: string
}

export const Login: FC = () => {
    const dispatch = useDispatch<MainThunkDispatch>()
    const message = useAppState(state => state.client.messages.current)
    const loading = useAppState(state => state.auth.loading)

    const [formState, setFormState] = useState<LoginFormState>({
        email: "",
        password: ""
    })
    const emailInput = "email"
    const passwordInput = "password"

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { id, value } = event.target
        switch (id) {
            case emailInput:
                setFormState({ ...formState, email: value.toLowerCase() })
                break
            case passwordInput:
                setFormState({ ...formState, password: value })
                break

            default:
                console.error("Unknown input id!")
                break
        }
    }

    const handleLoginClick = async (
        event: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>
    ) => {
        event.preventDefault()
        console.log(`Logging in with user: ${formState.email}`)

        await dispatch(
            signIn({ email: formState.email, password: formState.password })
        )
    }
    const inputs: InputProps[] = [
        {
            label: atoms.labels.email,
            placeholder: atoms.placeholder.email,
            name: emailInput,
            required: true,
            onChange: handleChange,
            value: formState.email
        },
        {
            label: atoms.labels.password,
            placeholder: atoms.placeholder.password,
            name: passwordInput,
            type: "password",
            required: true,
            onChange: handleChange,
            value: formState.password
        }
    ]

    const button: LoginButtonProps = {
        label: atoms.buttons.login,
        type: "submit",
        onClick: handleLoginClick,
        disabled: loading
    }

    return (
        <form className="min-w-login max-w-login mx-auto flex flex-col items-center ">
            {message?.text && (
                <Alert
                    success={message.type === MessageSentiment.good}
                    message={message.text}
                />
            )}
            {renderMultipleInputsAndButton({
                inputs,
                button
            })}
            <hr className="w-full" />
            <div className="w-full flex justify-center">
                <Link to={routePaths.register()}>{atoms.links.signUp}</Link>
                <Link to={routePaths.forgotPassword()}>
                    {atoms.links.forgotPassword}
                </Link>
            </div>
            <hr className="w-full" />
            <div className="w-full flex justify-center">
                <Link to={routePaths.eula()}>{atoms.links.userAgreement}</Link>
                <Link to={routePaths.privacy()}>{atoms.links.privacy}</Link>
            </div>
            <hr className="w-full" />
        </form>
    )
}
