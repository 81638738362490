import {
    TaskResponse,
    showTrainingEndMessage,
    showTrainingMessage
} from "resha"
import { createTextSchemaItem } from "universal-schema/lib"

export const ingestTrainingInfo = (task: TaskResponse): TaskResponse =>
    showTrainingEndMessage(task)
        ? {
              ...task,
              input: [
                  createTextSchemaItem(
                      "You have reached the end of the tutorial. Press submit to complete."
                  )
              ],
              output: []
          }
        : showTrainingMessage(task)
        ? {
              ...task,
              input: [
                  ...(task.hint ? [createTextSchemaItem(task.hint)] : []),
                  ...(task.explanation
                      ? [createTextSchemaItem(task.explanation)]
                      : [])
              ],
              output: []
          }
        : {
              ...task,
              input: Array.isArray(task.input)
                  ? [
                        ...(task.hint ? [createTextSchemaItem(task.hint)] : []),
                        ...(task.explanation
                            ? [createTextSchemaItem(task.explanation)]
                            : []),
                        ...(task.trainingMetricValue !== undefined
                            ? [
                                  createTextSchemaItem(
                                      "You have completed the qualification! Here is your score: **" +
                                          String(task.trainingMetricValue) +
                                          "**"
                                  )
                              ]
                            : []),
                        ...(task.input ?? [])
                    ]
                  : task.trainingMetricValue !== undefined
                  ? [
                        createTextSchemaItem(
                            "You have completed the qualification! Here is your score: **" +
                                String(task.trainingMetricValue) +
                                "**"
                        )
                    ]
                  : task.input,
              output: task.output ?? []
          }
