import { Divider } from "@material-ui/core"
import AccountCircle from "@material-ui/icons/AccountCircle"
import AttachMoney from "@material-ui/icons/AttachMoney"
import Brightness6 from "@material-ui/icons/Brightness6"
import ExitToApp from "@material-ui/icons/ExitToApp"
import Home from "@material-ui/icons/Home"
import PermIdentity from "@material-ui/icons/PermIdentity"
import Darkmode from "darkmode-js"
import React, { FC } from "react"
import { signOut } from "../../actions/AuthActions"
import { useAppDispatch } from "../../hooks/useReduxState"
import { ToastStatus, useToast } from "../../hooks/useToast"
import { Worker } from "../../models/Worker"
import { routePaths } from "../../routes"
import { componentsText, errorMessages } from "../../texts"
import { Link } from "../atoms/Link"

interface NavListProps {
    offsetTop: number
    onMenuClick: () => void
    worker: Worker | undefined
}

const darkmode = new Darkmode({
    time: "0",
    autoMatchOsTheme: false,
    mixColor: "#f0f0f0"
})

export const NavList: FC<NavListProps> = ({
    offsetTop,
    worker,
    onMenuClick
}) => {
    const dispatch = useAppDispatch()
    const errorToast = useToast(ToastStatus.ERROR)

    const { list } = componentsText.nav
    const { logout: errorLogout } = errorMessages

    const handleDarkmodeToggling = () => {
        darkmode.toggle()
        onMenuClick()
    }
    const handleLogout = async () => {
        try {
            await dispatch(signOut())
        } catch (error) {
            errorToast(errorLogout)
        }
    }

    const listItemStyle =
        "w-full pl-6 pr-16 py-3 flex items-center text-lg text-black text-opacity-87 transition-colors duration-200 ease-in-out hover:bg-gray-200"
    return (
        <div
            className="flex flex-col justify-between"
            style={{ height: `calc(100vh - ${offsetTop}px)` }}
        >
            <div className="flex flex-col items-start py-8">
                <div className="self-center flex flex-col items-center mb-4">
                    <AccountCircle className="w-32 h-32" />
                    <span>{worker?.email}</span>
                </div>
                <Divider className="w-full" />
                <Link
                    className={listItemStyle}
                    to={routePaths.missions()}
                    onClick={onMenuClick}
                >
                    <Home className="mr-2" />
                    {list.home}
                </Link>
                <Link
                    className={listItemStyle}
                    to={routePaths.earnings()}
                    onClick={onMenuClick}
                >
                    <AttachMoney className="mr-2" />
                    {list.earnings}
                </Link>
                <Link
                    className={listItemStyle}
                    to={routePaths.profile()}
                    onClick={onMenuClick}
                >
                    <PermIdentity className="mr-2" />
                    {list.profile}
                </Link>
                <button
                    className={`${listItemStyle} text-left`}
                    onClick={handleDarkmodeToggling}
                >
                    <Brightness6 className="mr-2" />
                    {list.toggleDarkmode}
                </button>

                <Divider className="w-full" />
                <button
                    data-testid={list.logout.toLowerCase()}
                    className={`${listItemStyle} text-left`}
                    onClick={handleLogout}
                >
                    <ExitToApp className="mr-2" />
                    {list.logout}
                </button>
            </div>
            <div className="flex flex-col">
                <Divider className="w-full mb-2" />
                <div className="w-full flex justify-center mb-2">
                    <Link
                        className="px-2 text-sm text-gray-700 hover:underline"
                        to={routePaths.privacy()}
                    >
                        {list.privacy}
                    </Link>
                    <Link
                        className="px-2 text-sm text-gray-700 hover:underline"
                        to={routePaths.eula()}
                    >
                        {list.userAgreement}
                    </Link>
                </div>
                {process.env.REACT_APP_BUILD_NUMBER && (
                    <div className="w-full flex justify-center mb-2">
                        <span className="text-sm text-gray-500">
                            Build: {process.env.REACT_APP_BUILD_NUMBER}
                        </span>
                    </div>
                )}
            </div>
        </div>
    )
}
