import { Action, Reducer } from "redux"
import { AuthType, TaskType } from "../../actions/Types"
import { AuthAction } from "../auth"
import { TaskResponse } from "resha"
import { LOCATION_CHANGE, RouterAction } from "connected-react-router"
import { matchPath } from "react-router"
import { routePaths } from "../../routes"

export interface TaskState {
    id?: number
    jobId?: number
    searching: boolean
    task?: TaskResponse
}

export interface FetchTaskAction {
    type: TaskType.setTaskIds
    payload?: { id?: number; jobId?: number }
}

export interface UpdateSearchingTaskAction {
    type: TaskType.setSearching
    payload: boolean
}

export interface SetTaskAction extends Action<TaskType.setTask> {
    payload?: TaskResponse
}

export type TaskActions =
    | FetchTaskAction
    | UpdateSearchingTaskAction
    | SetTaskAction

const initialState: TaskState = {
    searching: false
}

export const TaskReducer: Reducer<
    TaskState,
    TaskActions | AuthAction | RouterAction
> = (state = initialState, action): TaskState => {
    switch (action.type) {
        case LOCATION_CHANGE:
            const isEnteringTaskPage =
                matchPath(action.payload.location.pathname, {
                    path: routePaths.task()
                }) !== null

            return {
                ...state,
                task: isEnteringTaskPage ? state.task : undefined
            }
        case AuthType.isNotSignedIn:
            return initialState

        case TaskType.setTaskIds:
            return {
                ...state,
                id: action.payload?.id,
                jobId: action.payload?.jobId
            }
        case TaskType.setSearching:
            return {
                ...state,
                searching: action.payload
            }
        case TaskType.setTask:
            return { ...state, task: action.payload }
        default:
            return state
    }
}
