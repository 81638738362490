import { MultipleChoice } from "universal-schema/lib/0.0.4"
import { prepareDateWithoutTimeForBackend } from "../helpers/date"
import { ProfileState } from "../pages/profile/Profile"
import { pages } from "../texts"

export interface Worker {
    uuid: string
    email: string
    firstname?: string
    lastname?: string
    dob?: number | string
    gender?: string
    address?: string
    zipcode?: string
    phone?: string
    telegram?: string
    active: boolean
    payments: { [key: string]: string }
    notification: boolean
    picture?: string
    agreed: number | string
    role: Role
}

export enum Role {
    ADMIN = "ADMIN",
    CROWD_MANAGER = "CROWD_MANAGER",
    WORKER = "WORKER"
}

export type WorkerFeedback = Pick<MultipleChoice, "choices" | "selections">

export const transformWorkerToProfileState = (
    worker: Worker
): ProfileState => ({
    email: {
        value: worker.email,
        valid: true,
        helperText: "",
        label: "Email"
    },
    firstname: {
        value: worker.firstname,
        valid: true,
        helperText: "",
        label: "First name"
    },
    lastname: {
        value: worker.lastname,
        valid: true,
        helperText: "",
        label: "Last name"
    },
    dob: {
        value: worker.dob
            ? prepareDateWithoutTimeForBackend(new Date(worker.dob))
            : undefined,
        valid: true,
        helperText: "",
        label: "Date of birth"
    },
    gender: {
        value: worker.gender,
        valid: true,
        helperText: "",
        label: "Gender"
    },
    telegram: {
        value: worker.telegram,
        valid: true,
        helperText: "",
        label: "Telegram"
    },
    phone: {
        value: worker.phone,
        valid: true,
        helperText: "",
        label: "Phone"
    },
    address: {
        value: worker.address,
        valid: true,
        helperText: "",
        label: "Address"
    },
    zipcode: {
        value: worker.zipcode,
        valid: true,
        helperText: "",
        label: "Zip code"
    },
    notification: {
        value: worker.notification,
        valid: true,
        helperText: "",
        label: pages.profile.labelNotifications
    }
})

export const transformProfileStateToWorker = (
    worker: Worker,
    {
        address,
        dob,
        email,
        firstname,
        gender,
        lastname,
        notification,
        phone,
        telegram,
        zipcode
    }: ProfileState
): Worker => ({
    ...worker,
    email: email.value,
    dob: dob.value,
    address: address.value,
    firstname: firstname.value,
    gender: gender.value,
    lastname: lastname.value,
    notification: notification.value,
    phone: phone.value,
    telegram: telegram.value,
    zipcode: zipcode.value
})
