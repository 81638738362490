import { ReactNode, useCallback } from "react"
import { toast, ToastOptions } from "react-toastify"
import styles from "./useToast.module.css"

export enum ToastStatus {
    SUCCESS = "success",
    WARNING = "warning",
    ERROR = "error",
    INFO = "info"
}

export const useToast = (appearance: ToastStatus = ToastStatus.SUCCESS) => {
    return useCallback(
        (message: ReactNode) => {
            const commonToastConfig: ToastOptions = {
                autoClose: 3000,
                position: toast.POSITION.BOTTOM_LEFT,
                hideProgressBar: false,
                closeOnClick: false,
                closeButton: false,
                pauseOnHover: true,
                draggable: false,
                progressClassName: "opacity-50"
            }

            switch (appearance) {
                case ToastStatus.ERROR:
                    return toast.error(message, {
                        className: `shadow-lg p-4 rounded text-base ${styles.Notification_fail}`,
                        ...commonToastConfig
                    })
                case ToastStatus.INFO:
                case ToastStatus.SUCCESS:
                    return toast.success(message, {
                        className:
                            "shadow-lg p-4 rounded text-base text-rhino-100 bg-black",
                        ...commonToastConfig
                    })
                case ToastStatus.WARNING:
                    return toast.warn(message, {
                        className: `shadow-lg p-4 rounded text-base ${styles.Notification_warn}`,
                        ...commonToastConfig
                    })
            }
        },
        [appearance]
    )
}
