import { Billing } from "../../models/Billing"
import { MainThunkDispatch } from "../../reducers"
import { apiEndpoints, axios } from "../../routes/api"
import { getValidSession } from "../AuthActions"
import { reportError } from "../tracking/MonitoringActions"
import { Severity } from "@sentry/react"

export const getWorkerBillings =
    ({
        userUuid: workerUuid,
        endDate,
        startDate
    }: {
        userUuid: string
        startDate: string
        endDate: string
    }) =>
    async (
        dispatch: MainThunkDispatch
    ): Promise<{
        earnings: Billing[]
        totalEarning: number
        totalTasks: number
    }> => {
        const token = await dispatch(getValidSession())
        const { url, service } = apiEndpoints.getWorkerBillings({
            workerUuid,
            startDate,
            endDate
        })
        try {
            const response = await axios({ service }).get<Billing[]>(url, {
                headers: {
                    Authorization: token
                }
            })

            const { totalEarning, totalTasks } = response.data.reduce(
                (
                    {
                        totalEarning: totalEarningPrev,
                        totalTasks: totalTasksPrev
                    },
                    current
                ) => ({
                    totalEarning: totalEarningPrev + current.amount,
                    totalTasks: totalTasksPrev + current.qty
                }),
                { totalEarning: 0, totalTasks: 0 }
            )

            return { earnings: response.data, totalEarning, totalTasks }
        } catch (error) {
            dispatch(
                reportError({
                    error,
                    level: Severity.Fatal,
                    extraData: {
                        startDate,
                        endDate
                    }
                })
            )
            throw error
        }
    }
